import { data } from "dcmjs";
import { useLiveQuery } from "dexie-react-hooks";
import { idb } from "stores/idb";
import validationState from "stores/validation";
import { useSnapshot } from "valtio";


function useValidation(dataset: data.NaturalizedDataset) {

  const studyUID = dataset.StudyInstanceUID;
  const seriesUID = dataset.SeriesInstanceUID

  const idbValidations = useLiveQuery(
    () => idb.dicoms.where({ studyUID }).toArray()
    , [studyUID]
  )

  const validation = useSnapshot(validationState)

  if (validation?.markerValidations) return validation
  const idbValidation = idbValidations?.find(v => v.referenceSeriesUID === seriesUID || v.seriesUID === seriesUID)

  return idbValidation
}

export default useValidation