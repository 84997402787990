import { Button, ButtonProps, IconButton } from '@mui/material';
import { useRecordContext, RaRecord } from 'react-admin';

interface Props extends ButtonProps {
  callback?: (record: RaRecord) => void
  icon?: JSX.Element
  isHidden?: (record) => boolean
}

function CustomActionButton({ callback: action, icon, children, isHidden, ...buttonProps }: Props) {
  const record = useRecordContext();
  const hidden = isHidden?.(record)
  if(hidden){
    return null
  }

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault()
    e.stopPropagation()
    return  action?.(record);
  }

  if(!icon){
    return <Button {...buttonProps} onClick={action ? () => action(record) : undefined}>
      {children}
    </Button>
  }

  return (
    <IconButton {...buttonProps} size='small' onClick={(e) => handleClick(e)}>
      {icon}
    </IconButton>
  )
}

export default CustomActionButton


