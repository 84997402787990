import { IconButton } from "@mui/material";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useSnapshot } from "valtio";
import { useTranslate } from "ra-core";

import { type DicomValidation, setMarkerScreenshots } from "stores/validation"
import viewerState from "stores/viewer";
import type { Marker } from "../quantification/components/Marker";

function ScreenshotIcon({ marker, validationStore }: { marker: Marker, validationStore: DicomValidation }) {
  const t = useTranslate()
  const { segmentsPerSeries } = useSnapshot(viewerState)

  const { segments } = marker
  const isSegmentSelected = segmentsPerSeries
    .filter((seg) => segments.map((s) => s.SegmentNumber).includes(seg.SegmentNumber)
      && segments.map(s => s.seriesUID).includes(seg.seriesUID)
    )
    .every(seg => seg?.selected);

  if (!validationStore?.isValtioStore || !isSegmentSelected) return null
  const screenshots = validationStore.markerValidations?.[marker.code]?.screenshots


  let validated = validationStore?.markerValidations?.[marker.code]?.status
  if (!validated || validated === "unset") return null

  async function screenshotCanvas() {
    const screenshotPromises = ["viewer_main_1", "viewer_main_2", "viewer_main_3"].map(async canvasId => {
      const canvas = document.querySelector(`#${canvasId} canvas`) as HTMLCanvasElement
      return canvas.toDataURL();;
    })
    const dataUrls = await Promise.all(screenshotPromises)
    setMarkerScreenshots(marker.code, dataUrls)
  }

  const hasScreenshot = screenshots?.length
  return <IconButton size='small' onClick={() => screenshotCanvas()} title={t('patient.view.tooltip.validation.screenshot')} aria-label={t('patient.view.tooltip.validation.screenshot')}>
    <CameraAltIcon className={hasScreenshot ? "text-gray-900" : "text-white"} />
  </IconButton>
}

export default ScreenshotIcon