import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import { DicomNodeCreate } from "./components/DicomNodeCreate";
import { DicomNodeList } from "./components/DicomNodeList";
import { DicomNodeEdit } from "./components/DicomNodeEdit";

const resource = {
  create: DicomNodeCreate,
  list: DicomNodeList,
  edit: DicomNodeEdit,
  icon: BrandingWatermarkIcon,
};

export default resource;
