import { IconButton } from "@mui/material";
import ValidatedIcon from '@mui/icons-material/TaskAltOutlined';
import UnvalidatedIcon from '@mui/icons-material/UnpublishedOutlined';
import UnsetIcon from '@mui/icons-material/RemoveCircleOutline';
import { useTranslate } from "ra-core"

import { toggleSegmentValidation, type DicomValidation, type ValidationStatus } from "stores/validation"
import { getAllDescendant } from "shared/utils/recursive";
import type { Marker } from '../quantification/components/Marker';

function aggValidationStatuses(statuses: ValidationStatus[]): ValidationStatus {
  if (!statuses?.length || statuses.every(s => s === undefined)) return undefined
  // TODO Find a better way to "max" the status
  if (statuses.some(s => s === "edited")) return "edited"
  if (statuses.some(s => s === "erased")) return "erased"
  if (statuses.some(s => s === "unvalidated")) return "unvalidated"
  if (statuses.some(s => s === "validated")) return "validated"
  return undefined
}


function ValidateSegmentIcon({ marker, validationStore }: { marker: Marker, validationStore: DicomValidation }) {
  const t = useTranslate()
  if (!validationStore) return null
  let validated = validationStore?.markerValidations?.[marker.code]?.status

  const toggle = () => toggleSegmentValidation(marker.code)
  let disabled = !!validationStore['referenceSeriesUID']

  // If no validation found, aggregate the submarker validation
  if (!validated) {
    const statuses = getAllDescendant(marker.code)
      ?.map(rid => validationStore.markerValidations?.[rid]?.status)
      ?.filter(Boolean)
    const aggValidation = aggValidationStatuses(statuses)
    validated = aggValidation
    disabled = true
    //TODO fix the hardcoded RID4700
  } else if (marker.code === "RID4700" && ["unset", "validated"].includes(validated)) {
    const statuses = marker.substructures?.map(rid => validationStore.markerValidations?.[rid]?.status)
    const aggValidation = aggValidationStatuses(statuses)
    validated = aggValidation
    disabled = true
  }

  if (validated === "validated") {
    return <IconButton disabled={disabled} size='small' onClick={toggle} title={t("patient.view.tooltip.validation.status.validated")} aria-label={t("patient.view.tooltip.validation.status.validated")}>
      <ValidatedIcon className="text-green-700" />
    </IconButton>
  } else if (validated === "unvalidated") {
    return <IconButton disabled={disabled} size='small' onClick={toggle} title={t("patient.view.tooltip.validation.status.unvalidated")} aria-label={t("patient.view.tooltip.validation.status.unvalidated")}>
      <UnvalidatedIcon className="text-red-700" />
    </IconButton>
  } else if (["edited", "erased"].includes(validated)) {
    const title = t(`patient.view.tooltip.validation.status.${validated}`)
    return <IconButton disabled size='small' title={title} aria-label={title}>
      <UnvalidatedIcon className="text-red-700" />
    </IconButton>
  } else if (validated === "unset") {
    return <IconButton disabled={disabled} size='small' onClick={toggle} title={t("patient.view.tooltip.validation.status.unset")} aria-label={t("patient.view.tooltip.validation.status.unset")}>
      <UnsetIcon className="text-grey-700" />
    </IconButton>
  }

  return null
}

export default ValidateSegmentIcon