import { useRecordContext, RaRecord } from 'react-admin';
import { LinkProps, Link } from 'react-router-dom';

interface Props extends Omit<LinkProps, "to"> {
  getPath?: (record: RaRecord) => string
  icon?: JSX.Element
  isHidden?: (record) => boolean
  to?: LinkProps["to"]
}

function CustomLinkButton({ getPath, icon, children, isHidden, ...linkProps }: Props) {
  const record = useRecordContext();
  const hidden = isHidden?.(record)
  if(hidden){
    return null
  }

  if(getPath){
    return <Link {...linkProps} onClick={(e) => e.stopPropagation()} to={getPath(record)} >
      {children}
    </Link>
  }

  return (
    <Link to={linkProps.to} onClick={(e) => e.stopPropagation()} {...linkProps}>
      {children}
    </Link>
  )
}

export default CustomLinkButton