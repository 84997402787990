import exp from "constants";
import { useGetList } from "react-admin";

export const useCTBasicInfo = (groupId: string) => {
  const { data: sites } = useGetList("locations", {
    filter: {
      "encounters[].subject.group": groupId,
    },
  });
  const { data: visits } = useGetList("appointments", {
    filter: {
      grouping: "description",
      "subject.group": groupId,
      aggregation: "count()",
    },
  });
  const { data: modalities } = useGetList("imagingstudies", {
    filter: {
      grouping: "modality[].code",
      "subject.group": groupId,
      aggregation: "count()",
    },
    sort: { field: "modality[].code", order: "ASC" },
  });
  const { data: scanners } = useGetList("devices", {
    filter: {
      grouping: "name[0].value",
      "imagingstudies[].subject.group": groupId,
      aggregation: "count()",
    },
  });
  return { sites, visits, modalities, scanners };
};

export const usePatientStatus = (groupId) => {
  const { data } = useGetList("researchsubjects", {
    filter: {
      grouping: "progress[].subjectState",
      "subject.group": groupId,
      aggregation: "count()",
    },
  });
  return data;
};

export const useGetPatientData = (
  clinicalTrialId: string,
  selectedVisit: string | null,
  selectedSiteId: string | null
) => {
  return useGetList("patients", {
    filter: {
      group: clinicalTrialId,
      aggregation: "count()",
      "appointments[].description": selectedVisit ?? null,
      "encounters[].encounter.location[].location": selectedSiteId ?? null,
    },
  });
};

export const useGetVisitsData = (
  clinicalTrialId: string,
  selectedScannerLabel: string | null,
  selectedSiteId: string | null
) => {
  return useGetList("appointments", {
    filter: {
      grouping: "description",
      "subject.group": clinicalTrialId,
      aggregation: "count()",
      "encounters[].imagingstudies[].device.name[0].value":
        selectedScannerLabel ?? null,
      "encounters[].location[].location": selectedSiteId ?? null,
    },
  });
};

export const useGetModalitiesData = (
  clinicalTrialId: string,
  selectedVisitLabel: string | null
) => {
  return useGetList("imagingstudies", {
    filter: {
      grouping: "modality[].code",
      "subject.group": clinicalTrialId,
      aggregation: "count()",
      "encounter.appointment.description": selectedVisitLabel ?? null,
    },
  });
};

export const useGetPatientStatusData = (
  clinicalTrialId: string,
  selectedSiteId: string | null,
  selectedVisitLabel: string | null
) => {
  return useGetList("researchsubjects", {
    filter: {
      grouping: "progress[].subjectState",
      "subject.group": clinicalTrialId,
      aggregation: "count()",
      "subject.encounters[].encounter.location[].location":
        selectedSiteId ?? null,
      "subject.appointments[].description": selectedVisitLabel ?? null,
    },
  });
};

export const useGetAnalysisSummary = (
  grouping: string,
  clinicalTrialId: string,
  selectedScannerLabel: string | null = null,
  selectedSiteId: string | null = null,
  selectedModality: string | null = null,
  filter: string | null = null
) => {
  return useGetList("tasks", {
    filter: {
      grouping: grouping,
      "for.group": clinicalTrialId,
      aggregation: "count()",
      "basedOn[].device.deviceName[0].name": selectedScannerLabel ?? null,
      "basedOn[].modality[].coding[].code": selectedModality ?? null,
      "basedOn[].encounter.location[].location": selectedSiteId ?? null,
      _filter: filter ?? "",
    },
  });
};

export const useGetDetailedAnalysis = (clinicalTrialId: string) => {
  return useGetList("analyzes", {
    filter: {
      _include: "ImagingStudy:performer",
      groups: [Number(clinicalTrialId)],
      status: "error",
      "steps.name": "dicom_conversion",
    },
    sort: { field: "endedAt", order: "DESC" },
    pagination: { page: 1, perPage: 10 },
  });
};
