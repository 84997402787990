import { useIntrospect } from "@api-platform/admin";
import { useState } from "react";
import {
  useInput,
  useRecordContext,
  useResourceContext,
  useTranslate,
} from "react-admin";

import "../styles/ClinicalTrialConfiguration.css";

const translateRadlexItem = (item: string, translate) =>
  item
    .split("|")
    .map((radlex) => translate(radlex))
    .join(", ");

const ClinicalTrialMarkerOption = ({ item }) => {
  const translate = useTranslate();
  return (
    <option key={"available-marker-option-" + item} value={item}>
      {translateRadlexItem(item, translate)} ({item})
    </option>
  );
};

const ClinicalTrialMarker = ({ item, visits, deleteMarker, toggleMarker }) => {
  const translate = useTranslate();
  return (
    <tr key={"clinical-trial-row-" + item}>
      <th>
        {translateRadlexItem(item, translate)} ({item}){" "}
        <input
          type="button"
          id={"remove-marker-" + item}
          defaultValue="-"
          onClick={deleteMarker}
        />
      </th>
      {visits.map((visit, index) => {
        const checked = visit.outputs.reduce(
          (accumulator, output) => accumulator || item === output.title,
          false
        );
        return (
          <td key={"clinical-trial-" + item + "-cell-" + index}>
            <input
              type="checkbox"
              id={"marker-" + index + "-" + item}
              defaultValue="1"
              defaultChecked={checked}
              onClick={toggleMarker}
            />
          </td>
        );
      })}
      <td className="empty-cell">&nbsp;</td>
    </tr>
  );
};

const ClinicalTrialConfigurationField = ({ source }) => {
  const { field } = useInput({ source });
  const record = useRecordContext();
  const resource = useResourceContext();
  const { data } = useIntrospect();
  const translate = useTranslate();
  const initialValue =
    record !== undefined &&
    record[source] !== null &&
    typeof record[source] === "object" &&
    record[source].visits &&
    record[source].visits.length
      ? record[source]
      : {
          title: "",
          visits: [],
          molecule: record !== undefined ? record[source]?.molecule : null,
          sponsor: record !== undefined ? record.name : null,
          expected_number_of_subjects:
            record !== undefined
              ? record[source]?.expected_number_of_subjects
              : null,
          expected_number_of_visits:
            record !== undefined
              ? record[source]?.expected_number_of_visits
              : null,
          expected_start_date:
            record !== undefined ? record[source]?.expected_start_date : null,
          expected_end_date:
            record !== undefined ? record[source]?.expected_end_date : null,
        };

  const [value, setValue] = useState(initialValue);
  const changeValue = (newValue) => {
    field.onChange({
      target: {
        value: newValue,
      },
    });
    setValue(newValue);
  };
  const addVisit = () => {
    const delay =
      value.visits.length > 1
        ? Math.round(
            value.visits.reduce((accumulator, visit) => {
              return accumulator + visit.visit_delay;
            }, 0) /
              (value.visits.length - 1) +
              value.visits.reduce((accumulator, visit) => {
                return accumulator < visit.visit_delay
                  ? visit.visit_delay
                  : accumulator;
              }, 0)
          )
        : value.visits.length > 0
        ? 28
        : 0;
    const range =
      value.visits.length > 0
        ? value.visits.reduce((accumulator, visit) => {
            return accumulator < visit.visit_range
              ? visit.visit_range
              : accumulator;
          }, 7)
        : 7;
    const title =
      value.visits.length > 0 ? "week" + Math.round(delay / 7) : "screening";
    const expectedSubjects = 0;
    changeValue({
      ...value,
      visits: value.visits.concat([
        {
          title: title,
          visit_delay: delay,
          visit_range: range,
          expected_number_of_subjects: expectedSubjects,
          outputs: [],
        },
      ]),
    });
  };
  const deleteVisit = (event) => {
    const id = event.target.id;
    const indexToDelete = parseInt(id.substring(id.lastIndexOf("-") + 1));
    changeValue({
      ...value,
      visits: value.visits.filter((visit, index) => index !== indexToDelete),
    });
  };
  const addMarker = (event) => {
    const marker = event.target.value;
    if (value.visits.length > 0) {
      changeValue({
        ...value,
        visits: value.visits.map((visit, index) => {
          return {
            ...visit,
            outputs: visit.outputs.concat([
              {
                title: marker,
                description: translateRadlexItem(marker, translate),
              },
            ]),
          };
        }),
      });
    }
  };
  const deleteMarker = (event) => {
    const id = event.target.id;
    const radlexToDelete = id.substring(id.lastIndexOf("-") + 1);
    changeValue({
      ...value,
      visits: value.visits.map((visit) => {
        return {
          ...visit,
          outputs: visit.outputs.filter(
            (output) => output.title !== radlexToDelete
          ),
        };
      }),
    });
  };
  const toggleMarker = (event) => {
    const id = event.target.id.substring(event.target.id.indexOf("-") + 1);
    const visitIndex = parseInt(id.substring(0, id.indexOf("-")));
    const radlex = id.substring(id.indexOf("-") + 1);
    const checked = event.target.checked;
    changeValue({
      ...value,
      visits: value.visits.map((visit, index) => {
        return visitIndex === index
          ? {
              ...visit,
              outputs: checked
                ? visit.outputs.concat([
                    {
                      title: radlex,
                      description: translateRadlexItem(radlex, translate),
                    },
                  ])
                : visit.outputs.filter((output) => output.title !== radlex),
            }
          : visit;
      }),
    });
  };
  const changeVisitSetting = (node, attribute: string) => {
    const id = node.id;
    const visitIndex = parseInt(id.substring(id.lastIndexOf("-") + 1));
    changeValue({
      ...value,
      visits: value.visits.map((visit, index) => {
        if (visitIndex === index) {
          visit[attribute] = parseInt(node.value);
        }
        return visit;
      }),
    });
  };
  const changeVisitDelay = (event) => {
    changeVisitSetting(event.target, "visit_delay");
  };
  const changeVisitRange = (event) => {
    changeVisitSetting(event.target, "visit_range");
  };
  const changeExpectedNumOfSubjects = (event) => {
    changeVisitSetting(event.target, "expected_number_of_subjects");
  };
  let visits = typeof value?.visits == "object" ? value.visits : [];
  if (!data) {
    return <></>;
  }
  const introspect =
    data.data &&
    data.data.resources &&
    data.data.resources.find(({ name }) => name === resource);
  const fieldSchema = introspect?.fields.find(({ name }) => name === source);
  const availableMarkers = (
    typeof fieldSchema?.enum == "object" ? fieldSchema.enum : []
  ) as string[];
  let markers = visits
    .reduce(
      (accumulator, visit) =>
        accumulator.concat(visit.outputs.map((output) => output.title)),
      []
    )
    .filter((value, index, array) => array.indexOf(value) === index);
  return (
    <>
      <input
        type="hidden"
        {...field}
        value={JSON.stringify(value.visits.length ? value : null)}
      />
      <table className="clinical-trial-configuration-table">
        <thead>
          <tr>
            <th>{translate("resource.clinical_trial.visit_name")}</th>
            {visits.map((visit, index) => (
              <th key={"clinical-trial-visit-name-cell-" + index}>
                <input
                  type="text"
                  id={"visit-name-" + index}
                  defaultValue={visit.title}
                />{" "}
                <input
                  type="button"
                  id={"remove-visit-" + index}
                  defaultValue="-"
                  onClick={deleteVisit}
                />
              </th>
            ))}
            <th className="add-row-column">
              <input
                type="button"
                id="add-visit"
                defaultValue="+"
                onClick={addVisit}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr key="clinical-trial-delay-row">
            <th>{translate("resource.clinical_trial.visit_delay")}</th>
            {visits.map((visit, index) => (
              <td key={"clinical-trial-delay-cell-" + index}>
                <input
                  type="number"
                  id={"visit-delay-" + index}
                  defaultValue={visit.visit_delay}
                  readOnly={index === 0}
                  min="0"
                  pattern="\d*"
                  onChange={changeVisitDelay}
                />
              </td>
            ))}
            <td className="empty-cell">&nbsp;</td>
          </tr>
          <tr key="clinical-trial-range-row">
            <th>{translate("resource.clinical_trial.visit_range")}</th>
            {visits.map((visit, index) => (
              <td key={"clinical-trial-range-cell-" + index}>
                <input
                  type="number"
                  id={"visit-range-" + index}
                  defaultValue={visit.visit_range}
                  min="0"
                  pattern="\d*"
                  onChange={changeVisitRange}
                />
              </td>
            ))}
            <td className="empty-cell">&nbsp;</td>
          </tr>
          <tr key="clinical-trial-expected-number-of-subjects-row">
            <th>
              {translate("resource.clinical_trial.expected_number_of_subjects")}
            </th>
            {visits.map((visit, index) => (
              <td
                key={"clinical-trial-expected-number-of-subjects-cell-" + index}
              >
                <input
                  type="number"
                  id={"visit-expected-number-of-subjects-" + index}
                  defaultValue={visit.expected_number_of_subjects}
                  min="0"
                  pattern="\d*"
                  onChange={changeExpectedNumOfSubjects}
                />
              </td>
            ))}
            <td className="empty-cell">&nbsp;</td>
          </tr>
          {markers.map((marker) => (
            <ClinicalTrialMarker
              item={marker}
              visits={visits}
              toggleMarker={toggleMarker}
              deleteMarker={deleteMarker}
            />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th className="add-row-column">
              <select id="marker-list" onChange={addMarker}>
                <option key="option-empty">
                  {translate("resource.clinical_trial.select_a_marker_to_add")}
                </option>
                {availableMarkers?.map((item) => (
                  <ClinicalTrialMarkerOption item={item} />
                ))}
              </select>
            </th>
            {visits.map((visit, index) => (
              <td key={"clinical-trial-last-cell-" + index}>&nbsp;</td>
            ))}
            <td className="empty-cell">&nbsp;</td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

export default ClinicalTrialConfigurationField;
