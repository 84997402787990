import {
  DateField,
  useRecordContext,
  DateFieldProps
} from 'react-admin';

import { YYYYMMDDtoDate } from 'shared/utils/date';


function DateFieldYYYYMMDD({...props}: DateFieldProps) {
  const record = useRecordContext();
  const dateTime: string = record?.[props.source]
  const date = dateTime?.split("_")?.[0]
  const formattedRecord = { ...record, acquisition: YYYYMMDDtoDate(date) }
  return <DateField {...props} record={formattedRecord} />
}

export default DateFieldYYYYMMDD