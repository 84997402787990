import React from 'react'
import { Box, Button, ButtonGroup, Slider, Popper } from '@mui/material'
import { data } from 'dcmjs'
import { snapshot, useSnapshot } from 'valtio'
import { useTranslate } from "ra-core"
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import BrushIcon from '@mui/icons-material/Brush';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import DeleteIcon from '@mui/icons-material/Delete';
//@ts-ignore
import Eraser_SVG from 'shared/images/eraser.svg'
import { brushInstanceNames } from 'lib/cornerstone/setupCornerstoneViewer'
import { segmentation as cstSegmentation, utilities } from '@cornerstonejs/tools'

import validationState, { resetValidation, setActiveEditTool, initValidation } from 'stores/validation'
import viewerState, { Segment, ViewerState } from 'stores/viewer'
import getIds from 'lib/cornerstone/helpers/getIds'
import { segmentColorsToCSS } from "shared/utils/color";
import { deleteValidationSEG } from 'lib/cornerstone/helpers/fileManipulation'
import { Marker } from '../quantification/components/Marker'
import ValidationSave from 'features/patients/validation/ValidationSave'
import useValidation from 'shared/hooks/useValidation'

export type ValidateProps = EditToolsProps & { seriesMarkers: Marker[] }
type EditToolsProps = { dataset: data.NaturalizedDataset }

function EditTools({ dataset }: EditToolsProps) {
  const t = useTranslate()
  const { seriesUID } = useSnapshot(validationState)
  const { segmentsPerSeries, editSegmentNumber } = useSnapshot(viewerState)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  if (!editSegmentNumber || seriesUID !== dataset.SeriesInstanceUID) {
    return null
  }

  const segment = segmentsPerSeries.find(segment => segment.seriesUID === seriesUID && segment.SegmentNumber === editSegmentNumber)
  const segcolor = segmentColorsToCSS([segment as Segment])
  const { toolGroupId } = getIds()
  const openBrushSizer = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const selectBrushTool = (toolName: typeof brushInstanceNames[keyof typeof brushInstanceNames]) => {
    return () => {
      if (!segment?.representationUID) {
        console.error(`No segmentation representation  for the segment ${editSegmentNumber} of the series ${seriesUID} `)
        return
      }
      cstSegmentation.activeSegmentation.setActiveSegmentationRepresentation(toolGroupId, segment.representationUID)
      const activeSegmentation = cstSegmentation.activeSegmentation.getActiveSegmentation(toolGroupId)
      cstSegmentation.segmentIndex.setActiveSegmentIndex(activeSegmentation.segmentationId, editSegmentNumber)
      setActiveEditTool(toolName)
    }
  }

  const changeBrushSize = (event: MouseEvent, value: number) => {
    utilities.segmentation.setBrushSizeForToolGroup(toolGroupId, value)
  }

  const open = Boolean(anchorEl);
  const id = open ? '"brush-sizer"' : undefined;
  return (
    <>
      <Button onClick={selectBrushTool(brushInstanceNames.SphereBrush)} title={t("patient.view.tooltip.validation.sphere.brush")}>
        <BrushIcon style={segcolor} className='text-white' />
      </Button>
      <Button onClick={selectBrushTool(brushInstanceNames.SphereEraser)} title={t("patient.view.tooltip.validation.sphere.erase")}>
        <img style={segcolor} className='text-white' src={Eraser_SVG} alt="Erase" />
      </Button>
      <Button aria-describedby={id} onClick={openBrushSizer} title={t("patient.view.tooltip.validation.sphere.radius")}>
        <DesignServicesIcon style={segcolor} className='text-white' />
      </Button>
      <Popper id={id} open={Boolean(anchorEl)} anchorEl={anchorEl} className='z-50'>
        <Box className='border px-2 pt-1 min-w-[100px] bg-stone-800'>
          <Slider size="small" defaultValue={5} min={.5} max={15} step={.5} onChange={changeBrushSize} />
        </Box>
      </Popper>
    </>
  )
}

function ValidateIcon({ seriesMarkers, dataset }: ValidateProps) {
  const t = useTranslate()
  const { instanceMetadata } = useSnapshot(viewerState)
  const validation = useValidation(dataset)
  const seriesUID = seriesMarkers[0]?.seriesUID

  const validate = () => initValidation(seriesMarkers)
  const reset = async () => {
    resetValidation();
    viewerState.instanceMetadata = structuredClone(snapshot(viewerState.instanceMetadata)) as ViewerState["instanceMetadata"]
  }
  const isValidationInProgress = !!validation?.isValtioStore
  const deleteValdation = () => deleteValidationSEG(validation?.referenceSeriesUID)

  // Series not loaded
  if (seriesUID !== instanceMetadata?.SeriesInstanceUID || !instanceMetadata?.SeriesInstanceUID) {
    return null
  }

  // TODO Maybe found a better way to know if saved validation is displayed
  if (!!validation?.['referenceSeriesUID'] && !isValidationInProgress) {
    return <>
      <ValidationSave dataset={dataset} seriesMarkers={seriesMarkers} />
      <Button onClick={deleteValdation} title={t("patient.view.tooltip.validation.delete")} aria-label={t("patient.view.tooltip.validation.delete")}>
        <DeleteIcon className='text-white' />
      </Button>
    </>
  }

  if (isValidationInProgress) {
    return <>
      <ValidationSave dataset={dataset} seriesMarkers={seriesMarkers} />
      <Button onClick={reset} size="small" title={t("patient.view.tooltip.validation.cancel")} aria-label={t("patient.view.tooltip.validation.cancel")} >
        <CancelIcon className='text-white' />
      </Button>
    </>
  } else {
    return <Button onClick={validate} size="small" title={t("patient.view.tooltip.validation.validate")} aria-label={t("patient.view.tooltip.validation.validate")} >
      <FactCheckOutlinedIcon className='text-white' />
    </Button>
  }
}

function ValidationToolBox({ dataset, seriesMarkers }: ValidateProps) {
  return <ButtonGroup variant="outlined" aria-label="outlined button group" className='mx-2'>
    <EditTools dataset={dataset} />
    <ValidateIcon seriesMarkers={seriesMarkers} dataset={dataset} />
  </ButtonGroup>
}

export default ValidationToolBox