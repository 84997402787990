import {
  AutocompleteInput,
  CreateButton,
  DeleteWithConfirmButton,
  EditButton,
  ExportButton,
  FilterButton,
  ReferenceInput,
  SearchInput,
  TextField,
  TopToolbar,
  usePermissions,
} from "react-admin";
import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import { Role } from "../../../shared/types/users";
import { Organization } from "../../../shared/types/organizations";
import BulkActionButtons from "../../../shared/components/BulkActionButtons";

const ListActions = (permissions: any) => (
  <TopToolbar>
    {(permissions?.role.name === "SuperAdmin" ||
      permissions?.role.name === "Admin") && <FilterButton />}
    {permissions?.role.name !== "Member" && <CreateButton />}
    <ExportButton />
  </TopToolbar>
);

const userFilters = (permissions) =>
  [
    <SearchInput source="q" alwaysOn />,
    permissions?.role.name === "SuperAdmin" ||
    permissions?.role.name === "Admin" ? (
      <ReferenceInput
        source="role_id"
        reference="roles"
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteInput
          emptyText="-- None --"
          optionText={(choice?: Role) =>
            choice?.id ? `${choice.name}` : "-- None --"
          }
        />
      </ReferenceInput>
    ) : null,
    permissions?.role.name === "SuperAdmin" ? (
      <ReferenceInput
        source="organization_id"
        reference="organizations"
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteInput
          emptyText="-- None --"
          optionText={(choice?: Organization) =>
            choice?.id ? `${choice.name}` : "-- None --"
          }
        />
      </ReferenceInput>
    ) : null,
  ].filter((filter) => filter !== null);

export const UserList = () => {
  const { permissions } = usePermissions();
  if (!permissions) return null;
  return (
    <ListGuesser
      actions={ListActions(permissions)}
      sort={{ field: "email", order: "DESC" }}
      hasEdit={false}
      hasShow={false}
      filters={userFilters(permissions)}
      rowClick="edit"
      bulkActionButtons={
        permissions?.role.name !== "Member" ? <BulkActionButtons /> : false
      }
    >
      <FieldGuesser source="email" />
      <TextField sortBy="role" source="role.name" />

      {permissions?.role.name === "SuperAdmin" && (
        <FieldGuesser source="is_superuser" />
      )}
      <FieldGuesser source="is_active" />
      <FieldGuesser source="is_verified" />

      {permissions?.role.name === "SuperAdmin" && (
        <TextField sortBy="organization" source="organization.name" />
      )}
      <EditButton />
      <DeleteWithConfirmButton confirmContent="ra.confirm.delete" />
    </ListGuesser>
  );
};
