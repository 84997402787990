import { PasswordInput, SimpleForm, useTranslate } from "react-admin";
import {
  confirmPasswordValidation,
  validatepassword,
} from "../../../shared/utils/inputValidation";
import ResetPasswordButton from "../components/ResetPasswordButton";
import { Grid, Typography, Button } from "@mui/material";
import passwordGenerator from "shared/utils/passwordGenerator";
import { useFormContext } from "react-hook-form";
import PasswordValidation from "shared/components/PasswordValidation";

export const ResetPassword = () => {
  const t = useTranslate();

  const GeneratePasswordButton = () => {
    const { setValue, trigger } = useFormContext();
    const pwd = passwordGenerator();
    return (
      <Button
        onClick={() => {
          setValue("password", pwd);
          setValue("confirm_password", pwd);
          trigger("password");
        }}
      >
        Auto-fill Password
      </Button>
    );
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <SimpleForm
        mode="onBlur"
        reValidateMode="onBlur"
        toolbar={<ResetPasswordButton />}
      >
        <Typography variant="h5" align="center" className="title">
          {t("ra.auth.reset_password")}
        </Typography>
        <PasswordInput
          source="password"
          className="whitespace-pre-line"
          autoComplete="new-password"
          label={t("ra.auth.password")}
          isRequired
          validate={validatepassword(true)}
        />
        <PasswordValidation />
        <PasswordInput
          source="confirm_password"
          autoComplete="new-password"
          label={t("ra.auth.confirm_password")}
          isRequired
          validate={confirmPasswordValidation}
        />
        <GeneratePasswordButton />
      </SimpleForm>
    </Grid>
  );
};
