import type { data } from "dcmjs";
import { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem } from "@mui/material"
import { useTranslate, usePermissions } from 'ra-core';

import SaveAsIcon from '@mui/icons-material/SaveAs';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SaveIcon from '@mui/icons-material/Save';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ValidatedIcon from '@mui/icons-material/TaskAltOutlined';
import UnvalidatedIcon from '@mui/icons-material/UnpublishedOutlined';
import UnsetIcon from '@mui/icons-material/RemoveCircleOutline';
import EraseSegmentIcon from '@mui/icons-material/AutoFixNormal';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';

import { downloadData, getDicomSEGBlobFromDataset, saveValidationSEG } from "lib/cornerstone/helpers/fileManipulation";
import type { ValidateProps } from "./ValidationToolBox";
import useValidation from "shared/hooks/useValidation";
import { MarkerValidationInfo, ValidationStatus } from "stores/validation";
import JSZip from "jszip";
import { translateMarkerRID } from "shared/utils/recursive";


function ValidationSave({ dataset }: ValidateProps) {
  const t = useTranslate()
  const [open, setOpen] = useState(false)
  const validation = useValidation(dataset)

  if (!validation) return null

  const untouched = Object.values(validation.markerValidations).every(v => v.status === "unset")
  if (untouched) return null

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const save = () => {
    handleClose()
    saveValidationSEG(dataset)
  }
  const isSavedValidation = !validation?.isValtioStore
  const copyToClipboard = async () => {
    const rich = document.getElementById("report-content").innerHTML
    const html = new Blob([rich], { type: "text/html" });
    const data = new ClipboardItem({ "text/html": html })
    await navigator.clipboard.write([data])
  }

  const validationItems = Object.entries(validation.markerValidations)
    .filter(([rid, value]) => value.status !== "unset");
  return <>
    <Button onClick={handleOpen} title={t("patient.view.tooltip.validation.save")} aria-label={t("patient.view.tooltip.validation.save")}>
      {isSavedValidation ? <SummarizeIcon className='text-white' /> : <SaveAsIcon className='text-white' />}
    </Button>
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle>
        Validation
      </DialogTitle>
      <DialogContent id="report-content">
        <ul>
          {validationItems
            .map(valid => <ValidationItem key={valid[0]} validationItem={valid} />)}
        </ul>
      </DialogContent>
      <DialogActions>
        <Button startIcon={<ContentCopyIcon />} onClick={copyToClipboard} variant="contained">Copy in clipboard</Button>
        {!isSavedValidation ? <Button startIcon={<SaveIcon />} variant="contained" onClick={save}>Save</Button> : null}
        <ValidationDownloadButton dataset={dataset} validationItems={validationItems} />
      </DialogActions>
    </Dialog>
  </>
}

export default ValidationSave

const ValidationStatusIcons: Record<ValidationStatus, JSX.Element> = {
  "unset": <UnsetIcon className="text-grey-700" />,
  "validated": <ValidatedIcon className="text-green-700" />,
  "unvalidated": <UnvalidatedIcon className="text-red-700" />,
  "edited": <EditIcon className="text-red-700" />,
  "erased": <EraseSegmentIcon className="text-red-700" />,
}

function ValidationItem({ validationItem }: { validationItem: [string, MarkerValidationInfo] }) {
  const t = useTranslate()
  const [rid, { status, screenshots }] = validationItem
  const markerName = translateMarkerRID(rid, t);
  return <li>
    <div className="border rounded-lg  px-3 pb-2 mb-2 bg-slate-700">
      {ValidationStatusIcons[status]}
      <span className="p-2 text-lg inline-block first-letter:uppercase">{markerName}</span>
      <span className="hidden"> {` : ${status}`}</span>
      <div className="flex w-1/2">
        {screenshots?.map(ss => <img className="w-2/3" src={ss} alt={rid} />)}
      </div>
    </div>
  </li>
}

type ValidationDownloadButtonProps = {
  dataset: data.NaturalizedDataset;
  validationItems: [string, MarkerValidationInfo][]
};

function ValidationDownloadButton({ dataset, validationItems }: ValidationDownloadButtonProps) {
  const t = useTranslate()
  const { permissions } = usePermissions()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadDicomSEG = async () => {
    handleClose()
    const blob = getDicomSEGBlobFromDataset(dataset)
    downloadData(blob, "Modified_Dicom_SEG.dcm")
  }

  const downloadScreenshots = async () => {
    handleClose()
    validationItems.forEach(([rid, { screenshots }]) => {
      screenshots.forEach((dataUrl, i) => downloadData(dataUrl, `${dataset.SeriesDescription}_${rid}_${i}.png`))
    })
  }

  const downloadReport = async () => {
    handleClose()
    const report = validationItems.map(([rid, { status }]) => `- ${translateMarkerRID(rid, t)} : ${status}`).join('\n')
    const blob = new Blob([report], { type: "text/plain" })
    downloadData(blob, "report.txt")
  }

  const downloadZip = async () => {
    const zip = new JSZip()
    // Add text report to zip
    const report = validationItems.map(([rid, { status }]) => `- ${translateMarkerRID(rid, t)} : ${status}`).join('\n')
    zip.file("validation_report.txt", report)

    // Add screenshots to zip
    const screenshotFolder = zip.folder("screenshots")
    validationItems.forEach(([rid, { screenshots }]) => {
      screenshots.forEach((dataUrl, i) =>
        screenshotFolder.file(`${dataset.SeriesDescription}_${rid}_${i}.png`, dataUrl.split('base64,')[1], { base64: true })
      )
    })

    // Add dicom SEG to zip if SuperAdmin
    if (permissions.role.name === "SuperAdmin") {
      const blob = getDicomSEGBlobFromDataset(dataset)
      zip.file("Modified_Dicom_SEG.dcm", blob)
    }
    const blob = await zip.generateAsync({ type: "blob" })
    downloadData(blob, "validation.zip")
  }

  return <>
    <Button
      onClick={handleClick}
      startIcon={<DownloadIcon />}
      variant="contained">
      Download
    </Button>
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={downloadZip}>All in .zip</MenuItem>
      <MenuItem onClick={downloadScreenshots}>Screenshot</MenuItem>
      <MenuItem onClick={downloadReport}>Text report</MenuItem>
      {permissions.role.name === "SuperAdmin" && <MenuItem onClick={downloadDicomSEG}>Dicom SEG</MenuItem>}
    </Menu>
  </>
}


