import { UserForm } from "./UserForm";
import {
  BooleanInput,
  DeleteWithConfirmButton,
  Toolbar,
  SaveButton,
  useRecordContext,
  ReferenceInput,
  SelectInput,
  required,
  TextInput,
} from "react-admin";
import { EditGuesser, InputGuesser } from "@api-platform/admin";

const EditToolbar = () => {
  const record = useRecordContext();
  return (
    <Toolbar>
      <SaveButton />
      <DeleteWithConfirmButton
        confirmContent="ra.confirm.delete"
        translateOptions={{ email: record.email }}
      />
    </Toolbar>
  );
};

const RoleInput = () => {
  const record = useRecordContext();
  return !record.is_superuser ? (
    <ReferenceInput source="role.id" reference="roles">
      <SelectInput
        optionText="name"
        source="role"
        isRequired
        validate={required()}
      />
    </ReferenceInput>
  ) : (
    <TextInput source="role.name" disabled />
  );
};
export const UserEdit = () => (
  <EditGuesser
    toolbar={<EditToolbar />}
    redirect={false}
    mode="onBlur"
    reValidateMode="onBlur"
  >
    <InputGuesser source="email" disabled />
    <UserForm />
    <RoleInput />
    <BooleanInput source="is_active" />
    <BooleanInput source="is_verified" />
  </EditGuesser>
);
