import { Datagrid, TextField, List, useLocaleState } from "react-admin";
import DateFieldUTC from "shared/components/DateFieldUTC";

export const ActivityList = () => {
  const [locale] = useLocaleState();
  return (
    <List sort={{ field: "createdAt", order: "DESC" }}>
      <Datagrid hover={false} bulkActionButtons={false}>
        <DateFieldUTC
          locales={locale}
          source="createdAt"
          options={{
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false,
          }}
        />
        <TextField source="user.email" sortable={false} />
        <TextField source="action" sortable={false} />
      </Datagrid>
    </List>
  );
};
