// @ts-ignore
let env = typeof window !== "undefined" && window.env ? window.env : process.env
let apiUrl = env.API_URL
  ? env.API_URL
  : env.REACT_APP_API_URL
  ? env.REACT_APP_API_URL
  : '';
let normativeUrl = env.NORMATIVE_URL
  ? env.NORMATIVE_URL
  : env.REACT_APP_NORMATIVE_URL
  ? env.REACT_APP_NORMATIVE_URL
  : "https://demo-normative.qynapse.com";
export const ENTRYPOINT: string = apiUrl
export const DICOM_WEB_ENTRYPOINT: string = `${apiUrl}/dicom-web`
export const FHIR_ENTRYPOINT: string = `${apiUrl}/fhir`
export const NORMATIVE_ENTRYPOINT: string = normativeUrl
export const MANUALS_ENTRYPOINT: string = "https://manuals.qynapse.com"

// export const DICOM_WEB_ENTRYPOINT: string = `http://localhost:8083/dicom-web`
