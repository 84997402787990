import users from "../../features/users";
import organizations from "../../features/organizations";
import analysisGroups from "../../features/analysis-groups";
import authenticationMethods from "../../features/authentication-methods";
import dicomNodes from "../../features/dicom-nodes";
import activities from "../../features/activities";

export const adminResources = [
  {
    name: "users",
    props: users,
    resourcePermision: ["SuperAdmin", "Admin", "Member"],
    createPermission: ["SuperAdmin", "Admin"],
    listPermission: ["SuperAdmin", "Admin"],
    showPermission: [],
    editPermission: ["SuperAdmin", "Admin"], //TODO: need to refactor with adding user profile
  },
  {
    name: "organizations",
    props: organizations,
    resourcePermision: ["SuperAdmin", "Admin", "Member"],
    createPermission: ["SuperAdmin"],
    listPermission: ["SuperAdmin", "Admin"],
    showPermission: [],
    editPermission: ["SuperAdmin", "Admin"],
  },
  {
    name: "analyzis_groups",
    props: analysisGroups,
    resourcePermision: ["SuperAdmin", "Admin", "Member"],
    createPermission: ["SuperAdmin", "Admin"],
    listPermission: ["SuperAdmin", "Admin"],
    showPermission: [],
    editPermission: ["SuperAdmin", "Admin"],
  },
  {
    name: "authentication_methods",
    props: authenticationMethods,
    resourcePermision: ["SuperAdmin", "Admin"],
    createPermission: ["SuperAdmin", "Admin"],
    listPermission: ["SuperAdmin", "Admin"],
    showPermission: ["SuperAdmin", "Admin"],
    editPermission: ["SuperAdmin", "Admin"],
  },
  {
    name: "dicom_nodes",
    props: dicomNodes,
    resourcePermision: ["SuperAdmin", "Admin", "Member"],
    createPermission: ["SuperAdmin", "Admin"],
    listPermission: ["SuperAdmin", "Admin"],
    showPermission: [],
    editPermission: ["SuperAdmin", "Admin"],
  },
  {
    name: "activity",
    props: activities,
    resourcePermision: ["SuperAdmin", "Admin"],
    createPermission: [],
    listPermission: ["SuperAdmin", "Admin"],
    showPermission: [],
    editPermission: [],
  },
];
