import { useEffect, useState } from 'react';
import {
  useRecordContext,
  TextField,
  TextFieldProps,
  useLocaleState
} from 'react-admin';
import { secondsToHHMMss, getRemainingTime } from 'shared/utils/date';


function ETAField({ ...props }: TextFieldProps) {
  const record = useRecordContext();
  const [locale] = useLocaleState()
  const rtf = new Intl.RelativeTimeFormat(locale, { style: 'long' });
  const [remainingTime, setRemainingTime] = useState(null);
  

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (['running'].includes(record.status)) {
      interval = setInterval(() => {
        setRemainingTime(getRemainingTime(record?.estimatedEndDate));
      }, 1000);
    }else{
      clearInterval(interval)
    }
    return () => clearInterval(interval);
  }, [record]);

  if (!['running'].includes(record.status) || remainingTime < 0) {
    return <TextField {...props} source="eta" record={{ ...record, eta: "" }} />
  }

  if(remainingTime < 60 ){
    return <TextField {...props} source="eta" record={{ ...record, eta: rtf.format(Math.floor(remainingTime/3600), 'seconds') }} />
  }else if(remainingTime < 60 * 60){
    return <TextField {...props} source="eta" record={{ ...record, eta: rtf.format(Math.floor(remainingTime/60), 'minutes') }} />
  }
  
  return <TextField {...props} source="eta" record={{ ...record, eta: secondsToHHMMss(remainingTime) }} />
}

export default ETAField