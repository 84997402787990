// https://github.com/cornerstonejs/cornerstone3D-beta/blob/main/utils/demo/helpers/ptScalingMetaDataProvider.js

import { utilities as csUtils } from "@cornerstonejs/core/dist/esm";

const scalingPerImageId = {};

function addInstance(imageId: string, scalingMetaData: any) {
  const imageURI = csUtils.imageIdToURI(imageId);
  // @ts-ignore
  scalingPerImageId[imageURI] = scalingMetaData;
}

function get(type: string, imageId: string) {
  if (type === "scalingModule") {
    const imageURI = csUtils.imageIdToURI(imageId);
    // @ts-ignore
    return scalingPerImageId[imageURI];
  }
}

const provider = {
  addInstance,
  get,
};

export default provider;
