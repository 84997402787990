import {
  CreateButton,
  DeleteWithConfirmButton,
  EditButton,
  ExportButton,
  SearchInput,
  TopToolbar,
  usePermissions,
} from "react-admin";

import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import BulkActionButtons from "../../../shared/components/BulkActionButtons";

const ListActions = (permissions: any) => (
  <TopToolbar>
    {permissions.role.name === "SuperAdmin" && <CreateButton />}
    <ExportButton />
  </TopToolbar>
);

const organizationFilters = [<SearchInput source="q" alwaysOn />];

export const OrganizationList = () => {
  const { permissions } = usePermissions();
  return (
    <ListGuesser
      actions={ListActions(permissions)}
      sort={{ field: "name", order: "DESC" }}
      hasShow={false}
      hasEdit={false}
      filters={organizationFilters}
      rowClick="edit"
      bulkActionButtons={<BulkActionButtons />}
    >
      <FieldGuesser source="name" />
      <FieldGuesser source="domain" />
      <EditButton />
      {permissions.role.name === "SuperAdmin" && (
        <DeleteWithConfirmButton confirmContent="ra.confirm.delete" />
      )}
    </ListGuesser>
  );
};
