import uiState, { toggleHelp } from "stores/ui";
import { useSnapshot } from "valtio";
import { useTranslate } from "ra-core";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import MenuBookSharpIcon from "@mui/icons-material/MenuBookSharp";
import useKeyPress from "shared/hooks/useKeyPress";

import { useLocaleState } from "ra-core";
import { MANUALS_ENTRYPOINT } from "config/api";
import { useQuery } from "react-query";
import { scrapAvalaibleManuals } from "lib/client/manuals";
import { dicomOptions } from "lib/client/dicomweb";
import useViewerParams from "shared/hooks/useViewerParams";
import useStudyMetadata from "shared/hooks/useStudyMetadata";
import { AnchorHTMLAttributes } from "react";
import useVersions from "shared/hooks/useVersions";

function ViewerHelp() {
  const ui = useSnapshot(uiState);
  const t = useTranslate();
  useKeyPress("?", toggleHelp);

  return (
    <Dialog open={ui.help} onClose={toggleHelp}>
      <DialogTitle className="flex justify-between">
        <span>{t("help.title")}</span>
        <QyscoreManualLink className="border rounded py-1 px-3 bg-slate-800" />
      </DialogTitle>
      <DialogContent>
        <dl>
          <ShortCut desc={t("help.shortcut.crosshair.desc")}>
            {t("help.shortcut.crosshair.value")}
          </ShortCut>
          <ShortCut desc={t("help.shortcut.expand_view.desc")}>
            {t("help.shortcut.expand_view.value")}
          </ShortCut>
          <ShortCut desc={t("help.shortcut.window_intensity.desc")}>
            {t("help.shortcut.window_intensity.value")}
          </ShortCut>
          <ShortCut desc={t("help.shortcut.zoom.desc")}>
            <kbd className="px-2 py-1.5 bg-gray-600 rounded text-gray-100 border border-gray-100">
              Ctrl
            </kbd>{" "}
            {t("help.shortcut.zoom.value")}
          </ShortCut>
          <ShortCut desc={t("help.shortcut.slice_scroll.desc")}>
            {t("help.shortcut.slice_scroll.value")}
          </ShortCut>
          <ShortCut desc={t("help.shortcut.pan.desc")}>
            {t("help.shortcut.pan.value")}
          </ShortCut>
        </dl>
      </DialogContent>
    </Dialog>
  );
}

function ShortCut({
  desc,
  children,
}: {
  desc: string;
  children: React.ReactNode;
}) {
  return (
    <div className="grid grid-cols-2 gap-10 py-4 border-b border-b-neutral-600 last:border-transparent">
      <dt className="text-neutral-300"> {desc} </dt>
      <dd className="text-sm"> {children} </dd>
    </div>
  );
}

export function QyscoreManualLink(
  props: AnchorHTMLAttributes<HTMLAnchorElement>
) {
  const [currentLocal = "en"] = useLocaleState();
  const t = useTranslate();
  const { study_id } = useViewerParams();
  let { version } = useStudyMetadata(study_id);
  const { data: versions } = useVersions(true);
  const { data: availableManuals, isLoading } = useQuery(
    ["manuals"],
    scrapAvalaibleManuals,
    dicomOptions
  );

  if (isLoading) return null;

  if (!version && versions.length) {
    version = versions[0].version;
  }

  let [major, minor, patch] = version?.split(".") || [];

  if (!minor || !patch) {
    // ? Default to 1.11 ?
    major = "1";
    minor = "11";
  }

  const bestMatchedManual = availableManuals
    // Match the major and minor version and the language
    ?.filter(
      (m) =>
        m[1] === major &&
        m[2] === minor &&
        m[5]?.startsWith(currentLocal.toLocaleUpperCase())
    )
    // Sort by higher patch version and the higher revision
    ?.sort(
      (a, b) => Number(b[3]) - Number(a[3]) + (Number(b[4]) - Number(a[4]))
    )
    // Take the whole match
    ?.map((m) => m[0]) // Format the href from html
    ?.[0]?.replace('href="', "")
    ?.replace('"', "");

  let manualURL = null;

  if (bestMatchedManual) {
    manualURL = `${MANUALS_ENTRYPOINT}/${bestMatchedManual}`;
  } else {
    const manual = availableManuals
      ?.slice()
      ?.reverse()
      ?.filter((m) =>
        m[5]?.startsWith(currentLocal.toLocaleUpperCase())
      )?.[0]?.[0]
      ?.replace('href="', "")
      ?.replace('"', "");
    manualURL = `${MANUALS_ENTRYPOINT}/${manual}`;
  }

  if (!manualURL) return null;

  return (
    <a {...props} href={manualURL} target="_blank" rel="noreferrer">
      <MenuBookSharpIcon className="mr-4 text-white" />
      {t(`pos.menu.info.manual`)}
    </a>
  );
}

export default ViewerHelp;
