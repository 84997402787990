import { useRef } from 'react';
import Viewer from 'features/patients/viewer/components/Viewer';
import { useResizeDetector } from 'react-resize-detector';
import viewerState from 'stores/viewer';
import { useSnapshot } from 'valtio';
import { useAuthenticated } from 'react-admin'
import { Outlet } from 'react-router';
import uiState from 'stores/ui';
import { getRenderingEngine } from '@cornerstonejs/core';
import getIds from 'lib/cornerstone/helpers/getIds';

function onResize() {
  const { renderingEngineId } = getIds()
  const renderingEngine = getRenderingEngine(renderingEngineId)
  renderingEngine?.resize(true, true)
}

export const PatientShow = () => {
  useAuthenticated()
  const resizableRef = useRef<HTMLDivElement>(null)
  useResizeDetector({ targetRef: resizableRef, onResize })
  const snap = useSnapshot(viewerState)
  const ui = useSnapshot(uiState)

  return (
    <div ref={resizableRef} className="mt-6 grid lg:grid-cols-2 gap-2 h-[90vh] grid-cols-1">
      <Viewer id="main" instanceMetadata={snap.instanceMetadata} />
      <div className={`lg:overflow-y-auto ${ui.expandedPane ? "hidden" : ""}`}>
        <Outlet />
      </div>
    </div>
  )
}