import { Create, SimpleForm, PasswordInput } from "react-admin";
import { DicomNodeForm } from "./DicomNodeForm";
import { InputGuesser } from "@api-platform/admin";

export const DicomNodeCreate = () => (
  <Create redirect="list">
    <SimpleForm mode="onBlur" reValidateMode="onBlur">
      <InputGuesser source="username" isRequired />
      <PasswordInput
        source="clientPassword"
      />
      <PasswordInput
        source="serverPassword"
        isRequired
      />
      <DicomNodeForm />
    </SimpleForm>
  </Create>
);
