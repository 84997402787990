import VisitorIcon from '@mui/icons-material/People';
import { UserCreate } from "./components/UserCreate";
import { UserList } from "./components/UserList";
import { UserEdit } from "./components/UserEdit";

const resource = {
    create:UserCreate,
    list: UserList,
    edit: UserEdit,
    icon: VisitorIcon,
  };


export default resource;