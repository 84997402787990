import { OrganizationForm } from "./OrganizationForm";
import { Create, SimpleForm } from "react-admin";

export const OrganizationCreate = () => {
return (
  <Create redirect="list">
    <SimpleForm mode="onBlur" reValidateMode="onBlur">
      <OrganizationForm />
    </SimpleForm>
  </Create>
);
}
