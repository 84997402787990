import openApiDataProvider from "./data/openapiprovider";
import fhirDataProvider from "./data/fhirprovider";

class CompositeDataProvider {
  dataProviders: Array<any>;

  constructor(dataProviders) {
    this.dataProviders = dataProviders;
  }

  _delegate(name, resource, params) {
    let result = null;
    if (resource && typeof resource == "string") {
      result = this.dataProviders.find((dp) => {
        return dp.resources.find((supportedResource) => {
          return resource.startsWith(supportedResource);
        });
      });
    }
    if (!result) {
      result = this.dataProviders.find((dp) => dp.resources.includes("*"));
    }
    const dataProvider = result.dataProvider;
    return dataProvider[name](resource, params);
  }

  getList(resource, params) {
    return this._delegate("getList", resource, params);
  }
  getOne(resource, params) {
    return this._delegate("getOne", resource, params);
  }
  getMany(resource, params) {
    return this._delegate("getMany", resource, params);
  }
  getManyReference(resource, params) {
    return this._delegate("getManyReference", resource, params);
  }
  create(resource, params) {
    return this._delegate("create", resource, params);
  }
  update(resource, params) {
    return this._delegate("update", resource, params);
  }
  updateMany(resource, params) {
    return this._delegate("updateMany", resource, params);
  }
  delete(resource, params) {
    return this._delegate("delete", resource, params);
  }
  deleteMany(resource, params) {
    return this._delegate("deleteMany", resource, params);
  }
  introspect(resource, params) {
    return this._delegate("introspect", resource, params);
  }
  subscribe(resource, params) {
    return this._delegate("subscribe", resource, params);
  }
  unsubscribe(resource, params) {
    return this._delegate("unsubscribe", resource, params);
  }
}

const dataProvider = new CompositeDataProvider([
  {
    dataProvider: fhirDataProvider,
    resources: [
      "devices",
      "diagnosticreports",
      "encounters",
      "imagingselections",
      "imagingstudies",
      "groups",
      "observations",
      "patients",
      "tasks",
      "clinicaltrials",
      "appointments",
      "researchsubjects",
      "locations",
    ],
  },
  {
    dataProvider: openApiDataProvider,
    resources: ["*"],
  },
]);

export default dataProvider;
