import { TextInput, SimpleForm, email, required } from "react-admin";
import { Button, useNotify, useTranslate } from "react-admin";
import { useFormContext } from "react-hook-form";
import { useState } from "react";
import { ENTRYPOINT } from "config/api";
import { Grid, Typography } from "@mui/material";

const ForgotPasswordButton = (props: any) => {
  const t = useTranslate();
  const {formState, watch} = useFormContext();
  const notify = useNotify();
  const email = watch("email");

  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    setLoading(true);
    fetch(ENTRYPOINT + "/auth/forgot-password", {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/json",
        accept: "application/json",
      }),
      body: JSON.stringify({
        email: email,
      }),
    })
      .then(() => {
        notify("Reset password email has been sent. Check your email inbox !");
      })
      .catch((e) => {
        notify("Reset password email could not have been sent", { type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return <Button size="large" label={t('ra.action.confirm')} onClick={handleClick} disabled={loading || !formState.isValid} />;
};

export const ForgotPassword = () => {
  const t = useTranslate();
  return (
    <Grid container justifyContent="center" className="h-[100vh] pt-5">
      <SimpleForm
        mode="onBlur"
        sx={{ width: 300, display: "center" }}
        toolbar={<ForgotPasswordButton />}
      >
        <Typography variant="h5" align="center" className="title">
          {t('ra.auth.forgot_password')}
        </Typography>
        <TextInput
          autoFocus
          source="email"
          label={t('ra.auth.email')}
          validate={[required(), email()] }
        />
      </SimpleForm>
    </Grid>
  );
};
