import {
  DeleteWithConfirmButton,
  Edit,
  SaveButton,
  SimpleForm,
  Toolbar,
  useRecordContext,
} from "react-admin";
import { InputGuesser } from "@api-platform/admin";
import { DicomNodeForm } from "./DicomNodeForm";

const EditToolbar = () => {
  const record = useRecordContext();

  return (
    <Toolbar>
      <SaveButton />
      <DeleteWithConfirmButton
        confirmContent='ra.confirm.delete'
        translateOptions={{ name: record.name }}
      />
    </Toolbar>
  );
};

export const DicomNodeEdit = () => (
  <Edit redirect="list">
    <SimpleForm toolbar={<EditToolbar />} mode="onBlur" reValidateMode="onBlur">
      <InputGuesser source="id" disabled />
      <InputGuesser source="username" isRequired />
      <InputGuesser source="clientPassword"/>
      <DicomNodeForm />
    </SimpleForm>
  </Edit>
);
