import AvTimerIcon from '@mui/icons-material/AvTimer';
import AnalysisList from './components/AnalysisList';

const resource = {
  icon: AvTimerIcon,
  list: AnalysisList
}


export default resource

