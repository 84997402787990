import resource from "features/users";
import { fhirClient } from "../client/fhir";

let conformance = null;

const getConformanceStatement = async () => {
  if (conformance !== null) {
    return conformance;
  }
  conformance = await fhirClient.request("metadata");
  return conformance;
};

const getResourceFHIRName = async (resource) => {
  if (resource === "clinicaltrials") {
    resource = "researchstudies";
  }
  const conf = await getConformanceStatement();
  const singularResourceName = resource
    .replace(/ies$/gi, "y")
    .replace(/s$/gi, "");
  const resourceName = conf.rest[0].resource.reduce((accumulator, value) => {
    // Check if the current resource's type is equal to the desired singularResourceName
    if (value.type.toLowerCase() === singularResourceName) {
      return value.type;
    }

    // Check if the accumulator is not null or 0, return accumulator
    if (accumulator !== null && accumulator !== 0) {
      return accumulator;
    }

    // Return the current resource type if no match is found yet
    return value.type;
  }, null);
  if (!resourceName) {
    throw new Error("Resource not supported " + resource);
  }
  return resourceName;
};

const replaceFHIRReference = (obj, replacement) => {
  Object.keys(obj).forEach((key) => {
    if (
      typeof obj[key] === "object" &&
      obj[key]?.reference &&
      replacement[obj[key].reference]
    ) {
      obj[key] = replacement[obj[key].reference];
    } else if (typeof obj[key] === "object") {
      replaceFHIRReference(obj[key], replacement);
    }
  });
};

const mapEntriesToFHIRResources = async (entries) => {
  let resources = [];
  let included = {};
  entries.forEach((entry) => {
    if (entry?.search?.mode === "include") {
      included[entry.resource.resourceType + "/" + entry.resource.id] =
        entry.resource;
    } else {
      resources.push(entry?.resource);
    }
  });
  if (resources.length > 0) {
    switch (resources[0].resourceType) {
      case "Patient":
        const ids = resources.map((resource) => resource.id);
        const tasks = await dataProvider.getList("tasks", {
          filter: { subject: ids },
          pagination: { page: 1, perPage: 100 },
        });
        tasks.data.forEach((task) => {
          const patientId = task.for.reference.split("/", 2)[1];
          resources.forEach((resource) => {
            if (resource.id === patientId) {
              if (!resource.extension) {
                resource.extension = [];
              }
              resource.extension.push({
                url: "/fhir/Task/" + task.id,
                value: task,
              });
            }
          });
        });

        const patientIdsString = [ids].join(",");
        const imagingStudies = await dataProvider.getList("imagingstudies", {
          filter: {
            subject: patientIdsString,
            _include: "ImagingStudy:performer",
          },
          sort: { field: "resource.started", order: "DESC" },
          pagination: { page: 1, perPage: 100 },
        });
        imagingStudies.data.forEach((imagingStudy) => {
          const patientId = imagingStudy.subject.reference.split("/", 2)[1];
          resources.forEach((resource) => {
            if (resource.id === patientId) {
              if (!resource.extension) {
                resource.extension = [];
              }
              resource.extension.push({
                url: "/fhir/ImagingStudy/" + imagingStudy.id,
                value: imagingStudy,
              });
            }
          });
        });
    }
  }
  if (Object.keys(included).length > 0) {
    replaceFHIRReference(resources, included);
  }
  return resources;
};

const mapParametersToFHIRResources = async (parameters) => {
  const reformattedList = parameters.map((item, index) => {
    const id = index;
    const label = item.part
      .find((part) => part.name === "label")
      ?.valueCode.replace(/'/g, ""); // Extract label and remove single quotes
    const value =
      item.part.find((part) => part.name === "result")?.valueUnsignedInt || 0; // Extract value, default to 0 if not found

    return { id, label, value };
  });
  return reformattedList;
};

const dataProvider = {
  getList: async (resource, params) => {
    //eslint-disable-next-line
    const { filter, ...otherParams } = params;
    let resourceName = await getResourceFHIRName(resource);
    if (params?.filter?.aggregation) {
      resourceName += "/$aggregate";
    }
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "Cache-Control": "no-cache",
    };
    let urlSearchParams = new URLSearchParams();
    if (params?.filter) {
      let patientIds: string[] = [];
      const dateFilters = Object.entries(params.filter).filter(([key, value]) => ["acquisitionStartDate", "acquisitionEndDate"].includes(key));
      if(dateFilters?.length){
        const encounters = await dataProvider.getList("encounters", {
          filter: { "date": dateFilters.map(([key, value] : [string, string]) => 
            (`${key === "acquisitionStartDate"? "ge": "le"}${value.replaceAll('-', '')}_000000`)) },
          pagination: { page: 1, perPage: 1000 },
        });
        const patientIdsRefenced = encounters.data.map(
          (encounter) => encounter.subject.reference.split("/", 2)[1]
        );
        patientIds = patientIds?.length
          ? patientIds.filter((id) => patientIdsRefenced.includes(id))
          : patientIdsRefenced;
      }
      for (let key in params.filter) {
        const value = params.filter[key];
        if (key === "imagingStudyDescription") {
          const imagingStudies = await dataProvider.getList("imagingstudies", {
            filter: { "description:match": value },
            pagination: { page: 1, perPage: 1000 },
          });
          const patientIdsRefenced = imagingStudies.data.map(
            (imagingStudy) => imagingStudy.subject.reference.split("/", 2)[1]
          );
          patientIds = patientIds?.length
            ? patientIds.filter((id) => patientIdsRefenced.includes(id))
            : patientIdsRefenced;
        } else if (key === "groups") {
          const group = await dataProvider.getOne("groups", { id: value });
          const patientIdsRefenced = group?.data?.member?.map(
            ({ entity }) => entity.reference.split("/", 2)[1]
          );
          patientIds = patientIds?.length
            ? patientIds.filter((id) => patientIdsRefenced.includes(id))
            : patientIdsRefenced;
          patientIds = patientIds?.length ? patientIds : ["0"];
        } else if (typeof value === "object") {
          value?.forEach((item) => {
            urlSearchParams.append(key, item);
          });
        } else if (value && value !== "") {
          urlSearchParams.append(key, value);
        }
      }
      if (patientIds?.length) {
        let filterKey = null;
        switch (resourceName) {
          case "Patient":
            filterKey = "_id";
            break;
          case "Encounter":
          case "ImagingStudy":
          case "Observation":
          case "Task":
            filterKey = "subject";
            break;
        }
        if (filterKey) {
          urlSearchParams.append(filterKey, patientIds.join(","));
        }
      }
    }

    if (params.filter["_format"] && params.filter["_format"] === "csv") {
      const result = await fhirClient.request({
        url: resourceName + "?" + urlSearchParams,
        headers,
      });
      return { data: [{ id: 1, data: result }], total: 1 };
    }
    if (params?.pagination) {
      const offset =
        Math.max(params.pagination.page - 1, 0) * params.pagination.perPage;
      urlSearchParams.append("_getpagesoffset", offset.toString());
      urlSearchParams.append("_count", params.pagination.perPage);
    }
    if (params?.sort?.field) {
      urlSearchParams.append(
        "_sort",
        (params?.sort?.order === "DESC" ? "-" : "") +
          params.sort.field.replace("resource.", "")
      );
    } else {
      urlSearchParams.append("_sort", "-id");
    }

    const result = await fhirClient.request({
      url: resourceName + "?" + urlSearchParams,
      headers,
    });
    let finalResult = [];
    if (result.resourceType === "Parameters") {
      finalResult = await mapParametersToFHIRResources(result.parameter);
    } else {
      finalResult = await mapEntriesToFHIRResources(result.entry);
    }
    return {
      data: finalResult,
      total: result.total,
    };
  },
  getOne: async (resource, params) => {
    const resourceName = await getResourceFHIRName(resource);
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };

    const res = await fhirClient.request({
      url: `${resourceName}/${params.id}`,
      headers,
    });

    return { data: res };
  }, // get a single record by id
  getMany: (resource, params) => {
    throw new Error("Not implemented");
  }, // get a list of records based on an array of ids
  getManyReference: (resource, params) => {
    throw new Error("Not implemented");
  }, // get the records referenced to another record, e.g. comments for a post
  create: (resource, params) => {
    throw new Error("Not implemented");
  }, // create a record
  update: async (resource, params) => {
    const resourceName = await getResourceFHIRName(resource);
    if (resourceName !== "Patient") {
      throw new Error("Not implemented");
    }
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    return {
      data: await fhirClient.update(params.data, {
        url: `${resourceName}/${params.id}`,
        headers,
        method: "PUT",
      }),
    };
  }, // update a record based on a patch
  updateMany: (resource, params) => {
    throw new Error("Not implemented");
  }, // update a list of records based on an array of ids and a common patch
  delete: async (resource, params) => {
    const resourceName = await getResourceFHIRName(resource);
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    return await fhirClient.delete(`${resourceName}/${params.id}`, { headers });
  }, // delete a record by id
  deleteMany: async (resource, params) => {
    const resourceName = await getResourceFHIRName(resource);
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    return await fhirClient.delete(`${resourceName}/${params.ids.join()}`, {
      headers,
    });
  }, // delete a list of records based on an array of ids
};

export default dataProvider;
