// https://github.com/cornerstonejs/cornerstone3D-beta/blob/main/utils/demo/helpers/initDemo.js

import initProviders from './providers/initProviders';
import initCornerstoneWADOImageLoader from './loaders/initWADOLoader';
import initVolumeLoader from './loaders/initVolumeLoader';
import { init as csRenderInit } from '@cornerstonejs/core';
import { init as csToolsInit, state } from '@cornerstonejs/tools';
import {
  StackScrollMouseWheelTool,
  addTool,
  CrosshairsTool,
  SegmentationDisplayTool,
  WindowLevelTool,
  PanTool, ZoomTool,
  BrushTool
} from "@cornerstonejs/tools";

export default async function initCornerstone() {
  initProviders();
  initCornerstoneWADOImageLoader();
  initVolumeLoader();
  csToolsInit();
  await csRenderInit();

  // Add Tools globally to cornerstone
  if (state.tools[StackScrollMouseWheelTool.name] === undefined) {
    addTool(StackScrollMouseWheelTool);
    addTool(CrosshairsTool);
    addTool(SegmentationDisplayTool);
    addTool(WindowLevelTool)
    addTool(PanTool)
    addTool(ZoomTool)
    addTool(BrushTool)
  }
  
}