import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  LinearProgress,
  MenuItem,
  Select
} from '@mui/material'
import {useGetList, useTranslate} from 'ra-core'

import {useDropzone} from 'react-dropzone'
import {HTTPError} from 'shared/utils/error'
import { ENTRYPOINT } from "config/api";
import { useSnapshot } from 'valtio'
import uiState, { toggleUpload } from 'stores/ui'

export const CHUNK_SIZE = 1024 * 1024 * 10 // 10 mb
export const UPLOAD_IN_PARALLEL = 2
export const uploadsQyscoreUrl = '/uploads'

interface FileInfos {
 [filename : string] : {
    step: number,
    stepAmount: number,
    size: number,
    groupId : number
    error?: string
 }
}

function UploadAnalyses() {
  const ui = useSnapshot(uiState)
  const t = useTranslate()
  const  {data: userGroups} = useGetList("analyzis_groups", undefined, {enabled: ui.upload});
  const [groupId, setGroupId] = useState('')

  const [fileInfos, setFileInfos] = useState<FileInfos>({})

  const onDrop = useCallback(
    async acceptedFiles => {
      acceptedFiles.forEach(file =>
        setFileInfos(fI => ({
          ...fI,
          [file.name]: {
            step: 0,
            stepAmount: 10 + 1,
            size: Math.floor(file.size / 1000000),
            groupId,
          },
        })),
      )
      for (const file of acceptedFiles) {
        try {
          await uploadFile(file, Number(groupId), setFileInfos)
        } catch (e) {
          if (e instanceof HTTPError) {
            const errorType = e.status >= 500 ? 'Server error' 
              : e.status >= 400 ? 'Client error' 
              : 'Unknown error'
            setFileInfos(fI => ({
              ...fI,
              [file.name]: {
                ...fI[file.name],
                error: `Failed to send analysis : ${errorType} ${e.statusText} (${e.status})`,
              },
            }))
          } else {
            let errorLabel = 'Failed to send analysis : Unknown error'
            if (e instanceof TypeError) {
              errorLabel += ' (possibly low connection)'
            }
            setFileInfos(fI => ({
              ...fI,
              [file.name]: {...fI[file.name], error: errorLabel},
            }))
          }
        }
      }
    },
    [groupId],
  )
  const {getRootProps, getInputProps, open: openFileSelector} = useDropzone({
    onDrop,
    multiple: true,
    accept: ['application/zip', 'application/x-zip-compressed'],
    useFsAccessApi: false,
  })

  useEffect(() => {
    if (Object.values(fileInfos).some(info => info?.step !== info?.stepAmount && !info?.error)) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = null
    }
    return () => (window.onbeforeunload = null)
  }, [fileInfos, ui.upload])


  if (!userGroups?.length) {
    return (
      <Dialog open={ui.upload} onClose={toggleUpload}>
        <DialogTitle>{t("resources.analyses.upload.title")}</DialogTitle>
        <DialogContent>
          <p>{t("resources.analyses.upload.no_group")}</p>
          <p>
            {t("resources.analyses.upload.contact")}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:support@qynapse.com?subject=No group assigned for upload">
              support@qynapse.com
            </a>
          </p>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog open={ui.upload} onClose={toggleUpload} maxWidth="lg" aria-labelledby="dialog-title">
      <DialogTitle> {t("resources.analyses.upload.title")} </DialogTitle>
      <DialogContent style={{textAlign: 'center'}}>
        <p  className='text-zinc-400'>
          {t("resources.analyses.upload.subtext")}
        </p>
        <FormControl style={{marginBottom: '2em'}}>
          <span className='p-2'>{t("resources.analyses.upload.group_select")}</span>
          <Select name="group" value={groupId} onChange={e => setGroupId(e.target.value)} variant="outlined">
            {userGroups?.map(({id, name}) => (
              <MenuItem key={id} value={id} style={{width: '100%'}}>
                {name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText> {t("resources.analyzis_groups.name", {smart_count: 1})} </FormHelperText>
        </FormControl>
        {groupId && (
          <table className='w-full border-collapse'>
            {Object.keys(fileInfos).length ? (
              <thead>
                <tr>
                  <th className='text-white py-1 px-4'> {t('resources.analyses.upload.drag.filename')} </th>
                  <th className='text-white py-1 px-4'>{t('resources.analyses.upload.drag.size')} </th>
                  <th className='text-white py-1 px-4'> {t("resources.analyzis_groups.name", {smart_count: 1})}</th>
                  <th className='text-white py-1 px-4'> {t("resources.analyzes.fields.status")}</th>
                </tr>
              </thead>
            ) : null}
            <tbody className="border-2 rounded-sm text-slate-200 outline-none hover:border-slate-500 hover:border-dashed hover:transition" {...getRootProps()} onClick={openFileSelector}>
              <input {...getInputProps()} />
              {Object.entries(fileInfos).map(([filename, info]) => (
                <tr key={filename}>
                  <td className='p-4 border-b'>{filename} </td>
                  <td className='p-4 border-b'>{`${info.size} MB`} </td>
                  <td className='p-4 border-b'> {userGroups.find(({id}) => id === info?.groupId)?.name}</td>
                  <td className='p-4 border-b'>{info.error ? <span>{info.error}</span> : <Progress {...info} />}</td>
                </tr>
              ))}
              <tr>
                <td className="p-4" colSpan={4}>
                  {t("resources.analyses.upload.drag.text")}
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleUpload} variant="contained" color="secondary">
          {t("ra.modal.close")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UploadAnalyses

function Progress({step, stepAmount}) {
  const t = useTranslate()
  const progress = Math.floor((step / stepAmount) * 100)
  if (step === stepAmount) {
    return <span> {t("resources.analyses.upload.drag.file_sent")} </span>
  }

  return (
    <div style={{display: 'flex', paddingRight: '1em', minWidth: '15em'}}>
      <LinearProgress
        color="primary"
        variant={progress != null ? 'determinate' : 'indeterminate'}
        style={{height: 10, borderRadius: 4, marginTop: 6, width: '100%'}}
        value={progress}
      />
      <span style={{marginLeft: '-60%', zIndex: '1'}}>{progress}%</span>
    </div>
  )
}

async function uploadFile(file : File, groupId: number, setFileInfos: React.Dispatch<React.SetStateAction<FileInfos>>) {
  const chunkQuantity = Math.ceil(file.size / CHUNK_SIZE)
  setFileInfos(fI => ({
    ...fI,
    [file.name]: {
      step: 0,
      stepAmount: chunkQuantity + 1,
      size: Math.floor(file.size / 1000000),
      groupId,
    },
  }))
  const uploadInitUrl = `${ENTRYPOINT}${uploadsQyscoreUrl}/${groupId}/init`
  const initResponse = await fetch(uploadInitUrl, {
    body: null,
    method: 'POST',
    headers: new Headers({
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'x-content-name': file.name,
      'x-chunks-quantity': String(chunkQuantity),
    }),
  })

  if (!initResponse.ok) {
    throw new HTTPError(initResponse.status)
  }

  const {fileId} = await initResponse.json()
  setFileInfos(fI => ({
    ...fI,
    [file.name]: {
      ...fI[file.name],
      step: 1,
    },
  }))

  const chunks : Blob[] = []
  for (let i = 0; i < file.size; i = i + CHUNK_SIZE) {
    const chunk = file.slice(i, i + CHUNK_SIZE)
    chunks.push(chunk)
  }

  const uploadUrl = `${ENTRYPOINT}${uploadsQyscoreUrl}/${groupId}`

  let batchNb = Math.ceil(chunks.length / UPLOAD_IN_PARALLEL)
  for (let i = 0; i < batchNb; i++) {
    const toSend = chunks.slice(i * UPLOAD_IN_PARALLEL, (i + 1) * UPLOAD_IN_PARALLEL)
    const promises = toSend.map((c, index) =>
      uploadChunk(
        c,
        i * UPLOAD_IN_PARALLEL + index,
        uploadUrl,
        fileId,
        file.name,
        chunkQuantity,
        setFileInfos,
      ),
    )
    await Promise.all(promises)
  }
}

const uploadChunk = async (
  chunk: Blob,
  chunkID: number,
  uploadUrl: string,
  fileId: string,
  filename: string,
  chunkQuantity: number,
  setFileInfos: React.Dispatch<React.SetStateAction<FileInfos>>
) =>
  await fetch(uploadUrl, {
    body: chunk,
    method: 'POST',
    headers: new Headers({
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/octet-stream',
      'x-content-name': filename,
      'x-content-id': fileId,
      'x-chunks-quantity': String(chunkQuantity),
      'x-chunk-id': String(chunkID),
      'x-chunk-number': String(chunkID + 1),
    }),
  }).then(res => {
    if (res.ok) {
      setFileInfos(fI => ({
        ...fI,
        [filename]: { ...fI[filename], step: fI[filename].step + 1 },
      }))
    } else {
      throw new HTTPError(res.status)
    }
  })

