import { SelectInput, ReferenceInput } from "react-admin";
import { CredentialForm } from "../utils/CredentialForm";

const typeChoices = [
  { name: "Azure", value: "azure" },
  { name: "Google", value: "google" },
  { name: "LDAP", value: "ldap" },
];

export const AuthenticationMethodForm = () => {
  return (
    <>
      <ReferenceInput
        source="organization.id"
        reference="organizations"
      >
        <SelectInput
          optionText="name"
          source="organization"
          resettable
          isRequired
        />
      </ReferenceInput>
      <SelectInput
        source="name"
        choices={typeChoices}
        optionText="name"
        optionValue="value"
        isRequired
      />
      <CredentialForm />
    </>
  );
};
