import dcmjs from "dcmjs";
import { useParams } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { useSnapshot } from "valtio";

import viewerState, { Segment } from "stores/viewer";
import {
  calculatePercentiles,
  calculateZscore,
  fetchRegressionModels,
  getAgeFromMetadata,
  getPercentileRange,
  RegressionModel,
} from "lib/client/normative";
import { Marker } from "features/patients/quantification/components/Marker";
import { getAllDescendant, getSegmentRID, markers } from "shared/utils/recursive";
import useStudyMetadata from "./useStudyMetadata";
import { dicomOptions } from "lib/client/dicomweb";

function useMarkers(studyUID?: string) {
  const { segmentsPerSeries } = useSnapshot(viewerState);

  const { study_id } = useParams();
  const studyData = useStudyMetadata(studyUID || study_id);

  const { sex, naturalizedDatasets, isLoading: isStudyLoading } = studyData;

  const { data: regressionModels, isLoading: isRegressionModelLoading } =
    useQuery(
      ["regression", "all", sex] as const,
      ({ queryKey }) => fetchRegressionModels(queryKey[2]),
      { enabled: !!sex, ...dicomOptions }
    );

  const populatedMarkers = useMemo(
    () =>
      populate(
        naturalizedDatasets,
        segmentsPerSeries as Segment[],
        regressionModels
      ),
    [naturalizedDatasets, segmentsPerSeries, regressionModels]
  );

  const displaySegment = useCallback(
    ({ seriesUID, segments }: Pick<Marker, "seriesUID" | "segments">, onlySelect : boolean= false) => {
      if (viewerState.instanceMetadata?.SeriesInstanceUID !== seriesUID) {
        viewerState.instanceMetadata = naturalizedDatasets.find(
          (instance) => instance.SeriesInstanceUID === seriesUID
        );
      }

      const isSelected = viewerState.segmentsPerSeries
        .filter((seg) =>
          segments.map((s) => s.SegmentLabel).includes(seg.SegmentLabel)
        )
        .some((segment) => segment.selected);
      viewerState.segmentsPerSeries = viewerState.segmentsPerSeries.map(
        (segment) => {
          if (
            segments.find((s) => segment.SegmentNumber === s.SegmentNumber) &&
            segment.seriesUID === seriesUID
          ) {
            segment.selected = onlySelect ? true : !isSelected;
          }

          return segment;
        }
      );
    },
    [naturalizedDatasets]
  );
  return {
    ...studyData,
    isStudyLoading,
    isRegressionModelLoading,
    displaySegment,
    naturalizedDatasets,
    populatedMarkers,
    regressionModels,
  };
}

function populate(
  naturalizedDatasets: dcmjs.data.NaturalizedDataset[],
  segmentsPerSeries: Segment[],
  regressionModels: Record<string, RegressionModel>,
): Marker[] {
  const quantitativeData: [string, number, string][] = parseQuantitativeDataFromSR(naturalizedDatasets);

  const age = getAgeFromMetadata(naturalizedDatasets?.[0]);

  function getQuantitativeAndNormativeData(code: string) {
    // Match quantitative data from SR
    const quant = quantitativeData?.filter((m) => m[0] === code);
    const icv = quant?.find((m) => m[2] === "%")?.[1];
    const mL = quant?.find((m) => m[2] === "cm3")?.[1];
    const regressionModel = regressionModels?.[code];
    const hasNorm = Boolean(regressionModel);
    const hasQuantif = Boolean(quant?.length);
    // Calculate Normative data
    if (age && regressionModel && icv) {
      const zscore = calculateZscore(regressionModel, age, icv);
      const percentiles = calculatePercentiles(regressionModel, age);
      const percentileRange = getPercentileRange(percentiles, icv);

      return { icv, mL, zscore, percentileRange, hasNorm, hasQuantif };
    }

    return { icv, mL, hasNorm, hasQuantif };
  }
  // Add Markers for segments in the common hierarchy
  const seriesUIDs = Array.from(new Set(segmentsPerSeries.map(segment => segment.seriesUID)))
  const populatedMarkers = seriesUIDs.flatMap(seriesUID => {
    const segments = segmentsPerSeries.filter(seg => seg.seriesUID === seriesUID)
    return getHierarchyMarkers(getQuantitativeAndNormativeData, segments)
  });

  // Add Markers for segments not in the common hierarchy but that still has a segment
  const unknownSegMarkers: Marker[] = segmentsPerSeries
    .filter(seg => !markers.some((marker) => getSegmentRID(seg as Segment) === marker.code))
    .map((seg) => {
      const code = getSegmentRID(seg)

      const q = getQuantitativeAndNormativeData(code);
      const marker: Marker = {
        code,
        ...q,
        segments: [seg],
        seriesUID: seg?.seriesUID,
        isUnknown: true,
      };

      return marker;
    });
  populatedMarkers.push(...unknownSegMarkers);

  // Add Markers for coordinates found in the SR
  const coordinates = parseCoordsFromSR(naturalizedDatasets)
  if (coordinates?.length) {
    const coordMarkers: Marker[] = coordinates.flatMap(({ related, uid, coord, rids }) => {
      return populatedMarkers.filter(m => m.code === related)?.map(relatedMarker => {
        // TODO Need data in SR to link to corresponding Marker
        const code = `${related}:${uid}:${rids.join(":")}`;
        const coordMarker: Marker = { code, coord, hasNorm: false };

        if (relatedMarker) {
          relatedMarker["substructures"] = [...(relatedMarker.substructures || []), code]
          relatedMarker["hasCoords"] = true
          coordMarker["segments"] = relatedMarker["segments"]
          coordMarker["seriesUID"] = relatedMarker["seriesUID"]
        }
        return coordMarker
      })
    })
    populatedMarkers.push(...coordMarkers)
  }

  return populatedMarkers;
}

function getHierarchyMarkers(
  getQuantitativeAndNormativeData: Function,
  segmentsPerSeries: Segment[]) {
  return markers
    .map((marker) => {
      const q = getQuantitativeAndNormativeData(marker.code);

      // Match segment data from SEG
      const segment = segmentsPerSeries.find((seg) => getSegmentRID(seg) === marker.code);
      const hasSegments = Boolean(segment);

      return {
        ...marker,
        ...q,
        seriesUID: segment?.seriesUID,
        segments: [segment],
        hasSegments,
      };
    })
    // Go through a second time to populate parent marker with their substructures data
    .map((marker: Marker, _, pop1Markers) => {
      if (!marker.substructures) {
        return marker;
      }

      const subs: Marker[] = getAllDescendant(marker.code).map((rid) => pop1Markers.find((m) => m.code === rid)
      );
      const subSegments = subs.flatMap((m) => m.segments).filter((s) => s);
      const hasSegments = !!subSegments.length;
      const hasQuantif = subs.some((m) => m.hasQuantif) || marker.hasQuantif;
      const hasNorm = subs.some((m) => m.hasNorm) || marker.hasNorm;

      return {
        ...marker,
        seriesUID: subSegments[0]?.seriesUID,
        segments: subSegments,
        hasQuantif,
        hasSegments,
        hasNorm,
      };
    }).filter(marker => marker.seriesUID);
}

function getImageMeasurements(naturalizedDatasets: dcmjs.data.NaturalizedDataset[]) {
  return naturalizedDatasets
    ?.find((v) => v.Modality === "SR" && v.Manufacturer === "Qynapse")
    ?.ContentSequence?.find(
      (content) => content.ConceptNameCodeSequence?.[0]?.CodeMeaning === "Imaging Measurements"
    );
}

export function parseQuantitativeDataFromSR(naturalizedDatasets: dcmjs.data.NaturalizedDataset[]): [string, number, string][] {
  const imagingMeasurements = getImageMeasurements(naturalizedDatasets)
  return imagingMeasurements?.ContentSequence?.flatMap((c) => {
    const codes = c.ContentSequence
      ?.map(item => item.ValueType === "CODE" ? item.ConceptCodeSequence?.[0].CodeValue : null)
      .filter(Boolean);
    const measures = c.ContentSequence?.filter(item => item.ValueType === "NUM");
    return measures.map(measure => {
      const value = measure?.MeasuredValueSequence?.[0]?.NumericValue;
      const unit = measure?.MeasuredValueSequence?.[0]?.MeasurementUnitsCodeSequence?.[0].CodeValue;
      return [codes.join(":"), value, unit] as [string, number, string];
    });
  });
}

export function parseCoordsFromSR(naturalizedDatasets: dcmjs.data.NaturalizedDataset[]) {
  const imagingMeasurements = getImageMeasurements(naturalizedDatasets)
  const info = imagingMeasurements?.ContentSequence.filter(c => c.ContentSequence.some(c1 => c1.ValueType === "SCOORD3D"))
  const coordsInfo = info?.map(i => {
    const coord = i.ContentSequence.find(c => c.ValueType === "SCOORD3D").GraphicData
    const label = i.ContentSequence.find(c => c.ValueType === "TEXT").TextValue
    const uid = i.ContentSequence.find(c => c.ValueType === "UIDREF").UID
    const rids = i.ContentSequence.filter(c => c.ValueType === "CODE").map(c => c.ConceptCodeSequence?.[0].CodeValue)
    return { coord, label, uid, related: "RID4700", rids }
  })
  return coordsInfo
}

export default useMarkers;
