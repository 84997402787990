import { ENTRYPOINT } from "../config/api";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "./i18n/en";
import frenchMessages from "./i18n/fr";

const detectBrowserLanguage = () => {
  const browserLanguage = navigator.language.split("-")[0];
  return ["en", "fr"].includes(browserLanguage) ? browserLanguage : "en";
};

// Fetch ontology data
const fetchOntology = async (locale) => {
  try {
    const response = await fetch(`${ENTRYPOINT}/ontology/${locale}`, {
      headers: {
        "Cache-Control": "max-age=604800", // refresh browser cache every week
      },
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch ontology for locale ${locale}`);
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    return {};
  }
};

// Initialize synchronously for the default language
const getDefaultMessages = (locale) => {
  let xhr = new XMLHttpRequest();
  xhr.open("GET", ENTRYPOINT + "/ontology/" + locale, false);
  xhr.setRequestHeader("Cache-Control", "max-age=604800"); // refresh browser cache every week
  xhr.send();
  const ontology = JSON.parse(xhr.responseText);
  const messages = locale === "fr" ? frenchMessages : englishMessages;
  return {
    ...messages,
    ...ontology,
  };
};

// Initial language detection
const defaultLanguage =
  localStorage.getItem("selectedLanguage") || detectBrowserLanguage();
const defaultMessages = getDefaultMessages(defaultLanguage);

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    localStorage.setItem("selectedLanguage", locale);
    if (locale === defaultLanguage) {
      return defaultMessages;
    }
    return fetchOntology(locale).then((ontology) => {
      if (locale === "fr") {
        return {
          ...frenchMessages,
          ...ontology,
        };
      }
      // Always fallback on english
      return {
        ...englishMessages,
        ...ontology,
      };
    });
  },
  defaultLanguage,
  [
    { locale: "en-GB", name: "English (United Kingdom)" },
    { locale: "en", name: "English (United States)" },
    { locale: "fr", name: "Français" },
  ]
);
export default i18nProvider;
