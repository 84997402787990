import dcmjs from "dcmjs";
import type { Color } from "@cornerstonejs/tools/dist/esm/types";
import { Marker } from "features/patients/quantification/components/Marker";
import { CSSProperties } from "react";
// @ts-ignore
const { Colors } = dcmjs.data;


// COPY FROM : https://github.com/dcmjs-org/dcmjs/blob/c209047861d2ef6c17526c6d1e29d5db7e895b29/src/adapters/VTKjs/Segmentation.js#L6
export function lab2rgba(
  lab: dcmjs.data.SegMetadata["RecommendedDisplayCIELabValue"]
): Color {
  const rgba = Colors.dicomlab2RGB(lab).map((x: number) => Math.round(x * 255));
  rgba.push(255);
  return rgba;
}


export function segmentColorsToCSS(segments: Marker["segments"]) {
  const colors = segments.map((s) =>
    lab2rgba(s.RecommendedDisplayCIELabValue).join(",")
  );
  let style: CSSProperties = {};
  if (colors.length === 1) {
    style = { color: `rgb(${colors[0]}`, stroke: `rgb(${colors[0]}` };
  } else if (new Set(colors).size === 1) {
    style = { color: `rgb(${colors[0]}`, stroke: `rgb(${colors[0]}` };
  }
  return style;
}
