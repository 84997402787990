import { vec3 } from "gl-matrix";
import { utilities } from "@cornerstonejs/core";
import { api } from "dicomweb-client";
// @ts-ignore
import cornerstoneWADOImageLoader from "@cornerstonejs/dicom-image-loader";

import WADORSHeaderProvider from "./providers/WADORSHeaderProvider";
import getPixelSpacingInformation from "./helpers/getPixelSpacingInformation";
import {
  dicomOptions,
  fetchSeriesMetadata,
  retrieveInstance,
} from "lib/client/dicomweb";
import queryClient from "lib/client/main";

import { DICOM_WEB_ENTRYPOINT } from "config/api";
import { convertMultiframeImageIds } from "./helpers/convertMultiFrameImageIds";
import safeNaturalizeDataset from "./helpers/safeNaturalizeDataset";

const { calibratedPixelSpacingMetadataProvider } = utilities || {};

function sortInstance(instances: any[]) {
  const referenceImagePositionPatient = instances[0]["00200032"].Value;

  const refIppVec = vec3.create();
  const scanAxisNormal = vec3.create();
  const ImageOrientationPatient = instances[0]["00200037"].Value;
  const rowCosineVec = vec3.fromValues(
    ImageOrientationPatient[0],
    ImageOrientationPatient[1],
    ImageOrientationPatient[2]
  );
  const colCosineVec = vec3.fromValues(
    ImageOrientationPatient[3],
    ImageOrientationPatient[4],
    ImageOrientationPatient[5]
  );

  vec3.set(
    refIppVec,
    referenceImagePositionPatient[0],
    referenceImagePositionPatient[1],
    referenceImagePositionPatient[2]
  );
  vec3.cross(scanAxisNormal, rowCosineVec, colCosineVec);

  const distanceImagePairs = instances.map((instance) => {
    const positionVector = vec3.create();

    vec3.sub(
      positionVector,
      referenceImagePositionPatient,
      instance["00200032"].Value
    );

    const distance = vec3.dot(positionVector, scanAxisNormal);

    return {
      distance,
      instance,
    };
  });

  distanceImagePairs.sort((a, b) => b.distance - a.distance);

  return distanceImagePairs.map((a) => a.instance);
}

export interface SeriesIds {
  StudyInstanceUID: string;
  SeriesInstanceUID: string;
}

export type getSEGPixelDataType = (() => Promise<ArrayBuffer>) | null;

// Inspired by https://github.com/cornerstonejs/cornerstone3D-beta/blob/main/utils/demo/helpers/createImageIdsAndCacheMetaData.js
export default async function createImageIdsAndCacheMetaData({
  StudyInstanceUID,
  SeriesInstanceUID,
}: SeriesIds): Promise<[string[], getSEGPixelDataType]> {
  const SOP_INSTANCE_UID = "00080018";
  const SERIES_INSTANCE_UID = "0020000E";
  const MODALITY = "00080060";
  const PET_SERIES_TYPE = "00541000";
  const FRAME_REFENCE_TIME = "00541300";


  let instances: api.Metadata[] = await queryClient.fetchQuery(
      ["studies", StudyInstanceUID, "series", SeriesInstanceUID, "metadata"],
      async () => fetchSeriesMetadata(StudyInstanceUID, SeriesInstanceUID),
      dicomOptions
  )

  const firstInstance = instances[0];
  const modality = firstInstance[MODALITY]?.Value?.[0] as string;
  const petSeriesType = firstInstance[PET_SERIES_TYPE]?.Value?.[0] as string[];

  if (modality === "PT" && petSeriesType.includes("DYNAMIC")) {
    const frameReferenceTime = firstInstance[FRAME_REFENCE_TIME]?.Value?.[0];
    instances = instances.filter(
      (i) => i[FRAME_REFENCE_TIME]?.Value?.[0] === frameReferenceTime
    );
  }
  if (["MR", "PT"].includes(modality)) {
    instances = sortInstance(instances);
  }

  let imageIds = instances.map((instanceMetaData) => {
    const SeriesInstanceUID = instanceMetaData[SERIES_INSTANCE_UID]?.Value?.[0];
    const SOPInstanceUID = instanceMetaData[SOP_INSTANCE_UID]?.Value?.[0];
    // const ImageOrientation = instanceMetaData['00200037'];

    // FIXME Dirty hacks to change MR ImageOrientation to a MNI coordinates (I think)
    // if (ImageOrientation) {
    //   ImageOrientation.Value = [0, 1, 0, 0, 0, -1]
    // }

    const prefix = "wadors:";

    const imageId =
      prefix +
      DICOM_WEB_ENTRYPOINT +
      "/studies/" +
      StudyInstanceUID +
      "/series/" +
      SeriesInstanceUID +
      "/instances/" +
      SOPInstanceUID +
      "/frames/1";

    cornerstoneWADOImageLoader.wadors.metaDataManager.add(
      imageId,
      instanceMetaData
    );

    WADORSHeaderProvider.addInstance(imageId, instanceMetaData);
    return imageId;
  });

  // if the image ids represent multiframe information, creates a new list with one image id per frame
  // if not multiframe data available, just returns the same list given
  imageIds = convertMultiframeImageIds(imageIds);
  if (modality !== "SEG") {
    imageIds.forEach((imageId) => {
      let instanceMetaData =
        cornerstoneWADOImageLoader.wadors.metaDataManager.get(imageId);

      if (instanceMetaData) {
        // Add calibrated pixel spacing
        const metadata = safeNaturalizeDataset(instanceMetaData);
        const pixelSpacing = getPixelSpacingInformation(metadata);

        if (pixelSpacing) {
          calibratedPixelSpacingMetadataProvider.add(
            imageId,
            pixelSpacing.map((s) => parseFloat(s))
          );
        }
      }
    });
  } else {
    const firstSEGInstance = instances[0];
    const referenceSeriesSequence = firstSEGInstance["00081115"];
    // @ts-ignore
    const referenceSeriesUID =
      referenceSeriesSequence["Value"][0]["0020000E"]["Value"][0];
    const [referenceImageIds] = await createImageIdsAndCacheMetaData({
      StudyInstanceUID,
      SeriesInstanceUID: referenceSeriesUID,
    });

    async function getSEGPixelData() {
      const sopInstanceUID = String(instances[0][SOP_INSTANCE_UID].Value[0]);
      const response = await queryClient.fetchQuery(
        [
          "instance",
          StudyInstanceUID,
          SeriesInstanceUID,
          sopInstanceUID,
        ],
        async () =>
          await retrieveInstance(
            StudyInstanceUID,
            SeriesInstanceUID,
            sopInstanceUID
          ),
        dicomOptions
      );
      return response;
    }
    return [referenceImageIds, getSEGPixelData];
  }

  return [imageIds, null];
}
