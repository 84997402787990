import jwtDecode from "jwt-decode";
import { AuthProvider, UserIdentity } from "ra-core";
import { ENTRYPOINT } from "../config/api";
import decodeJwt from "jwt-decode";
import { dicomWebClient } from "./client/dicomweb";

interface LoginFormValues {
  username: string;
  password: string;
}

const url = window.location;

const access_token = () => {
  if (localStorage.getItem("token")) {
    return localStorage.getItem("token");
  } else if (new URLSearchParams(url.search).get("token")) {
    const token = new URLSearchParams(url.search).get("token");
    if (jwtDecode(token)?.["aud"] === "fastapi-users:reset") return null;
    return token;
  }
};

if (access_token()) {
  localStorage.setItem("token", access_token());
}

const authProvider: AuthProvider = {
  login: async (loginFormValues: LoginFormValues) => {
    const { username, password } = loginFormValues;
    const request = new Request(ENTRYPOINT + "/auth/jwt/login", {
      method: "POST",
      body: new URLSearchParams({
        username,
        password,
      }),
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    });
    await fetch(request)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else if (response.status === 400) {
          return response.json().then((data) => {
            if (data.detail === "LOGIN_BAD_CREDENTIALS") {
              throw new Error("ra.auth.sign_in_error");
            }
            throw new Error(response.statusText);
          });
        } else {
          throw new Error(response.statusText);
        }
      })
      .then(({ access_token }) => {
        localStorage.setItem("token", access_token);
        dicomWebClient.headers["Authorization"] =
          "Bearer " + localStorage.getItem("token");
      });
    if (window.history.length > 1 && document.referrer)
      return Promise.resolve({ redirectTo: -1 });
    localStorage.setItem(
      "RaStore.locale",
      localStorage.getItem("selectedLanguage") || "en"
    );
    return;
  },
  logout: () => {
    localStorage.removeItem("token");
    dicomWebClient.headers["Authorization"] = "";
    return Promise.resolve("/login");
  },
  checkAuth: () => {
    if (
      window.location.pathname === "reset-password" ||
      window.location.pathname === "forgot-password"
    ) {
      return Promise.resolve();
    } else if (
      !localStorage.getItem("token") ||
      new Date().getTime() / 1000 >
        // @ts-ignore
        jwtDecode(localStorage.getItem("token"))?.exp
    ) {
      // return Promise.reject({redirectTo: '/login'});
      return Promise.resolve();
    }
    return Promise.resolve();
  },
  checkError: (err: { status: any; response: { status: any } }) => {
    if ([401, 403].includes(err?.status || err?.response?.status)) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getIdentity: () => {
    const accessToken: any = decodeJwt(localStorage.getItem("token"));
    const userIdentity = {
      id: accessToken.sub,
      role: accessToken.role,
      organization: accessToken.organization,
      groups: accessToken.groups,
    };
    return Promise.resolve(userIdentity);
  },
  getPermissions: () => {
    if (!localStorage.getItem("token")) {
      Promise.resolve("guest");
    } else {
      // @ts-ignore
      const accessToken: any = decodeJwt(localStorage.getItem("token"));
      const userPermissions = {
        role: accessToken.role,
        actions: accessToken.permissions,
      };

      return accessToken ? Promise.resolve(userPermissions) : Promise.resolve();
    }
  },
};

export default authProvider;
