// If seconds amount reachs one day the HH:MM:ss resets
export function secondsToHHMMss(duration: number) {
  if (!duration) return undefined
  const seconds: number = Math.floor(duration);
  const date = new Date(0);
  date.setSeconds(seconds);
  return date.toISOString().substring(11, 19);
}

export function YYYYMMDDtoDate(datestring: string) {
  if (!datestring) {
    return undefined
  }
  return new Date(`${datestring.substring(0, 4)}-${datestring.substring(4, 6)}-${datestring.substring(6, 8)}`)
}

export function getRemainingTime(estimatedEndDate: string) {
  const estimated = new Date(estimatedEndDate + "Z");
  const seconds = (Number(estimated) - Date.now()) / 1000;
  return seconds
}