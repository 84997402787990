import { useMemo } from 'react';
import { useQuery, useQueries } from 'react-query';
import { dicomOptions, fetchSeries, fetchSeriesMetadata, searchPatientStudies } from "lib/client/dicomweb";
import safeNaturalizeDataset from "lib/cornerstone/helpers/safeNaturalizeDataset";
import useViewerParams from './useViewerParams';

function useLongitudinal(patientId: string) {
  const { patient_id } = useViewerParams()
  const { data: datasets } = useQuery(
    ['studies', patientId, patient_id],
    ({ queryKey }) => searchPatientStudies(queryKey[1]!, queryKey[2]!),
    { ...dicomOptions, enabled: !!patientId }
  )
  const patientStudies = useMemo(() => datasets?.map(safeNaturalizeDataset)
    , [datasets])

  const patientStudiesUID = patientStudies?.map(study => study.StudyInstanceUID)

  const studiesSeriesQueries = useQueries(
    patientStudiesUID?.map(studyUID => ({
      queryKey: ['studies', studyUID, 'series', patient_id],
      queryFn: () => fetchSeries(studyUID, patient_id),
      enabled: !!studyUID,
      ...dicomOptions
    })) || []
  )
  const seriesInfo = useMemo(() => studiesSeriesQueries?.map(study => study.data?.map(safeNaturalizeDataset))
    , [studiesSeriesQueries])

  const SRSeriesInfo = seriesInfo?.map(d => d?.find(serie => serie.Modality === "SR"));
  const SRMetadatas = useQueries(
    SRSeriesInfo.map(series => ({
      queryKey: ['studies', series?.StudyInstanceUID, 'series', series?.SeriesInstanceUID, 'metadata'],
      queryFn: () => fetchSeriesMetadata(series?.StudyInstanceUID, series?.SeriesInstanceUID),
      enabled: !!SRSeriesInfo?.every(d => !!d),
      ...dicomOptions
    })) || []
  )

  const studiesInstanceMetadata = SRMetadatas?.map(d => d.data?.map(safeNaturalizeDataset))

  return { studiesInstanceMetadata, patientStudies, isDone: SRMetadatas?.every(d => d.isFetched && !d.error) }
}

export default useLongitudinal