import { proxy } from "valtio";

interface UIState {
  upload: boolean;
  help: boolean;
  about: boolean;
  expandedPane: string | null;
  loading: boolean;
  error?: Error;
}

const uiState = proxy<UIState>({
  upload: false,
  help: false,
  about: false,
  loading: false,
  expandedPane: null,
});

export function toggleUpload() {
  uiState.upload = !uiState.upload;
}

export function toggleHelp() {
  uiState.help = !uiState.help;
}

export function toggleAbout() {
  uiState.about = !uiState.about;
}

export function toggleExpandedPane(paneId: string) {
  if (uiState.expandedPane === paneId) {
    uiState.expandedPane = null;
  } else {
    uiState.expandedPane = paneId;
  }
}

export function resetError() {
  uiState.error = null;
}

export default uiState;
