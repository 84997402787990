import { Edit, SimpleForm } from "react-admin";
import { AuthenticationMethodForm } from "./AuthenticationMethodForm";

const transformCredentials = (data: any) => {
  const name = data.name;
  const organization = data.organization;
  const id = data.id;
  delete data.id;
  delete data.name;
  delete data.organization;
  delete data.credentials;
  const credentials = data;
  return {
    id: id,
    name: name,
    organization: organization,
    credentials: credentials,
  };
};

export const AuthenticationMethodEdit = () => (
  <Edit transform={transformCredentials} redirect="show">
    <SimpleForm mode="onBlur" reValidateMode="onBlur">
      <AuthenticationMethodForm />
    </SimpleForm>
  </Edit>
);
