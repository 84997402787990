import {
  AutocompleteInput,
  CreateButton,
  DeleteWithConfirmButton,
  EditButton,
  ExportButton,
  FilterButton,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  TextField,
  TopToolbar,
  usePermissions,
} from "react-admin";

import { ListGuesser, FieldGuesser } from "@api-platform/admin";
import { Organization } from "../../../shared/types/organizations";
import BulkActionButtons from "../../../shared/components/BulkActionButtons";

const ListActions = (permissions: any) => (
  <TopToolbar>
    {permissions.role.name === "SuperAdmin" && <FilterButton />}
    {permissions.role.name !== "Member" && <CreateButton />}
    <ExportButton />
  </TopToolbar>
);

const analysisGroupFilters = (permissions: any) =>
  [
    <SearchInput source="q" alwaysOn />,
    permissions.role.name === "SuperAdmin" ? (
      <ReferenceInput
        source="organization_id"
        reference="organizations"
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteInput
          emptyText="-- None --"
          optionText={(choice?: Organization) =>
            choice?.id // the empty choice is { id: '' }
              ? `${choice.name}`
              : "-- None --"
          }
        />
      </ReferenceInput>
    ) : null,
  ].filter((filter) => filter !== null);

export const AnalysisGroupList = () => {
  const { permissions } = usePermissions();

  return (
    <ListGuesser
      actions={ListActions(permissions)}
      sort={{ field: "name", order: "DESC" }}
      hasShow={false}
      hasEdit={false}
      filters={analysisGroupFilters(permissions)}
      rowClick="edit"
      bulkActionButtons={<BulkActionButtons />}
    >
      <FieldGuesser source="name" />
      <FieldGuesser source="preset" />
      <NumberField source="retention" />
      <FieldGuesser source="allowDerived" />
      <FieldGuesser source="anonymize" />
      <ReferenceField
        sortable={false}
        source="organization.id"
        reference="organizations"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
      <DeleteWithConfirmButton confirmContent="ra.confirm.delete" />
    </ListGuesser>
  );
};
