import {
  AutocompleteInput,
  CreateButton,
  DeleteWithConfirmButton,
  EditButton,
  ExportButton,
  FilterButton,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  TextField,
  TopToolbar,
  usePermissions,
  useTranslate
} from "react-admin";
import { ListGuesser, FieldGuesser } from "@api-platform/admin";
import WebhookIcon from '@mui/icons-material/Webhook';
import { triggerWebhook } from "../../../lib/client/api";
import { AnalysisGroup } from "../../../shared/types/analysisGroups";
import BulkActionButtons from "../../../shared/components/BulkActionButtons";
import CustomActionButton from "../../../shared/components/CustomActionButton";


const ListActions = (permissions: any) => (
  <TopToolbar>
    {permissions.role.name === "SuperAdmin" && <FilterButton />}
    {(permissions.role.name === "SuperAdmin" ||
      permissions.role.name === "Admin") && <CreateButton />}
    <ExportButton />
  </TopToolbar>
);

const dicomNodeFilters = (permissions: any) =>
  [
    <SearchInput source="q" alwaysOn />,
    permissions.role.name === "SuperAdmin" ? (
      <ReferenceInput
        source="group_id"
        reference="analyzis_groups"
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteInput
          emptyText="-- None --"
          optionText={(choice?: AnalysisGroup) =>
            choice?.id // the empty choice is { id: '' }
              ? `${choice.name}`
              : "-- None --"
          }
        />
      </ReferenceInput>
    ) : null,
  ].filter((filter) => filter !== null);

export const DicomNodeList = () => {
  const { permissions } = usePermissions();
  const t = useTranslate()

  return (
    <ListGuesser
      actions={ListActions(permissions)}
      sort={{ field: "id", order: "DESC" }}
      hasShow={false}
      hasEdit={false}
      filters={dicomNodeFilters(permissions)}
      rowClick="edit"
      bulkActionButtons={<BulkActionButtons />}
    >
      <FieldGuesser source="id" />
      <NumberField source="aeTitle" />
      <FieldGuesser source="username" />
      <FieldGuesser source="report" />
      <FieldGuesser source="structuredData" />
      <FieldGuesser source="mask" />
      <FieldGuesser source="mesh" />
      <ReferenceField
        sortable={false}
        source="group.id"
        reference="analyzis_groups"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <CustomActionButton
        callback={record => {triggerWebhook(record.id as number);}}
        icon={<WebhookIcon />}
        aria-label={t('resources.dicom_nodes.tooltip.triggerWebhook')}
        title={t('resources.dicom_nodes.tooltip.triggerWebhook')}
      />
      <EditButton />
      <DeleteWithConfirmButton confirmContent="ra.confirm.delete" />
    </ListGuesser>
  );
};
