import { Create, SimpleForm } from "react-admin";
import { AnalysisGroupForm } from "./AnalysisGroupForm";

import { SaveAction } from "./utils/SaveAction";

export const AnalysisGroupCreate = () => (
  <Create redirect="list">
    <SimpleForm mode="onBlur" reValidateMode="onBlur" toolbar={<SaveAction />}>
      <AnalysisGroupForm />
    </SimpleForm>
  </Create>
);
