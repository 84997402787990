// import { dataProviderFactory } from '@api-platform/admin/src/openapi/dataProvider';
// import { restDataProvider } from '@api-platform/admin/src/dataProvider';
import { ENTRYPOINT } from "../../config/api";
import { fetchJson } from "../client/api";
import { openApiDataProvider } from "@api-platform/admin";
import simpleRestProvider from "ra-data-simple-rest";
import { parseOpenApi3Documentation } from "@api-platform/api-doc-parser";

const baseDataProvider = openApiDataProvider({
  dataProvider: simpleRestProvider(ENTRYPOINT, fetchJson),
  entrypoint: ENTRYPOINT,
  docEntrypoint: ENTRYPOINT + "/openapi.json",
  apiDocumentationParser: (entrypointUrl: string) => {
    return parseOpenApi3Documentation(entrypointUrl)
      .then((res) => {
        const analysisGroupResource = res.api.resources.find(({ name }) => name === 'analyzis_groups');
        const clinicalTrialConfigurationField = analysisGroupResource.fields.find(({ name }) => name === 'clinicalTrialConfiguration');
        clinicalTrialConfigurationField.enum = res.response.components.schemas.ClinicalTrialOutputType.enum;
        return res;
      });
  }
});

const dataProvider = {
  ...baseDataProvider,
};

export default dataProvider;
