import { Card } from '@mui/material'
import useStudyMetadata from 'shared/hooks/useStudyMetadata'
import { useTranslate } from 'ra-core'

type Props = { studyId: string }

function PatientInfo({ studyId }: Props) {
  const { PatientID, PatientName, age, sex } = useStudyMetadata(studyId)
  const t = useTranslate()

  return (
    <Card className='mx-3 p-2' sx={{ width: "auto !important" }} data-testid="viewer-menu-patient-info">
      <h3 className='pl-2 pb-2 text-lg font-bold'>{t('patient.info.title')}</h3>

      <dl className='grid grid-cols-3'>
        <dt> {`${t('patient.info.identifier')}:`} </dt>
        <dd className='font-semibold col-span-2'>{PatientID}</dd>

        <dt> {`${t('patient.info.name')}:`} </dt>
        <dd className='font-semibold col-span-2'>{PatientName}</dd>

        <dt> {`${t('patient.info.age')}:`} </dt>
        <dd className='font-semibold col-span-2'>{typeof age === "number" ? Math.floor(age) : age}</dd>

        <dt> {`${t('patient.info.sex')}:`} </dt>
        <dd className='font-semibold col-span-2'>{sex}</dd>
      </dl>
    </Card>
  )

}

export default PatientInfo