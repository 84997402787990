import { useSnapshot } from 'valtio'
import { IconButton, Slider, Switch } from '@mui/material'
import HelpIcon from '@mui/icons-material/Help';
import { useTranslate } from 'ra-core'

import useViewerParams from 'shared/hooks/useViewerParams'
import settingsState, { disableCrossHair, toogleOrientationLabel as toggleOrientationLabel, updateRoiOpacity, updateWindowLevel } from 'stores/settings'
import { toggleHelp } from 'stores/ui'

function ViewerSettings() {
  const { atViewerPage } = useViewerParams()
  const {windowLevel, roiOpacity, showOrientationLabel, defaultWindowLevel} = useSnapshot(settingsState)
  const t = useTranslate()

  if (!atViewerPage) {
    return null
  }

  //@ts-ignore
  const handleWindowLevelChange = (e: Event, value: number): void => updateWindowLevel({ [e.target.name]: value })
  const handleRoiOpacityChange = (e: Event, value: number): void => updateRoiOpacity(value)
  const offset = 1000

  return (
    <fieldset className='border px-1 rounded-lg inline-block m-2 relative'>
      <IconButton size="small" className="-top-6 -right-4 z-10" aria-label="help" title="Help" onClick={toggleHelp}
        color="secondary" sx={{ position: "absolute", backgroundColor: "#121212", '&:hover': { backgroundColor: "#121212" } }}>
        <HelpIcon />
      </IconButton>

      <legend className='px-1.5'>{t('settings.title')}</legend>
      <form className='grid grid-cols-2 gap-x-2 items-center px-2 py-1'>
        <label>{t('settings.field.width')}</label> 
        <Slider name="width" onChange={handleWindowLevelChange} min={defaultWindowLevel.width - offset} max={defaultWindowLevel.width + offset} value={windowLevel.width} valueLabelDisplay="auto" />
        
        <label>{t('settings.field.center')}</label> 
        <Slider name="center" onChange={handleWindowLevelChange} min={defaultWindowLevel.center - offset} max={defaultWindowLevel.center + offset} value={windowLevel.center} valueLabelDisplay="auto" />
        
        <label>{t('settings.field.roi_opacity')}</label> 
        <Slider name="opacity" onChange={handleRoiOpacityChange} value={roiOpacity} valueLabelDisplay="auto" />
        
        <label>{t('settings.field.orientation_labels')}</label>
        <Switch checked={showOrientationLabel} onClick={toggleOrientationLabel} />
        
        <label>{t('settings.field.3d_localizer')}</label> 
        <Switch defaultChecked onClick={disableCrossHair} className='block' />
      </form>
    </fieldset>
  )
}

export default ViewerSettings