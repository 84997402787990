import { Chip, LinearProgress, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecordContext, FieldProps, RaRecord, useTranslate, type Translate } from 'react-admin';

export function reformatIfFhirPatientTask(record: RaRecord) {
  const extension = record?.extension?.[0]?.value
  if (!extension) return record

  const startedAt = extension?.executionPeriod?.start;
  const endedAt = extension?.executionPeriod?.end;
  return {
    id: record.id,
    startedAt,
    endedAt,
    duration: (new Date(endedAt + "Z").getTime() - new Date(startedAt + "Z").getTime()) / 1000,
    estimatedEndDate: extension?.requestedPeriod?.end,
    status: extension?.status,
    errorMessage: extension.statusReason
  };
}

const colorStatus = {
  "done": "#0a5004",
  "completed": "#0a5004",
  "error": "#702020",
  "failed": "#702020",
  "cached": "#2F3C2F",
  "running": "#151535",
  "in-progress": "#151535",
  "scheduled": "#456565"
}

function AnalysisStatusField(props: FieldProps) {
  const recordContext = useRecordContext();
  const [progress, setProgress] = useState(0)
  const t = useTranslate()

  const record = reformatIfFhirPatientTask(recordContext)

  useEffect(() => {
    let interval: NodeJS.Timer | undefined;
    const { status, startedAt, estimatedEndDate } = record
    if ((status === "running" || status === "in-progress") && startedAt) {
      interval = setInterval(() => {
        const elapsedTime = Date.now() - new Date(startedAt + "Z").getTime();
        const estimatedTime = new Date(estimatedEndDate + "Z").getTime() - new Date(startedAt + "Z").getTime();
        const estimatedProgress = Math.floor((elapsedTime / estimatedTime) * 10000) / 100
        setProgress(estimatedProgress)
      }, 1000);
    } else {
      setProgress(0)
      clearInterval(interval)
    }
    return () => clearInterval(interval);
  }, [record]);

  if (progress === 0 || progress > 100) {
    const tooltip = getErrorText(record, t);
    return <Tooltip title={tooltip} placement='top'>
      <Chip style={{ backgroundColor: colorStatus[record.status] }} label={t("resources.status." + record.status)} />
    </Tooltip>
  }


  return <div style={{ display: 'flex', paddingRight: '1em', minWidth: '10em', maxWidth: '15em', margin: 'auto' }}>
    <LinearProgress
      color="primary"
      variant={progress != null ? 'determinate' : 'indeterminate'}
      style={{ height: 10, borderRadius: 4, marginTop: 6, width: '100%' }}
      value={progress}
    />
    <span style={{ marginLeft: '-60%', zIndex: '1' }}>{progress}%</span>
  </div>
}

export default AnalysisStatusField

function getErrorText(record: RaRecord, t: Translate) {
  if(!["failed","error"].includes(record.status)) return
  const errorMessage: string = record.steps
    ? record.steps.filter(step => step.status === 'error').map(step => step.name + ': ' + step.errorMessage).join("\n")
    : t("resource.error.message.unknown");
  const isErrorUnknown = record?.exitCode === 1
    || record?.errorMessage === "Unknonwn error"
    || errorMessage.includes(t("resource.error.message.unknown"))
    || errorMessage.includes("Unknonwn error") // TODO Remove when typo in qyerror is removed
  if (isErrorUnknown) return t("resource.error.message.unknown")

  if (record?.isInputError) {
    return `${record?.errorMessage || errorMessage} : ${t("resource.error.message.input")}`
  }

  return record?.errorMessage || errorMessage;
}
