import type { Validator } from "ra-core";
const FORBIDDEN_WORDS = [
  "qynapse",
  "qyscore",
  "qypredict",
  "qyviewer",
  "orchestrator",
  "viewer",
  "api",
  "boucicaut",
  "montreal",
  "lyon",
  "paris",
  "boston",
];

const FORBIDDEN_SEQUENCES = [
  "01234567890",
  "09876543210",
  "zyxwvutsrqponmlkjihgfedcda",
  "abcdefghijklmnopqrstuvwxyz",
  "azertyuiopqsdfghjklmwxcvbn",
  "qwertyuiopasdfghjklzxcvbnm",
];

//** confirm password validation */
export const confirmPasswordValidation = (
  confirmPassword: any,
  userInfo: any
) => {
  if (!confirmPassword) {
    return "Confirm password is required";
  }

  if (userInfo.password && userInfo.password !== confirmPassword) {
    return "Password and confirm password are not match";
  }
};

function escapeRegExp(str: string) {
  return str.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&");
}
//** String intersection */
const StringIntersections = (haystack: any, sequence: any) => {
  let intersections: any[] = [];
  let index = 0;
  const haystackLength = haystack.length;
  const sequenceLength = sequence.length;
  for (let i = 0; i < haystack.length; i++) {
    let character = haystack[i];
    const position = sequence.search(escapeRegExp(character));
    if (position >= 0) {
      let length = 1;
      while (
        haystack.slice(index, index + length) ===
        sequence.slice(position, position + length)
      ) {
        length += 1;
        if (
          haystackLength < index + length ||
          sequenceLength < position + length
        )
          break;
      }

      length -= 1;
      intersections = intersections.concat(
        haystack.slice(index, index + length)
      );
    }

    index += 1;
  }

  return intersections;
};

export const pwdValidation: {
  [x: string]: (password: string, userInfo?: { email: string }) => boolean;
} = {
  "resources.users.validation.length": (password: string) =>
    password && password?.length >= 12 && password?.length <= 64,
  "resources.users.validation.uppercase": (password: string) =>
    password && /[A-Z]/.test(password),
  "resources.users.validation.lowercase": (password: string) =>
    password && /[a-z]/.test(password),
  "resources.users.validation.digit": (password: string) =>
    password && /\d/.test(password),
  "resources.users.validation.three_in_a_row": (password: string) =>
    password && !/(.)\1{2}/gi.test(password),
  "resources.users.validation.special_character": (password: string) =>
    //eslint-disable-next-line
    password && /[-:_=\\|/?^&!.@$£#*\(\)%~<>{}\[\];]/.test(password),
  "resources.users.validation.email_part": emailIntersection,
  "resources.users.validation.forbidden_word": (password: string) =>
    password &&
    !FORBIDDEN_WORDS.some((fw) =>
      password?.toString().toLowerCase().includes(fw)
    ),
  "resources.users.validation.previsible_sequence":
    forbiddenSequencesIntersection,
};

export const validatepassword: (noMessage: boolean | undefined) => Validator =
  (noMessage) => (password: string, userInfo: { email: string }) => {
    const validations: Array<[string, boolean]> = Object.entries(
      pwdValidation
    ).map(([errorMsg, validate_fn]) => [
      errorMsg,
      validate_fn(password, userInfo),
    ]);
    if (noMessage) return validations.some((v) => !v[1]) ? " " : undefined;
    return validations.find((v) => v[1])?.[0];
  };

function forbiddenSequencesIntersection(password: string) {
  if (!password) return false;
  for (let i = 0; i < FORBIDDEN_SEQUENCES.length; i++) {
    let forbiddenSequence = FORBIDDEN_SEQUENCES[i];
    let stringIntersections = StringIntersections(
      password?.toString().toLowerCase(),
      forbiddenSequence
    );
    for (let n = 0; n < stringIntersections.length; n++) {
      let intersection = stringIntersections[n];
      if (intersection.length > 3) {
        return false;
      }
    }
  }
  return true;
}

function emailIntersection(password: string, userInfo: { email: string }) {
  if (!password) return false;
  const lowerPwd = password?.toString().toLowerCase();
  if (userInfo?.email) {
    const lowerEmail = userInfo?.email?.toString().toLowerCase();
    if (lowerEmail.includes(lowerPwd) || lowerPwd.includes(lowerEmail)) {
      return false;
    }
    //eslint-disable-next-line
    for (let i = 0; i < lowerEmail.split(/[\.@]+/).length; i++) {
      //eslint-disable-next-line
      let part = lowerEmail.split(/[\.@]+/)[i];
      if (part.length > 3 && lowerPwd.includes(part)) {
        return false;
      }
    }
  }

  return true;
}
