import { useEffect, useRef, forwardRef, Ref } from "react";
import dcmjs from "dcmjs";
import { EVENTS } from "@cornerstonejs/core";
import { Enums } from "@cornerstonejs/tools";
import { initViewer } from "lib/cornerstone/setupCornerstoneViewer";
import { useSnapshot } from "valtio";
import settingsState from "stores/settings";
import uiState, { toggleExpandedPane } from "stores/ui";
import {
  crosshairRenderingListener,
  voiModifiedListener,
} from "lib/cornerstone/helpers/listeners";
import useViewerSetup from "shared/hooks/useViewerSetup";
import useStudyMetadata from "shared/hooks/useStudyMetadata";
import { useParams } from "react-router";

export interface ViewerProps {
  id: string;
  instanceMetadata: dcmjs.data.NaturalizedDataset | null | any;
}

function Viewer({ id, instanceMetadata }: ViewerProps) {
  const ui = useSnapshot(uiState);
  const { study_id } = useParams();
  const {SEGIsDone, naturalizedDatasets} = useStudyMetadata(study_id);
  useViewerSetup(study_id, SEGIsDone, naturalizedDatasets);
  const viewerRef1 = useRef<HTMLDivElement>(null);
  const viewerRef2 = useRef<HTMLDivElement>(null);
  const viewerRef3 = useRef<HTMLDivElement>(null);

  if (ui.error instanceof Error) {
    throw ui.error;
  }

  useEffect(() => {
    const viewer1 = viewerRef1.current;
    viewer1.addEventListener(
      Enums.Events.ANNOTATION_RENDERED,
      crosshairRenderingListener
    );
    viewer1.addEventListener(EVENTS.VOI_MODIFIED, voiModifiedListener);
    return () => {
      viewer1.removeEventListener(
        Enums.Events.ANNOTATION_RENDERED,
        crosshairRenderingListener
      );
      viewer1.removeEventListener(EVENTS.VOI_MODIFIED, voiModifiedListener);
    };
  });

  useEffect(() => {
    if (instanceMetadata) {
      uiState.loading = true;
      console.count("INIT VIEWER")
      initViewer(id, [viewerRef1, viewerRef2, viewerRef3], instanceMetadata)
        .catch((e) => (uiState.error = e))
        .finally(() => (uiState.loading = false));
      uiState.error = undefined;
    }
  }, [instanceMetadata, id]);

  return (
    <>
      <ViewerPane
        id={`viewer_${id}_1`}
        orientationLabels={{ top: "S", left: "R" }}
        ref={viewerRef1}
        expandedPane={ui.expandedPane}
      />
      <ViewerPane
        id={`viewer_${id}_2`}
        orientationLabels={{ top: "S", left: "A" }}
        ref={viewerRef2}
        expandedPane={ui.expandedPane}
      />
      <ViewerPane
        id={`viewer_${id}_3`}
        orientationLabels={{ top: "A", left: "R" }}
        ref={viewerRef3}
        expandedPane={ui.expandedPane}
      />
    </>
  );
}

interface ViewerPaneProps {
  id: string;
  orientationLabels: { top: string; left: string };
  expandedPane: string | null;
}

export const ViewerPane = forwardRef(function ViewerPane(
  { id, orientationLabels, expandedPane }: ViewerPaneProps,
  ref: Ref<HTMLDivElement>
) {
  let className = "h-[45vh] rounded-lg overflow-hidden bg-black relative";
  if (expandedPane === id) {
    className =
      "h-[90vh] w-[85vw] rounded-lg overflow-hidden bg-black relative";
  } else if (expandedPane !== null) {
    className = "hidden";
  }

  return (
    <div
      id={id}
      data-testid={id}
      onContextMenu={(e) => e.preventDefault()}
      onDoubleClick={() => toggleExpandedPane(id)}
      className={className}
      ref={ref}
    >
      <OrientationLabel
        top={orientationLabels.top}
        left={orientationLabels.left}
      />
    </div>
  );
});

function OrientationLabel({ top, left }: { top: string; left: string }) {
  const snap = useSnapshot(settingsState);
  if (!snap.showOrientationLabel) {
    return null;
  }

  return (
    <>
      <span className="absolute top-1 left-[49.3%] z-10">{top}</span>
      <span className="absolute top-[47.5%] left-1 z-10">{left}</span>
    </>
  );
}

export default Viewer;
