import { InputGuesser } from "@api-platform/admin";

export const OrganizationForm = () => {
  return (
    <>
      <InputGuesser source="name" isRequired />
      <InputGuesser source="domain" isRequired />
    </>
  );
};
