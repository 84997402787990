import { Skeleton } from "@mui/material";
import useMarkers from "shared/hooks/useMarkers";
import { useParams } from "react-router";
import uiState from "stores/ui";
import SequenceMarkers from "./SequenceMarkers";

function QuantificationTable() {
  const { study_id } = useParams();
  const { naturalizedDatasets, displaySegment, isLoading, populatedMarkers, error } =
    useMarkers(study_id);

  if (error instanceof Error) {
    uiState.error = error;
    throw error;
  }
  if (isLoading) {
    return (
      <div>
        <Skeleton
          className="bg-stone-500 leading-0"
          animation={false}
          height={50}
        />
        <Skeleton className="bg-stone-500" animation="wave" height={50} />
        <Skeleton className="bg-stone-500" animation="wave" height={50} />
        <Skeleton className="bg-stone-500" animation="wave" height={50} />
        <Skeleton className="bg-stone-500" animation="wave" height={50} />
        <Skeleton className="bg-stone-500" animation="wave" height={50} />
        <Skeleton className="bg-stone-500" animation="wave" height={50} />
      </div>
    );
  }

  return (
    <table
      data-testid="viewer-mode-quantification"
      className="w-full rounded overflow-x-clip text-right"
    >
      <thead className="bg-stone-600 rounded sticky top-0 z-50 ">
        <tr>
          <th className="py-2 rounded-tl w-8"></th>
          <th className="py-2 text-left w-72">Structure</th>
          <th className="py-2">Volume(mL)</th>
          <th className="py-2">Volume(%ICV)</th>
          <th className="py-2">Zscore</th>
          <th className="py-2">Percentile</th>
          <th className="py-2 rounded-tr w-8"></th>
        </tr>
      </thead>
      <tbody>
        {naturalizedDatasets?.length ?
         naturalizedDatasets
         .filter(d => d.Modality === 'SEG')
         .sort((dA,dB)=> dA.SeriesDescription.localeCompare(dB.SeriesDescription))
         .map(d => <SequenceMarkers key={d.SeriesInstanceUID} dataset={d} populatedMarkers={populatedMarkers} displaySegment={displaySegment} />) 
         : null}
      </tbody>
    </table>
  );
}

export default QuantificationTable;
