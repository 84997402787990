import { useWatch } from "react-hook-form";
import { Toolbar, SaveButton } from "react-admin";

export const SaveAction = (props) => {
  const userData = useWatch();

  if (userData.preset !== null) {
    userData.workflowConfiguration = null;
  }

  if (
    typeof userData.workflowConfiguration == "string" &&
    userData.workflowConfiguration.length > 0
  ) {
    userData.workflowConfiguration = JSON.parse(userData.workflowConfiguration);
  }
  return (
    <Toolbar {...props}>
      <SaveButton {...props} {...userData} type="button" />
    </Toolbar>
  );
};
