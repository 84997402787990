import { Layout, LayoutProps } from "react-admin";

import About from "features/about";
import ViewerHelp from "features/patients/viewer/components/ViewerHelp";
import UploadAnalyses from "features/upload";
import Menu from "./Menu";
import NavBar from "./NavBar";
import ErrorDisplay from "./ErrorDisplay";

function CustomLayout({ children, ...props }: LayoutProps) {
  return (
    <Layout {...props} appBar={NavBar} menu={Menu} error={ErrorDisplay}>
      <UploadAnalyses />
      <ViewerHelp />
      <About />
      {children}
    </Layout>
  );
}

export default CustomLayout;
