import {
  Dialog,
  DialogContent,
  DialogTitle,
  Tabs,
  Tab,
  Skeleton,
} from "@mui/material";
import { useTranslate, useLocaleState, useLocales } from "ra-core";
import { useSnapshot } from "valtio";
import Barcode from "react-barcode";

import useVersions from "shared/hooks/useVersions";
import uiState, { toggleAbout } from "stores/ui";

//@ts-ignore
import CE_SVG from "shared/images/ce_dark.svg";
//@ts-ignore
import FDA_SVG from "shared/images/fda.svg";
//@ts-ignore
import FACTORY_PNG from "shared/images/factory_dark.svg";
//@ts-ignore
import MD_PNG from "shared/images/md_dark.svg";
//@ts-ignore
import eIFU_PNG from "shared/images/eIFU_dark.svg";
import { useState } from "react";
import QyscoreLogo from "shared/images/QyscoreLogo";

interface AboutProps {
  id: string;
  version: string;
  uniqueDeviceIdentifier: string;
  beta: boolean;
  ce?: boolean;
  fda?: boolean;
  showTitle?: boolean;
}

export const AboutVersion = ({
  ce,
  fda,
  uniqueDeviceIdentifier,
  version,
  showTitle,
  beta,
}: AboutProps) => {
  const t = useTranslate();
  return (
    <div>
      {showTitle ? (
        <h2 className="text-2xl mb-2 text-center border rounded">
          {" "}
          {version}{" "}
        </h2>
      ) : null}
      {beta ? (
        <div className="text-center bg-red-700 p-2">{t("beta.disclaimer")}</div>
      ) : null}
      <div className="grid grid-cols-3">
        <div />
        <QyscoreLogo />
      </div>
      <div>
        <h2 className="text-2xl mb-2 text-center rounded"> {version} </h2>
      </div>
      <div className="my-6 mx-10 flex justify-around items-center gap-20">
        {ce ? <img src={CE_SVG} className="h-14" alt="CE" /> : null}
        {fda ? <img src={FDA_SVG} className="w-28 h-20" alt="FDA" /> : null}
        <img className="h-14" src={MD_PNG} alt="MD" />
        <div>
          <div className="grid grid-cols-3">
            <div />
            <img className="h-14" src={eIFU_PNG} alt="eIFU" />
          </div>
          <p className="text-center">manuals.qynapse.com</p>
        </div>
      </div>
      <div className="flex justify-center">
        <Barcode
          height={50}
          width={1.5}
          format="CODE128"
          value={uniqueDeviceIdentifier}
        />
      </div>
      <div className=" my-6 mx-10 flex  align-items-center justify-center">
        <img className="h-14 flex mr-2" src={FACTORY_PNG} alt="Factory" />
        <div>
          <p>{t("company.name")}</p>
          <p>{t("company.address")}</p>
        </div>
      </div>
    </div>
  );
};

const About = () => {
  const { about } = useSnapshot(uiState);
  const t = useTranslate();
  const locales = useLocales();
  const [currentLocale] = useLocaleState();
  const currentLanguage = locales.find((l) => l.locale === currentLocale)?.name;

  return (
    <Dialog maxWidth="xl" open={about} onClose={toggleAbout}>
      <DialogTitle className="grid grid-cols-3" fontSize="2em">
        <div />
        <div className="text-center">{t("pos.menu.info.about")}</div>
        <div className="text-end text-xl text-neutral-400">
          {currentLanguage}
        </div>
      </DialogTitle>
      <DialogContent>
        <MultipleVersions />
      </DialogContent>
    </Dialog>
  );
};

function MultipleVersions() {
  const { about } = useSnapshot(uiState);
  const { data: versions, isLoading } = useVersions(about);
  const [active, setActive] = useState<number>(0);

  const handleChange = (e, newValue: number) => setActive(newValue);

  if (isLoading) {
    return <Skeleton animation="pulse" width={500} />;
  }

  if (!versions?.length) return null;

  const uniqueVersions = new Set<string>();

  // Use Array.filter() to filter out duplicates
  const filteredList = versions.filter((item) => {
    if (uniqueVersions.has(item.version)) {
      // If the version is already in the Set, it's a duplicate
      return false;
    } else {
      // If the version is not in the Set, add it and return true
      uniqueVersions.add(item.version);
      return true;
    }
  });

  return (
    <>
      {versions.length > 1 ? (
        <Tabs
          centered
          variant="fullWidth"
          value={active}
          onChange={handleChange}
        >
          {filteredList.map(({ version }) => (
            <Tab label={version} />
          ))}
        </Tabs>
      ) : null}
      <AboutVersion id={"about-" + active} {...filteredList[active]} />
    </>
  );
}

export default About;
