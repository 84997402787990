import React from "react";

import { BrowserRouter, Route } from "react-router-dom";
import { AdminGuesser, openApiSchemaAnalyzer } from "@api-platform/admin";
import { CustomRoutes, Resource } from "react-admin";
import { PatientShow } from "features/patients/components/PatientShow";

import authProvider from "lib/authprovider";
import dataProvider from "lib/dataprovider";
import i18nProvider from "lib/i18nprovider";
import theme from "lib/theme";

import Layout from "shared/layout/Layout";

import patients from "./features/patients";
import analysis from "./features/analysis";
import clinicalTials from "./features/clinical-trials";
import { ClinicalTrailGeneralOverview } from "features/clinical-trials/components/ClinicalTrialExternal";
import { ClinicalTrialInternal } from "features/clinical-trials/components/ClinicalTrialInternal";
import { LoginPage } from "./features/authentication";
import { ResetPassword } from "features/authentication/pages/ResetPasswordPage";
import { ForgotPassword } from "features/authentication/pages/ForgotPasswordPage";

import imagingstudies from "./features/imagingstudies";
import queryClient from "lib/client/main";

import { adminResources } from "shared/utils/resourcesPermision";

import QuantificationTable from "features/patients/quantification";
import NormativeGraph from "features/patients/normative-graph";
import LongitudinalGraph from "features/patients/longitudinal-graph";
import Rendering3D from "features/patients/3d-rendering";
import { MODES } from "features/patients/modes/components/Modes";
import LoadingScreen from "shared/layout/LoadingScreen";

const schemaAnalyzer = openApiSchemaAnalyzer();

const App = () => {
  return (
    <BrowserRouter>
      <AdminGuesser
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        loading={LoadingScreen}
        queryClient={queryClient}
        layout={Layout}
        loginPage={LoginPage}
        schemaAnalyzer={schemaAnalyzer}
        theme={theme}
        disableTelemetry
      >
        {(permissions) => (
          <>
            <CustomRoutes noLayout>
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
            </CustomRoutes>
            {permissions &&
            permissions?.role?.name === "CROClinicalTrialProgramManager" ? (
              <>
                <Resource name="clinicaltrials" {...clinicalTials} />
                <CustomRoutes>
                  <Route
                    path="/clinicaltrials/:id/external"
                    element={<ClinicalTrailGeneralOverview />}
                  />
                </CustomRoutes>
                <Resource name="patients" {...patients} />
                <Resource name="analyzes" {...analysis} />
                <Resource name="imagingstudies" {...imagingstudies} />
              </>
            ) : (
              <>
                <Resource name="patients" {...patients} />
                <Resource name="analyzes" {...analysis} />
                <Resource name="imagingstudies" {...imagingstudies} />
                <Resource name="clinicaltrials" {...clinicalTials} />
                <CustomRoutes>
                  <Route
                    path="/clinicaltrials/:id/external"
                    element={<ClinicalTrailGeneralOverview />}
                  />
                  {permissions?.role?.name === "SuperAdmin" && (
                    <Route
                      path="/clinicaltrials/:id/internal"
                      element={<ClinicalTrialInternal />}
                    />
                  )}
                </CustomRoutes>
              </>
            )}
            <Resource name="groups" recordRepresentation="name" />
            <Resource name="locations" recordRepresentation="name" />
            <CustomRoutes>
              <Route
                path="/patients/:patient_id/studies/:study_id"
                element={<PatientShow />}
              >
                <Route index element={<QuantificationTable />} />
                <Route
                  path={MODES.QUANTIFICATION}
                  element={<QuantificationTable />}
                />
                <Route
                  path={MODES.NORMATIVEGRAPH}
                  element={<NormativeGraph />}
                />
                <Route
                  path={MODES.LONGITUDINALGRAPH}
                  element={<LongitudinalGraph />}
                />
                <Route path={MODES.RENDERING3D} element={<Rendering3D />} />
              </Route>
            </CustomRoutes>
            {permissions
              ? adminResources.map((resource) => (
                  <Resource
                    key={resource.name}
                    name={resource.name}
                    {...resource.props}
                    create={
                      resource.createPermission
                        .map((p) => p.toLowerCase())
                        .indexOf(permissions.role.name.toLowerCase()) !== -1 &&
                      permissions.actions[`/${resource.name}`].indexOf("POST") >
                        -1 &&
                      "create" in resource.props
                        ? resource.props.create
                        : undefined
                    }
                    list={
                      resource.listPermission
                        .map((p) => p.toLowerCase())
                        .indexOf(permissions.role.name.toLowerCase()) !== -1 &&
                      permissions.actions[`/${resource.name}`].indexOf("GET") >
                        -1 &&
                      "list" in resource.props
                        ? resource.props.list
                        : undefined
                    }
                    show={
                      resource?.showPermission
                        .map((p) => p.toLowerCase())
                        .indexOf(permissions.role.name.toLowerCase()) !== -1 &&
                      permissions.actions[`/${resource.name}`].indexOf("GET") >
                        -1 &&
                      "show" in resource.props
                        ? resource.props.show
                        : undefined
                    }
                    edit={
                      resource.editPermission
                        .map((p) => p.toLowerCase())
                        .indexOf(permissions.role.name.toLowerCase()) !== -1 &&
                      permissions.actions[`/${resource.name}`].indexOf("PUT") >
                        -1 &&
                      "edit" in resource.props
                        ? resource.props.edit
                        : undefined
                    }
                  ></Resource>
                ))
              : null}
            ,
          </>
        )}
      </AdminGuesser>
    </BrowserRouter>
  );
};

export default App;
