import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useTranslate } from "ra-core";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BarChart({ xAxis, yAxis, analysisSummary }) {
  const t = useTranslate();
  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: xAxis,
        },
      },
      y: {
        ticks: {
          precision: 0,
          beginAtZero: true,
          stepSize: 1,
        },
        title: {
          display: true,
          text: yAxis,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const labels = analysisSummary.map((item) =>
    t(`resources.status.${item.label}`)
  );
  const unqielabels = [...new Set(labels)];

  const resultArray = [];
  unqielabels.forEach((label) => {
    const valuesWithSameLabel = analysisSummary.filter(
      (item) => t(`resources.status.${item.label}`) === label
    );
    const sumValues = valuesWithSameLabel.reduce(
      (sum, item) => sum + item.value,
      0
    );
    resultArray.push(sumValues);
  });

  const data = {
    labels: unqielabels,
    datasets: [
      {
        label: "",
        data: resultArray,
        backgroundColor: [
          "rgba(75, 192, 192)",
          "rgba(54, 162, 235)",
          "rgba(255, 206, 86)",
          "rgba(255, 99, 132)",
          "rgba(153, 102, 255)",
        ],
      },
    ],
  };
  return <Bar options={options} data={data} />;
}

export default BarChart;
