import { NORMATIVE_ENTRYPOINT } from "../../config/api";
import dcmjs from "dcmjs";

interface PolynomialIndices {
  x0: number;
  x1: number;
  x2: number;
}
type Percentiles =
  | "0.01"
  | "0.05"
  | "0.1"
  | "0.25"
  | "0.5"
  | "0.75"
  | "0.9"
  | "0.95"
  | "0.99";
type Zcores = "model" | "lower" | "upper";

export interface RegressionModel {
  count: number;
  min: number;
  max: number;
  percentiles: { [key in Percentiles]?: PolynomialIndices };
  zscore: { [key in Zcores]?: PolynomialIndices };
}

export async function fetchRegressionModels(
  sex: dcmjs.data.NaturalizedDataset["PatientSex"] = undefined
) {
  const gender = sex === "M" ? "male" : sex === "F" ? "female" : undefined;
  const searchParam = new URLSearchParams();
  if (gender) searchParam.append("gender", gender);

  const url = `${NORMATIVE_ENTRYPOINT}/regression/all?${searchParam}`;
  const response = await fetch(url);
  if (!response.ok) {
    throw Error(String(response.status));
  }
  const regressionModels = (await response.json()) as Record<
    string,
    RegressionModel
  >;
  return regressionModels;
}

// TODO Move unrelated code into a seperate file
function applySecondDegreePolynom(x: number, a: number, b: number, c: number) {
  return a + b * x + c * x * x;
}

export function getAgeFromMetadata(
  metadata: dcmjs.data.NaturalizedDataset
): number | null {
  return Number(metadata?.PatientAge?.replace("Y", "")) || null;
}

export function calculateZscore(
  regression: RegressionModel,
  age: number,
  measurementValue: number
) {
  const lower = applySecondDegreePolynom(
    age,
    regression.zscore.lower.x0,
    regression.zscore.lower.x1,
    regression.zscore.lower.x2
  );
  const upper = applySecondDegreePolynom(
    age,
    regression.zscore.upper.x0,
    regression.zscore.upper.x1,
    regression.zscore.upper.x2
  );
  const standardDeviation =
    (Math.sqrt(regression.count) * (upper - lower)) / 3.92;
  const mean = applySecondDegreePolynom(
    age,
    regression.zscore.model.x0,
    regression.zscore.model.x1,
    regression.zscore.model.x2
  );
  return (measurementValue - mean) / standardDeviation;
}

export function calculatePercentiles(
  regression: RegressionModel,
  age: number
): { [key in Percentiles]?: number } {
  return Object.fromEntries(
    Object.entries(regression.percentiles).map(
      ([percs, { x0, x1, x2 }]: [Percentiles, PolynomialIndices]) => [
        percs,
        applySecondDegreePolynom(age, x0, x1, x2),
      ]
    )
  );
}

export function getPercentileRange(
  percentiles: { [key in Percentiles]?: number },
  volume: number
) {
  let entries = Object.entries(percentiles);
  for (let i = 0; i < entries.length; i++) {
    const [perc, value] = entries[i];
    if (i === 0 && volume < value) {
      return `< ${Number(perc) * 100}`;
    }
    if (i > 0) {
      const [prevPerc, prevValue] = entries[i - 1];
      if (volume < value && volume > prevValue) {
        return `${Number(prevPerc) * 100} - ${Number(perc) * 100}`;
      }
    }
    if (i === entries.length - 1 && volume > value) {
      return ` > ${Number(perc) * 100}`;
    }
  }

  return "-";
}
