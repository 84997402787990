import { data } from "dcmjs";

import { Markers, type Marker } from "./Marker";
import ValidationToolBox from "../../validation/ValidationToolBox";
import useValidation from "shared/hooks/useValidation";
import {removeBetaDisclaimer} from "shared/utils/recursive";
import { useSnapshot } from "valtio";
import viewerState from "stores/viewer";


type SequenceRowProps = {
  dataset: data.NaturalizedDataset
  populatedMarkers: Marker[]
  displaySegment: ({ segments, seriesUID }: Pick<Marker, 'seriesUID' | 'segments'>, onlySelect?: boolean) => void
}

function SequenceMarkers({ dataset, populatedMarkers, displaySegment }: SequenceRowProps) {
  const validationStore = useValidation(dataset)
  const { instanceMetadata } = useSnapshot(viewerState)

  const seriesDescription = removeBetaDisclaimer(dataset.SeriesDescription)

  const seriesMarkers = populatedMarkers
    .filter(m => m.seriesUID === dataset.SeriesInstanceUID)

  const substructures = seriesMarkers
    .flatMap((m) => m.substructures)
    .filter((m, index, arr) => arr.indexOf(m) === index && m);

  const roots = seriesMarkers
    .filter(m => !substructures?.includes(m.code) && !m.isUnknown);

  const unknownMarkers = seriesMarkers
    .filter((m) => m.isUnknown);

  const isCurrentSeries = instanceMetadata?.SeriesInstanceUID === dataset?.SeriesInstanceUID

  return <>
    <tr className={`shadow-inner bg-stone-500 ${isCurrentSeries ? "font-bold" : ""}`}>
      <td className='py-2 text-center'></td>
      <td className='py-2 text-left text-lg'>
        <abbr title={dataset.SeriesInstanceUID} className="border-b-transparent decoration-transparent inline-block first-letter:uppercase">
          {seriesDescription}
        </abbr>
      </td>
      <td colSpan={5} className='py-2'>
        <div className="flex justify-end">
          <ValidationToolBox dataset={dataset} seriesMarkers={seriesMarkers} />
        </div>
      </td>
    </tr>
    {roots
      .filter(m => m.seriesUID === dataset.SeriesInstanceUID)
      .map((m) => (
        <Markers
          key={m.code}
          depth={0}
          marker={m}
          populatedMarkers={seriesMarkers}
          displaySegment={displaySegment}
          //@ts-ignore
          validationStore={validationStore}
        />
      ))}
    {unknownMarkers.map((m) => (
      <Markers
        key={m.code}
        depth={0}
        marker={m}
        populatedMarkers={unknownMarkers}
        displaySegment={displaySegment}
        //@ts-ignore
        validationStore={validationStore}
      />
    ))
    }
  </>
}

export default SequenceMarkers