import {
  AutocompleteInput,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  FunctionField,
  TextField,
  WrapperField,
  TopToolbar,
  ReferenceInput,
  TextInput,
  SelectInput,
  DateInput,
  List,
  ExportButton,
  useTranslate,
  useLocaleState,
  useListContext,
  useGetList,
  usePermissions,
  downloadCSV
} from "react-admin";
import { useMediaQuery, Theme, Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

import { ImagingStudyList } from "../../imagingstudies/components/ImagingStudyList";
import BulkActionButtons from "../../../shared/components/BulkActionButtons";
import AnalysisStatusField from "shared/components/AnalysisStatusField";
import DurationField from "shared/components/DurationField";

const ListActions = () => {
  const { filterValues, setFilters, sort } =
    useListContext();
  const customFilter = { ...filterValues, _format: "csv" };
  const PatientListExporter = (record) => {
    const filterPart: string = Object.entries(filterValues)
      .map(([key, value]) => `${key}_${value}`.replace(/[^a-z0-9]/gi, "-"))
      .join("_");
    const localDate = new Date()
      .toISOString()
      .replace(/[^0-9]/gi, "-")
      .slice(0, -2);
    const sanitizedFilename = `patients_${filterPart}_${localDate}`.replace(
      "__",
      "_"
    );
    downloadCSV(record[0].data, sanitizedFilename);
  };

  return (
    <TopToolbar>
      {Object.keys(filterValues)?.length
        ? <Button style={{ "lineHeight": 1.5 }} size="small" onClick={() => setFilters({}, {})}> Reset </Button>
        : null
      }
      <ExportButton
        resource="observations"
        sort={sort}
        filterValues={customFilter}
        exporter={PatientListExporter}
        label="Export"
      />
    </TopToolbar>
  );
};

const patientListFilters = [
  <TextInput
    className="w-48"
    source="identifier:match"
    label="resources.patients.filter.identifier.match"
    alwaysOn
  />,
  <TextInput
    source="name:match"
    label="resources.patients.filter.name.match"
    alwaysOn
  />,
  <DateInput 
    source="birthdate"
    alwaysOn
  />,
  <SelectInput
    source="gender"
    choices={[
      { id: "male", name: "resources.patients.filter.gender.male" },
      { id: "female", name: "resources.patients.filter.gender.female" },
    ]}
    emptyText="resources.patients.filter.gender.empty"
    emptyValue={0}
    alwaysOn
  />,
  <ReferenceInput
    source="groups"
    reference="groups"
    label="resources.patients.filter.groups"
    alwaysOn
  >
    <AutocompleteInput TextFieldProps={{ style: { "width": "13em" } }}
      label="resources.patients.filter.groups"
      emptyText="resources.patients.filter.groups.empty"
    />
  </ReferenceInput>,
  <DateInput 
    source="acquisitionStartDate"
    label="resources.analyzes.filter.acquisition.start"
    alwaysOn
  />,
  <DateInput 
  source="acquisitionEndDate"
  label="resources.analyzes.filter.acquisition.end"
  alwaysOn
  />,
];


export const PatientList = () => {
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const t = useTranslate();
  const { permissions } = usePermissions();
  const [locale] = useLocaleState();
  const {data: groups} = useGetList("groups")

  return (
    <List
      sx={{"& .MuiToolbar-root": {mb: 3}}}
      actions={<ListActions />}
      filters={patientListFilters.filter(p => p.props["source"] !== "groups" || groups?.length >= 2 )}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid
        expand={<ImagingStudyList />}
        bulkActionButtons={<BulkActionButtons />}
        rowClick="expand"
      >
        <TextField
          source="identifier.0.value"
          sortable={false}
          label={t("resources.patients.fields.id")}
        />
        <TextField
          source="name.0.given.0"
          sortable={false}
          label={t("resources.patients.fields.name")}
        />
        {isLarge ? (
          <DateField
            source="birthDate"
            sortable={false}
            locales={locale}
            label={t("resources.patients.fields.birthdate")}
            options={{ year: "numeric", month: "2-digit", day: "2-digit" }}
          />
        ) : null}
        {isLarge ? (
          <FunctionField
            sortable={false}
            source="gender"
            label={t("resources.patients.fields.gender")}
            render={({ gender }) =>
              gender ? t("resources.patients.filter.gender." + gender) : ""
            }
          />
        ) : null}
        {isLarge && groups?.length >= 2 ? (
          <TextField
            sortable={false}
            source="extension.0.value.extension.0.valueString"
            label={t("resources.analyzes.fields.preset")}
          />
        ) : null}
        {isLarge ? (
          <DurationField
            sortable={false}
            data-testid={"duration-field"}
            source="extension.0.value"
            label={t("resources.analyzes.fields.duration")}
          />
        ) : null}
        <AnalysisStatusField
          data-testid={`analysis-status-field`}
          sortable={false}
          source="extension.0.value.status"
          label={t("resources.analyzes.fields.status")}
        />
        <FunctionField
          label={t("resources.patients.fields.imagingStudyCount")}
          render={(record) =>
            record.extension
              ? record.extension?.filter((ext) =>
                ext.url.startsWith("/fhir/ImagingStudy/")
              ).length
              : 0
          }
        />
        <WrapperField cellClassName="text-right">
          {permissions.role.name === "SuperAdmin" ? <EditButton  /> :  null}
          <DeleteWithConfirmButton className="!text-right" label={null} confirmContent="ra.confirm.delete" icon={<DeleteIcon />} />
        </WrapperField>
      </Datagrid>
    </List>
  );
};
