import { useState } from "react"
import { useTranslate } from "ra-core";

import useMarkers from "shared/hooks/useMarkers"
import { bgDepth, IMarkerRow, shadowDepth } from "features/patients/quantification/components/Marker"

import { IconButton } from "@mui/material"
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Eye from "features/patients/quantification/components/Eye";
import { translateMarkerRID } from "shared/utils/recursive";


interface NormativeGraphTableProps {
  studyId: string
}

const LONGITUDINAL_WMH_RID = "RID39467:RID5683";

function LongitudinalTable({ studyId }: NormativeGraphTableProps) {
  const { populatedMarkers, displaySegment } = useMarkers(studyId)

  const longitudinalMarker = populatedMarkers.find(m => m.code === LONGITUDINAL_WMH_RID && m.hasSegments === true)
  const seriesMarkers = populatedMarkers.filter(m => m.seriesUID === longitudinalMarker.seriesUID)

  if (!longitudinalMarker) return null

  return (
    <div className="m-3">
      <table data-testid="longitudinal-table" className='w-full rounded overflow-hidden text-right'>
        <tbody>
          <LongitudinalMarkers depth={0} marker={longitudinalMarker} populatedMarkers={seriesMarkers} displaySegment={displaySegment} />
        </tbody>
      </table>
    </div>
  )
}

export function LongitudinalMarkers({ marker, depth, displaySegment: displayMarker, populatedMarkers }: IMarkerRow) {
  const [open, setOpen] = useState(true);
  const t = useTranslate()

  if (marker.hasQuantif === false && marker.hasSegments === false) return null

  const canOpen = !!marker.substructures?.length
  const title = open ? t("patient.view.tooltip.marker.collapse") : t("patient.view.tooltip.marker.expand");
  return <>
    <tr className={`shadow-inner ${shadowDepth[depth]} ${bgDepth[depth]}`}>
      <td className='py-2 text-center'>
        {/* {marker?.segments?.map(s => s?.SegmentNumber).join(', ')} */}
        <Eye marker={marker} displayMarker={displayMarker} />
      </td>
      <td className='py-2 text-left'>
        <abbr title={marker.code} className="border-b-transparent decoration-transparent capitalize">
         {translateMarkerRID(marker.code, t)}
        </abbr>
      </td>
      <td className='py-2'>
        {canOpen &&
          <IconButton size='small' onClick={() => setOpen(!open)} aria-label={title} title={title}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        }
      </td>
    </tr>
    {open && marker?.substructures
      ?.map(rid => populatedMarkers.find(marker => marker.code === rid))
      ?.map(sub => <LongitudinalMarkers key={sub.code} depth={depth + 1} marker={sub} populatedMarkers={populatedMarkers} displaySegment={displayMarker} />)}
  </>
}

export default LongitudinalTable