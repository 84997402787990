import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function Selection({ data, name, onSelectChange }) {
  return (
    <FormControl style={{ margin: "2em" }} variant="filled">
      <InputLabel id="acquisition-selection-label"> {name} </InputLabel>
      <Select
        defaultValue="all"
        className="min-w-[8rem]"
        name="visits"
        size="small"
        onChange={(e) => onSelectChange(e.target.value)}
      >
        <MenuItem key="all" value="all" id="all">
          All
        </MenuItem>
        {data?.map((item) => {
          return (
            <MenuItem
              key={item.id ?? item.label}
              value={item}
              id={item.id ?? item.label}
            >
              {item.label ?? item?.identifier[0]?.value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default Selection;
