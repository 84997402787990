import React from "react";
import dcmjs from "dcmjs";
import { Button, Menu, MenuItem } from "@mui/material";
import ReportIcon from "@mui/icons-material/Summarize";
import { useTranslate } from "ra-core";

import useStudyMetadata from "shared/hooks/useStudyMetadata";
import JSZip from "jszip";
import {
  createPDFBlobFromDicomMetadata,
  downloadData,
} from "lib/cornerstone/helpers/fileManipulation";

import { pathologyChoices } from "features/analysis-groups/components/AnalysisGroupForm";
import { removeBetaDisclaimer } from "shared/utils/recursive";

interface ReportProps {
  study_id: string;
}

function Report({ study_id }: ReportProps) {
  const t = useTranslate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { naturalizedDatasets } = useStudyMetadata(study_id);
  const pdfInstances = naturalizedDatasets?.filter(
    (instance) =>
      instance.Modality === "DOC" &&
      instance.MIMETypeOfEncapsulatedDocument === "application/pdf" &&
      !!instance.SeriesInstanceUID
  );

  if (!pdfInstances?.length) {
    return null;
  }

  async function showReport(
    pdfInstance: dcmjs.data.NaturalizedDataset,
    isDownload?: boolean
  ) {
    const PDF = await createPDFBlobFromDicomMetadata(pdfInstance);
    viewOrDownloadPDF(PDF, isDownload && getPdfFilename(t, pdfInstance));
  }

  async function downloadZip(pdfInstances: dcmjs.data.NaturalizedDataset[]) {
    const pdfBlobPromises = pdfInstances.map((pdfI) =>
      createPDFBlobFromDicomMetadata(pdfI)
    );
    const pdfBlobs = await Promise.all(pdfBlobPromises);
    const zip = new JSZip();
    pdfBlobs.forEach((blob, i) => {
      const filename = getPdfFilename(t, pdfInstances[i]);
      zip.file(filename, blob);
    });
    const zipBlob = await zip.generateAsync({ type: "blob" });
    downloadData(zipBlob, "pdf_reports.zip");
  }

  return (
    <div>
      <Button
        startIcon={<ReportIcon />}
        sx={{ verticalAlign: "bottom", marginBottom: "1em", marginLeft: "2em" }}
        onClick={handleClick}
        variant="contained"
      >
        {t("report.name")}
      </Button>
      <Menu
        id="basic-menu"
        data-testid="report-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {pdfInstances?.map((pdfInstance) => (
          <MenuItem
            key={pdfInstance.SeriesInstanceUID}
            onClick={() => showReport(pdfInstance)}
          >
            {getReportName(t, pdfInstance.SeriesDescription)}
          </MenuItem>
        ))}
        <MenuItem
          onClick={() => {
            Promise.all(pdfInstances.map((pdf) => showReport(pdf, true)));
          }}
        >
          {" "}
          {t("report.download")}
        </MenuItem>
        <MenuItem onClick={() => downloadZip(pdfInstances)}>
          Download all reports as .zip
        </MenuItem>
      </Menu>
    </div>
  );
}

export function getReportName(t: Function, seriesDescription: string){
  const seriesDescAttributes = removeBetaDisclaimer(seriesDescription).split(":").at(-1);
  const pathology = pathologyChoices.map(p => p.toLowerCase()).find(p => seriesDescAttributes?.includes(p))
  const options = ["syn", "qc"].filter(o => seriesDescAttributes?.includes(o))
  return `${t("report.name")} ${[pathology, ...options].map(attr =>t("report.type." + attr)).join(" ")}`
}

function getPdfFilename(t: Function, pdfInstance: dcmjs.data.NaturalizedDataset): string {
  return `${getReportName(t, pdfInstance.SeriesDescription)}_${pdfInstance.PatientID}_${pdfInstance.StudyDate}.pdf`;
}

function viewOrDownloadPDF(blob: Blob, fileName?: string) {
  if (fileName) {
    downloadData(blob, fileName);
  } else {
    const data = window.URL.createObjectURL(blob);
    window.open(data);
  }
}

export default Report;
