import {
  DeleteWithConfirmButton,
  Toolbar,
  SaveButton,
  useEditController,
  Edit,
  SimpleForm,
} from "react-admin";
import { AnalysisGroupForm } from "./AnalysisGroupForm";

const EditToolbar = (props) => {
  const { record } = useEditController();
  return (
    <Toolbar {...props}>
      <SaveButton />
      <DeleteWithConfirmButton
        confirmContent="ra.confirm.delete"
        translateOptions={{ name: record.name }}
      />
    </Toolbar>
  );
};

export const AnalysisGroupEdit = () => {
  const transform = (analysisGroup) => {
    if (analysisGroup.preset == null) {
      if (
        typeof analysisGroup.workflowConfiguration == "string" &&
        analysisGroup.workflowConfiguration.length > 0
      ) {
        analysisGroup.workflowConfiguration = JSON.parse(
          analysisGroup.workflowConfiguration
        );
      }
    } else {
      analysisGroup.workflowConfiguration = null;
    }

    return {
      ...analysisGroup,
    };
  };
  return (
    <Edit transform={transform} redirect="list">
      <SimpleForm
        toolbar={<EditToolbar />}
        mode="onBlur"
        reValidateMode="onBlur"
      >
        <AnalysisGroupForm />
      </SimpleForm>
    </Edit>
  );
};
