import { cache } from '@cornerstonejs/core';
import { segmentation as cstSegmentation } from "@cornerstonejs/tools";
import getIds from './getIds';
import { Marker } from 'features/patients/quantification/components/Marker';

/**
 * Remove a segment volume segmentation in a defined radius.
 * @param marker - marker with coordinates
 * @param radius - diameter of the removal radius
 */
export function removeVolumeSegment({
  marker,
  radius = 5,
}: { marker: Marker, radius?: number }) {
  const origCenter = marker.coord
  const { toolGroupId } = getIds()
  const activeSegmentation = cstSegmentation.activeSegmentation.getActiveSegmentation(toolGroupId)
  // @ts-expect-error
  const segVolumeId = activeSegmentation.representationData.LABELMAP.volumeId
  const segmentationVolume = cache.getVolume(segVolumeId);
  // @ts-expect-error
  const scalarData = segmentationVolume.scalarData;
  const { dimensions } = segmentationVolume;

  // Invert Z 
  const center = [origCenter[0], origCenter[1], dimensions[2] - origCenter[2]]

  radius = radius || dimensions[0] / 20;

  let voxelIndex = 0;
  for (let z = 0; z < dimensions[2]; z++) {
    for (let y = 0; y < dimensions[1]; y++) {
      for (let x = 0; x < dimensions[0]; x++) {
        const distanceFromCenter = Math.sqrt(
          (x - center[0]) * (x - center[0]) +
          (y - center[1]) * (y - center[1]) +
          (z - center[2]) * (z - center[2])
        );
        if (distanceFromCenter < radius) {
          scalarData[voxelIndex] = 0;
        }
        voxelIndex++;
      }
    }
  }
  // Trigger event to rerender segmentation
  cstSegmentation.triggerSegmentationEvents.triggerSegmentationDataModified(activeSegmentation.segmentationId)
}
