import { IconButton } from "@mui/material";
import {
  Datagrid,
  DateField,
  TextField,
  FunctionField,
  ListContextProvider,
  useRecordContext,
  useGetList,
  useLocaleState,
  useList,
  useTranslate,
  WrapperField
} from "react-admin";
import CustomActionLink from "shared/components/CustomActionLink";
import VisibilityIcon from "@mui/icons-material/Visibility";


export const ImagingStudyList = (props) => {
  const patientRecord = useRecordContext();
  const t = useTranslate();
  const [locale] = useLocaleState();
  const patientIdsString = [patientRecord.id].join(",");
  const imagingStudyList = useGetList("imagingstudies", {
    filter: { subject: patientIdsString, _include: "ImagingStudy:performer" },
    sort: { field: "resource.started", order: "DESC" },
    pagination: { page: 1, perPage: 10 },
  });

  const listContext = useList({
    data: imagingStudyList.data,
    isFetching: imagingStudyList.isFetching,
    sort: { field: "started", order: "DESC" },
  });

  const isAnalysisDone =
    patientRecord?.extension?.[0]?.value?.status === "completed";
  const onRowClick = (id, resource, imagingStudyRecord) => {
    const patientId = imagingStudyRecord.subject?.reference?.split("/")?.[1];
    const studyId = imagingStudyRecord.uid;
    return `${patientId}/studies/${studyId}`;
  };

  return (
    <ListContextProvider value={listContext}>
      <Datagrid
        bulkActionButtons={false}
        hover={isAnalysisDone}
        rowClick={isAnalysisDone ? onRowClick : undefined}
      >
        <DateField
          source="started"
          locales={locale}
          label={t("resource.imagingstudies.startDate")}
          options={{
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          }}
        />
        <TextField
          source="description"
          label={t("resource.imagingstudies.description")}
        />
        <TextField
          source="series.0.performer.0.actor.name.0.value"
          label={t("resource.devices.name")}
        />
        <FunctionField
          source="modality.0.coding.0.code"
          label={t("resource.imagingstudies.modality")}
          render={(record => [...new Set(record.series
            .map(serie => serie?.modality?.coding?.[0].code)
            .sort().filter(Boolean))].join(", "))}
        />
        <FunctionField
          source="series.[].extension.0.valueString"
          label={t("resource.imagingstudies.sequence_type")}
          render={(record => [...new Set(record.series
            .map(serie => serie?.extension?.[0]?.valueString?.toUpperCase())
            .sort().filter(Boolean))].join(", "))}
        />
        <WrapperField label="Actions" cellClassName="w-8 text-blue-500 !py-1 !pr-5 text-right">
          <CustomActionLink
            title={t("resources.analyzes.tooltip.view")}
            aria-label={t("resources.analyzes.tooltip.view")}
            getPath={record => `/patients/${patientRecord.id}/studies/${record.uid}`}
            hidden={!isAnalysisDone}
          >
            <IconButton size='small' color="primary">
            <VisibilityIcon fontSize="small" />
            </IconButton>
          </CustomActionLink>
        </WrapperField>
      </Datagrid>
    </ListContextProvider>
  );
};
