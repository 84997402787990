import { DateField, useRecordContext, DateFieldProps } from "react-admin";
import get from "lodash/get";

/**
 * Overwrite DateField react admin component to force date at UTC
 * @param props
 * @returns
 */
function DateFieldUTC({ ...props }: DateFieldProps) {
  const record = useRecordContext();
  const dateTime: string = get(record, props.source);
  if (!dateTime) return null;

  const formattedRecord = { ...record, [props.source]: dateTime + "Z" };
  return <DateField {...props} record={formattedRecord} />;
}

export default DateFieldUTC;
