import { SelectInput, DateInput, ReferenceInput, useTranslate } from "react-admin";
import { InputGuesser } from "@api-platform/admin";

export const PatientForm = () => {
  const t = useTranslate();
  return (
    <>
      <SelectInput
        source="gender"
        choices={[
          { id: "male", name: t("resources.patients.filter.gender.male") },
          { id: "female", name: t("resources.patients.filter.gender.female") },
        ]}
        emptyText={t("resources.patients.filter.gender.empty")}
        emptyValue={0}
        alwaysOn
      />
      <DateInput source="birthDate"/>
    </>
  );
};
