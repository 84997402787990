import {
  Datagrid,
  DateField,
  FilterButton,
  TextField,
  TextInput,
  TopToolbar,
  List,
  useTranslate,
  useRecordContext,
  Button,
  usePermissions,
} from "react-admin";
import { Link as RouterLink } from "react-router-dom";

const ListActions = (permissions: any) => (
  <TopToolbar>
    {permissions.role.name === "SuperAdmin" && <FilterButton />}
  </TopToolbar>
);

export const ClinicalTrialist = () => {
  const t = useTranslate();
  const { permissions } = usePermissions();

  const clinicalTrialFilters = [
    <TextInput
      source="title:match"
      label={t("resources.clinicalTrials.filter.title.match")}
      alwaysOn
    />,
    <TextInput
      source="identifier:match"
      label={t("resources.clinicalTrials.filter.identifier.match")}
      alwaysOn
    />,
  ];

  const ShowingButton = ({ detailType }) => {
    const ctRecord = useRecordContext();
    return (
      <Button
        component={RouterLink}
        to={`/clinicaltrials/${ctRecord.id}/${detailType}`}
        label={`${detailType}`}
      ></Button>
    );
  };
  if (
    !(
      permissions.role.name === "SuperAdmin" ||
      permissions.role.name === "CROClinicalTrialProgramManager"
    )
  ) {
    return null;
  }

  return (
    <List
      actions={ListActions(permissions)}
      filters={clinicalTrialFilters}
      sort={{ field: "title", order: "DESC" }}
      hasShow={false}
      hasEdit={false}
    >
      <Datagrid bulkActionButtons={false} hover={false}>
        <TextField
          source="title"
          label={t("resources.clinicalTrials.fields.title")}
        />
        <TextField
          source="name"
          label={t("resources.clinicalTrials.fields.identifier")}
        />
        <TextField
          source="associatedParty.0.name"
          label={t("resources.clinicalTrials.fields.sponsor")}
        />
        <TextField
          source="focus.coding.0.display"
          label={t("resources.clinicalTrials.fields.molecule")}
        />
        <TextField
          source="extension.0.value"
          label={t("resources.clinicalTrials.fields.expected_number_of_visits")}
        />
        <TextField
          source="recruitment.target"
          label={t(
            "resources.clinicalTrials.fields.expected_number_of_subjects"
          )}
        />
        <DateField
          source="period.start"
          label={t("resources.clinicalTrials.fields.expected_start_date")}
        />
        <DateField
          source="period.end"
          label={t("resources.clinicalTrials.fields.expected_end_date")}
        />
        {permissions.role.name === "SuperAdmin" ? (
          <ShowingButton detailType="internal" />
        ) : null}
        <ShowingButton detailType="external" />
      </Datagrid>
    </List>
  );
};
