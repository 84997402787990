import { Marker } from "../../features/patients/quantification/components/Marker";
import configuration from "../../config/radlex/hierarchy.json"
import { Segment } from "../../stores/viewer";

export function recursiveToFlat(configuration) : Marker[]{
  var new_configuration = [];
  for (var i = 0; i < configuration.length; i++) {
    var sub = configuration[i];
    new_configuration.push(sub);
    if (sub.substructures && sub.substructures.length) {
      var substructure_replacement = []
      for (var j = 0; j < sub.substructures.length; j++) {
        substructure_replacement.push(sub.substructures[j].code)
      }
      var sub_new_configuration = recursiveToFlat(sub.substructures);
      for (var k = 0; k < sub_new_configuration.length; k++) {
        new_configuration.push(sub_new_configuration[k]);
      }
      sub.substructures = substructure_replacement;
    }
  }
  return new_configuration;
}


export const markers = recursiveToFlat(configuration)

export function getAllDescendant(rid: string, children: string[]= []) : string[]{
  const marker = markers.find(m => m.code === rid)
  const newChildren = marker?.substructures?.flatMap(child => getAllDescendant(child, children))
  return [...(marker?.substructures||[]), ...(newChildren||[])]
}

export function getSegmentRID(segment: Segment){
  const code = segment.SegmentedPropertyTypeCodeSequence?.[0]?.CodeValue
  const region = segment.AnatomicRegionSequence?.[0].AnatomicRegionModifierSequence?.[0]?.CodeValue
  const modifier = segment.SegmentedPropertyTypeCodeSequence?.[0]?.SegmentedPropertyTypeModifierCodeSequence?.[0]?.CodeValue
  return [code, region, modifier].filter(rid => Boolean(rid)).join(":") 
}

export const eqSet = (xs: Set<unknown>, ys: Set<unknown>) =>
xs.size === ys.size &&
[...xs].every((x) => ys.has(x));

export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce((groups, item) => {
    if (!key(item)) return groups;
    (groups[key(item)] ||= []).push(item);
    return groups;
  }, {} as Record<K, T[]>);

export function uniqBy<T,  K extends keyof any>(a :T[], key: (i: T) => K) {
  const seen = {} as Record<K, boolean>;
  return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
  })
}

export function translateMarkerRID(code: string, translateFn: (rid: string) => string) {
  if (code.includes('RID4700')) {
    return code?.split(":")
      ?.map(rid => isNaN(Number(rid)) ? translateFn(rid) : rid)
      ?.join(' - ')
      // TODO : Remove those replace(All) when ontology has been fix
      ?.replaceAll(" cortex", "").replaceAll(" site", "")
      ?.replace(" - ", " ")
      ?.replace("o-h", "oh")
  }
  return code?.split(":")
    ?.map(rid => isNaN(Number(rid)) ? translateFn(rid) : rid)
    ?.join(' ')
}

export function removeBetaDisclaimer(description: string){
  return description.split(" - ").find(elem => !elem.trim().startsWith("QYSCORE") && !elem.trim().startsWith("NOT TO BE"))
}
