import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import CopyrightSharpIcon from "@mui/icons-material/CopyrightSharp";
import InfoIcon from "@mui/icons-material/Info";
import {
  useTranslate,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  usePermissions,
  useGetList,
  Loading,
} from "react-admin";

import SubMenu from "./SubMenu";
import users from "features/users";
import organizations from "features/organizations";
import analyzisGroups from "features/analysis-groups";
import authenticationMethods from "features/authentication-methods";
import dicomNodes from "features/dicom-nodes";
import patients from "features/patients";
import analysis from "features/analysis";
import activity from "features/activities";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { MenuItem } from "@mui/material";
import { toggleAbout, toggleUpload } from "stores/ui";
import ViewerMenu from "./ViewerMenu";
import theme from "lib/theme";
import { QyscoreManualLink } from "features/patients/viewer/components/ViewerHelp";

type MenuName = "menuAdmin" | "menuInfo";

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuAdmin: false,
    menuInfo: false,
  });
  const [clinicalTrialList, setClinicalTrialList] = useState([]);

  const translate = useTranslate();
  const [open] = useSidebarState();
  const { data: clinicaltrials } = useGetList("clinicaltrials");
  useEffect(() => {
    setClinicalTrialList(() =>
      clinicaltrials !== undefined && clinicaltrials.length > 0
        ? clinicaltrials
        : []
    );
  }, [clinicaltrials]);
  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  const { permissions } = usePermissions();
  if (!permissions) {
    return <Loading />;
  } else {
    return (
      <Box
        sx={{
          width: open ? theme.sidebar.width : theme.sidebar.closedWidth,
          marginTop: 2,
          marginBottom: 1,
          transition: (theme) =>
            theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        {permissions &&
        permissions.role.name !== "CROClinicalTrialProgramManager" ? (
          <>
            <MenuItem
              onClick={toggleUpload}
              style={{ color: "rgb(255, 255, 255, 0.7)" }}
            >
              <CloudUploadIcon className="mr-4 text-white" />
              {translate("resources.analyses.upload.name")}
            </MenuItem>
            <MenuItemLink
              to="/patients"
              state={{ _scrollToTop: true }}
              primaryText={translate("resources.patients.name", {
                smart_count: 2,
              })}
              leftIcon={<patients.icon />}
              dense={dense}
            />
            <MenuItemLink
              to="/analyzes"
              state={{ _scrollToTop: true }}
              primaryText={translate("resources.analyzes.name", {
                smart_count: 2,
              })}
              leftIcon={<analysis.icon />}
              dense={dense}
            />
          </>
        ) : null}

        {clinicalTrialList.length > 0 &&
        (permissions.role.name == "SuperAdmin" ||
          permissions.role.name === "CROClinicalTrialProgramManager") ? (
          <MenuItemLink
            to="/clinicaltrials"
            state={{ _scrollToTop: true }}
            primaryText={translate(`resources.clinicalTrials.name`, {
              smart_count: 2,
            })}
            leftIcon={<users.icon />}
            dense={dense}
          />
        ) : null}

        {(permissions.role.name === "SuperAdmin" ||
          permissions.role.name === "Admin") && (
          <SubMenu
            data-testid="admin-submenu"
            handleToggle={() => handleToggle("menuAdmin")}
            isOpen={state.menuAdmin}
            name="pos.menu.admin"
            icon={<SupervisedUserCircleIcon />}
            dense={dense}
          >
            <MenuItemLink
              to="/users"
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.users.name`, {
                smart_count: 2,
              })}
              leftIcon={<users.icon />}
              dense={dense}
            />
            <MenuItemLink
              to="/organizations"
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.organizations.name`, {
                smart_count: 2,
              })}
              leftIcon={<organizations.icon />}
              dense={dense}
            />
            <MenuItemLink
              to="/analyzis_groups"
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.analyzis_groups.name`, {
                smart_count: 2,
              })}
              leftIcon={<analyzisGroups.icon />}
              dense={dense}
            />
            <MenuItemLink
              to="/authentication_methods"
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.authentication_methods.name`, {
                smart_count: 2,
              })}
              leftIcon={<authenticationMethods.icon />}
              dense={dense}
            />
            <MenuItemLink
              to="/dicom_nodes"
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.dicom_nodes.name`, {
                smart_count: 2,
              })}
              leftIcon={<dicomNodes.icon />}
              dense={dense}
            />
            <MenuItemLink
              to="/activity"
              state={{ _scrollToTop: true }}
              primaryText={translate(`resources.activity.name`, {
                smart_count: 2,
              })}
              leftIcon={<activity.icon />}
              dense={dense}
            />
          </SubMenu>
        )}
        <SubMenu
          className="mb-3"
          handleToggle={() => handleToggle("menuInfo")}
          isOpen={state.menuInfo}
          name="pos.menu.info"
          icon={<InfoIcon />}
          dense={dense}
        >
          <MenuItem
            onClick={toggleAbout}
            style={{ color: "rgb(255, 255, 255, 0.7)", paddingLeft: 0 }}
          >
            <a>
              <CopyrightSharpIcon className="mr-4 text-white" />
              {translate(`pos.menu.info.about`)}
            </a>
          </MenuItem>
          <MenuItem
            style={{ color: "rgb(255, 255, 255, 0.7)", paddingLeft: 0 }}
          >
            <QyscoreManualLink />
          </MenuItem>
        </SubMenu>
        <ViewerMenu open={open} />
      </Box>
    );
  }
};

export default Menu;
