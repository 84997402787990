
import { IconButton } from "@mui/material";
import EraseSegmentIcon from '@mui/icons-material/AutoFixNormal';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import { useSnapshot } from "valtio";
import { useTranslate } from "ra-core"

import viewerState, { type Segment, setEditSegment } from "stores/viewer";
import { setMarkerValidation, type DicomValidation } from "stores/validation"
import { removeVolumeSegment as eraseVolumeSegment } from "lib/cornerstone/helpers/volumeManipulation";
import type { Marker } from "../quantification/components/Marker";

function EditSegmentIcon({ marker, validationStore }: { marker: Marker, validationStore: DicomValidation }) {
  const { segmentsPerSeries, editSegmentNumber} = useSnapshot(viewerState)
  const t = useTranslate()
  if (!validationStore) return null

  const validated = validationStore?.markerValidations?.[marker.code]?.status
  const segment = segmentsPerSeries.find(s => s.SegmentNumber === marker.segments[0].SegmentNumber)

  const edit = () => {
    if (!segment) {
      console.error("No segment to edit")
      return
    }
    viewerState.segmentsPerSeries.find(s =>
      s.SegmentNumber === marker.segments[0].SegmentNumber
      && s.seriesUID === marker.seriesUID
    ).selected = true
    setEditSegment(segment as Segment)
    setMarkerValidation(marker.code, "edited")
  }

  const eraseSegment = () => {
    eraseVolumeSegment({ marker: marker })
    setMarkerValidation(marker.code, "erased")
  }

  if (validated === "erased") {
    return <IconButton disabled title={t("patient.view.tooltip.validation.erase")} aria-label={t("patient.view.tooltip.validation.erase")}>
      <EraseSegmentIcon className="text-black" />
    </IconButton>
  } else if (validated === "edited") {
    return <IconButton disabled title={t("patient.view.tooltip.validation.edit")} aria-label={t("patient.view.tooltip.validation.edit")}>
      <EditIcon className="text-black" />
    </IconButton>
  } else if (validated === "unvalidated" && validationStore?.isValtioStore) {
    return <>
      <IconButton size='small' onClick={edit} aria-label={t("patient.view.tooltip.validation.edit")} title={t("patient.view.tooltip.validation.edit")}>
        {(editSegmentNumber === segment.SegmentNumber) ? <EditOffIcon className="text-black" /> : <EditIcon />}
      </IconButton>
      {marker.coord ? <IconButton onClick={eraseSegment} title={t("patient.view.tooltip.validation.erase")} aria-label={t("patient.view.tooltip.validation.erase")}>
        <EraseSegmentIcon />
      </IconButton>
        : null}
    </>
  }

  return null

}

export default EditSegmentIcon