import { UserForm } from "./UserForm";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  PasswordInput,
  ReferenceInput,
  SelectInput,
} from "react-admin";

import {
  confirmPasswordValidation,
  validatepassword,
} from "../../../shared/utils/inputValidation";
import PasswordValidation from "shared/components/PasswordValidation";

export const UserCreate = () => {
  return (
    <Create redirect={false}>
      <SimpleForm mode="onBlur" reValidateMode="onBlur">
        <TextInput source="email" isRequired validate={required()} />
        <UserForm />
        <ReferenceInput source="role.id" reference="roles">
          <SelectInput
            optionText="name"
            source="role"
            isRequired
            validate={required()}
          />
        </ReferenceInput>
        <PasswordInput
          source="password"
          autoComplete="new-password"
          isRequired
          validate={validatepassword(true)}
        />
        <PasswordValidation />
        <PasswordInput
          source="confirm_password"
          autoComplete="new-password"
          isRequired
          validate={confirmPasswordValidation}
        />
      </SimpleForm>
    </Create>
  );
};
