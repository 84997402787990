import { data } from "dcmjs";
import { api } from "dicomweb-client";
import removeInvalidTags from "./removeInvalidTags";


function safeNaturalizeDataset(dataset: api.Metadata){
  if(!dataset) return null
  return data.DicomMetaDictionary.naturalizeDataset(removeInvalidTags(dataset))
}

export const denaturalizeDataset = data.DicomMetaDictionary.denaturalizeDataset

export default safeNaturalizeDataset