import {
  BooleanInput,
  DateInput,
  FormDataConsumer,
  Labeled,
  NumberInput,
  ReferenceInput,
  ReferenceArrayInput,
  SelectInput,
  SelectArrayInput,
  useTranslate,
  useInput,
  useI18nProvider,
  TextInput,
} from "react-admin";
import { InputGuesser } from "@api-platform/admin";
import { useWatch } from "react-hook-form";

import CodeEditor from "@uiw/react-textarea-code-editor";
import ClinicalTrialConfigurationField from "./utils/ClinicalTrialConfigurationField";

const WorkflowConfigurationInput = (props: any) => {
  const t = useTranslate();
  document.documentElement.setAttribute("data-color-mode", "dark");
  const { onChange, onBlur, ...rest } = props;
  const { field } = useInput({
    onChange,
    onBlur,
    ...props,
  });
  const preset = useWatch({ name: "preset" });
  var configuration = field?.value;
  if (typeof field?.value == "object" && field?.value !== null) {
    configuration = JSON.stringify(field.value);
  }

  return (
    preset == null && (
      <Labeled
        label={t("resources.analyzis_groups.fields.workflowConfiguration")}
      >
        <CodeEditor
          {...rest}
          {...field}
          placeholder={configuration}
          value={configuration}
          padding={15}
          language="json"
          style={{
            fontSize: 12,
          }}
        />
      </Labeled>
    )
  );
};

export const pathologyChoices = ["GEN", "MCI", "MS", "EP", "PD", "CUSTOM"]

export const AnalysisGroupForm = () => {
  const i18nProvider = useI18nProvider();
  const locales = i18nProvider.getLocales();
  const t = useTranslate();

  return (
    <>
      <InputGuesser source="name" />
      <InputGuesser source="preset" isRequired />
      <WorkflowConfigurationInput source="workflowConfiguration" />
      <InputGuesser source="retention" />
      <BooleanInput source="allowDerived" />
      <BooleanInput source="anonymize" />
      <BooleanInput source="keepTemporaryFiles" />
      <SelectInput
        source="pathology"
        choices={pathologyChoices.map(p =>({value: p, name: t(`report.type.${p}`)}))}
        optionText="name"
        optionValue="value"
        emptyText="-- All --"
      />
      <BooleanInput source="synthetic" label={t('report.type.syn')} />
      <BooleanInput source="qualityControl" label={t('report.type.qc')} />
      <SelectInput
        source="locale"
        choices={locales}
        optionText="name"
        optionValue="locale"
        emptyText="-- Default --"
      />
      <ReferenceInput source="organization.id" reference="organizations">
        <SelectInput optionText="name" source="organization" isRequired />
      </ReferenceInput>
      <ReferenceArrayInput
        source="users"
        reference="users"
        sort={{ field: "email", order: "ASC" }}
      >
        <SelectArrayInput
          parse={(value) => value && value?.map((v) => ({ id: v }))}
          format={(value) => value && value?.map((v) => v.id)}
          optionText="email"
        />
      </ReferenceArrayInput>
      <TextInput source="clinicalTrialConfiguration.sponsor" />
      <TextInput source="clinicalTrialConfiguration.molecule" />
      <NumberInput source="clinicalTrialConfiguration.expected_number_of_subjects" />
      <NumberInput source="clinicalTrialConfiguration.expected_number_of_visits" />
      <DateInput source="clinicalTrialConfiguration.expected_start_date" />
      <DateInput source="clinicalTrialConfiguration.expected_end_date" />
      <ClinicalTrialConfigurationField source="clinicalTrialConfiguration" />
    </>
  );
};
