import { data } from "dcmjs";
import type { Segmentation } from "@cornerstonejs/tools/dist/types/types";
import { ImageVolume, cache, metaData } from "@cornerstonejs/core";
import dicomParser from "dicom-parser";
import { adaptersSEG } from '@cornerstonejs/adapters'
import { Buffer } from "buffer";
import { segmentation as cstSegmentation } from "@cornerstonejs/tools";
import getIds from "./getIds";
import { idb } from "stores/idb";
import validationState, { resetValidation } from "stores/validation";
import { retrieveInstance } from "lib/client/dicomweb";
import { getSegmentRID } from "shared/utils/recursive";

type LabelMapsObject = ReturnType<typeof generateLabelMaps2DFrom3D> & {
  metadata?: data.NaturalizedDataset["SegmentSequence"];
};

const { generateLabelMaps2DFrom3D, generateSegmentation } = adaptersSEG.Cornerstone3D.Segmentation;

export function getDicomSEGBlobFromDataset(dataset: data.NaturalizedDataset) {
  const generatedSegmentation = generateSegmentationDataset(dataset)
  const blob = createDicomBlob(generatedSegmentation.dataset)
  return blob
}

export async function saveValidationSEG(dataset: data.NaturalizedDataset) {
  const generatedSegmentation = generateSegmentationDataset(dataset)

  if (generatedSegmentation) {
    const blob = createDicomBlob(generatedSegmentation.dataset)
    await idb.dicoms.put({
      studyUID: dataset.StudyInstanceUID,
      seriesUID: generatedSegmentation.dataset.SeriesInstanceUID,
      referenceSeriesUID: dataset.SeriesInstanceUID,
      markerValidations: JSON.parse(JSON.stringify(validationState.markerValidations)),
      dataset: JSON.parse(JSON.stringify(generatedSegmentation.dataset)),
      dicom: await blob.arrayBuffer()
    }, dataset.SeriesInstanceUID)
  } else {
    await idb.dicoms.put({
      studyUID: dataset.StudyInstanceUID,
      referenceSeriesUID: dataset.SeriesInstanceUID,
      markerValidations: JSON.parse(JSON.stringify(validationState.markerValidations)),
    }, dataset.SeriesInstanceUID)
  }
  resetValidation()
}

export async function deleteValidationSEG(referenceSeriesUID: string) {
  await idb.dicoms.delete(referenceSeriesUID)
}

export function generateSegmentationDataset(dataset: data.NaturalizedDataset) {
  // If all segment are erased or all segment are untouched only save the validation with no dicom
  const allSegmentErased = dataset.SegmentSequence?.every(seg => {
    const validated = validationState.markerValidations[getSegmentRID(seg)]?.status
    return !validated || validated === "erased"
  })
  const allSegmentUntouched = dataset.SegmentSequence?.every(seg => {
    const validated = validationState.markerValidations[getSegmentRID(seg)]?.status
    return !validated || !["erased", "edited"].includes(validated)
  })

  if (allSegmentErased || allSegmentUntouched) {
    return null
  }

  const { volumeId } = getIds()
  const volume = cache.getVolume(volumeId) as ImageVolume;
  const images = volume.getCornerstoneImages()
  const segmentations = cstSegmentation.state.getSegmentations()
  const labelmapObjs = segmentations
    .filter((seg: Segmentation) => seg.segmentationId.includes(dataset.SeriesInstanceUID))
    .map((seg: Segmentation) => {
      //@ts-ignore
      const segVolumeId = seg.representationData.LABELMAP.volumeId
      const segmentationVolume = cache.getVolume(segVolumeId) as ImageVolume;

      const labelmapObj: LabelMapsObject = generateLabelMaps2DFrom3D(segmentationVolume);
      labelmapObj.metadata = [];
      labelmapObj.segmentsOnLabelmap.forEach(segmentIndex => {
        const segmentMetadata = dataset.SegmentSequence.find(segmentSeq => segmentSeq.SegmentNumber === segmentIndex)
        labelmapObj.metadata[segmentIndex] = segmentMetadata;
      });
      return labelmapObj
    })

  const generatedSegmentation: { dataset: data.NaturalizedDataset } = generateSegmentation(images, labelmapObjs, metaData, {});

  // Inject some metadata tags
  generatedSegmentation.dataset.StudyInstanceUID = dataset.StudyInstanceUID
  const seriesInstanceUID = data.DicomMetaDictionary.uid();
  generatedSegmentation.dataset.SeriesInstanceUID = seriesInstanceUID
  generatedSegmentation.dataset.SeriesDescription = `${dataset.SeriesDescription} modified`
  const referenceOriginalSerie: typeof dataset.ReferencedSeriesSequence[0] = {
    SeriesInstanceUID: dataset.SeriesInstanceUID,
    ReferencedInstanceSequence: [{
      ReferencedSOPClassUID: dataset.SOPClassUID,
      ReferencedSOPInstanceUID: generatedSegmentation.dataset.SOPInstanceUID
    }]
  }
  generatedSegmentation.dataset.ReferencedSeriesSequence = [...dataset.ReferencedSeriesSequence, referenceOriginalSerie]

  // List all valid segment to inject into the new dicom SEG
  const SegmentSequence = generatedSegmentation.dataset.SegmentSequence
    .filter(seg => {
      const rid = getSegmentRID(seg);
      return validationState.markerValidations?.[rid]?.status !== "erased";
    });

  if (!SegmentSequence?.length) {
    return null
  }

  generatedSegmentation.dataset.SegmentSequence = SegmentSequence
    .map(seg => {
      const relatedSeg = dataset.SegmentSequence.find(segmentSeq => segmentSeq.SegmentNumber === Number(seg.SegmentNumber))
      const AnatomicRegionSequence = relatedSeg.AnatomicRegionSequence
      AnatomicRegionSequence[0].CodingSchemeVersion = "RADLEX"
      return {
        ...seg,
        AnatomicRegionSequence,
        SegmentNumber: Number(seg.SegmentNumber),
        SegmentAlgorithmType: "SEMIAUTOMATIC"
      }
    })

  return generatedSegmentation
}


export function createDicomBlob(bufferOrDataset: data.NaturalizedDataset) {
  let blob: Blob;
  if (bufferOrDataset instanceof ArrayBuffer) {
    blob = new Blob([bufferOrDataset], { type: "application/dicom" });
  }
  else {
    if (!bufferOrDataset._meta) {
      throw new Error("Dataset must have a _meta property");
    }
    var buffer = Buffer.from(data.datasetToDict(bufferOrDataset).write());
    blob = new Blob([buffer], { type: "application/dicom" });
  }
  return blob
}

export async function createPDFBlobFromDicomMetadata(pdfInstance: data.NaturalizedDataset) {
  const arrayBuffer = await retrieveInstance(
    pdfInstance.StudyInstanceUID,
    pdfInstance.SeriesInstanceUID,
    pdfInstance.SOPInstanceUID
  );
  const dataset = dicomParser.parseDicom(new Uint8Array(arrayBuffer));
  const fileTag = dataset.elements.x00420011;
  const offset = fileTag.dataOffset;
  const remainder = offset + fileTag.length;
  const pdfByteArray = dataset.byteArray.slice(offset, remainder);
  const pdfBlob = new Blob([pdfByteArray], { type: "application/pdf" });
  return pdfBlob;
}


// TODO : Mutualize code with Report.tsx viewOrDownloadPDF function
export function downloadData(data: Blob | string, filename: string) {
  const link = document.createElement("a");
  if (data instanceof Blob) {
    const objectURL = window.URL.createObjectURL(data);
    link.href = objectURL;
    // For Firefox it is necessary to delay revoking the ObjectURL
    setTimeout(function () {
      window.URL.revokeObjectURL(objectURL);
    }, 100);
  } else {
    link.href = data
  }
  link.download = filename;
  link.click();
}
