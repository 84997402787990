import { api } from "dicomweb-client";
import { DICOM_WEB_ENTRYPOINT } from "config/api";
import { HTTPErrorMessage } from "shared/utils/error";
import uiState from "stores/ui";

export const dicomWebClient = new api.DICOMwebClient({
  url: DICOM_WEB_ENTRYPOINT,
  headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  // headers: { Authorization: "Basic dmFsaWRhdGlvbjpoM1VqQXlKdjZiVUZLY3M=" },
});

export const dicomOptions = {
  staleTime: 1000 * 60 * 60 * 24, // 1 hour
  cacheTime: 1000 * 60 * 60 * 24, // 1 hour,
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
  retry: 1,
  retryDelay: 3000,
} as const;

export async function fetchSeries(studyInstanceUID: string, patientId: string) {
  if (!studyInstanceUID) {
    return [];
  }
  try {
    uiState.loading = true;
    const series = await dicomWebClient.searchForSeries({ 
      studyInstanceUID, 
      queryParams:{"OtherPatientIDsSequence.PatientID": patientId}
    });

    if (series.length === 0) {
      // Handle the "not found" case. This assumes an empty array means not found.
      throw new Error(`No study found for ${studyInstanceUID}`);
    } else {
      // Handle the found series
      return series;
    }
  } catch (error) {
    redirectToLoginIf401(error);
    uiState.loading = false;
  }
}

export async function fetchSeriesMetadata(
  studyInstanceUID: string,
  seriesInstanceUID: string
) {
  if (!studyInstanceUID || !seriesInstanceUID) {
    return [];
  }
  uiState.loading = true;
  return await dicomWebClient
    .retrieveSeriesMetadata({ studyInstanceUID, seriesInstanceUID })
    .catch(redirectToLoginIf401)
    .finally(() => (uiState.loading = false));
}


/**
 * Search studies
 * @param  PatientID is the dicom tag
 * @param  patientId is the Qyscore business id
 * @returns All the studies of a specific patient
 */
export async function searchPatientStudies(PatientID: string, patientId: string) {
  return await dicomWebClient.searchForStudies({ 
    queryParams: { PatientID, "OtherPatientIDsSequence.PatientID": patientId }
  })
    .catch(redirectToLoginIf401)
}

export async function retrieveInstance(studyInstanceUID: string, seriesInstanceUID: string, sopInstanceUID: string) {
  return await dicomWebClient.retrieveInstance({ studyInstanceUID, seriesInstanceUID, sopInstanceUID })
    .catch(redirectToLoginIf401)
}

const redirectToLoginIf401 = (error: Error & { status?: number, message?: string }) => {
  if ([401, 403].includes(error.status)) {
    window.location.href = '/login'
  }
  if (error.status > 400) {
    error.message = HTTPErrorMessage[error.status]
  }
  throw error;
};
