import getIds from "./getIds";
import { ToolGroupManager, CrosshairsTool } from "@cornerstonejs/tools";
import viewerState from "stores/viewer";
import settingsState, { updateWindowLevel } from "stores/settings";
import { VoiModifiedEventDetail } from "@cornerstonejs/core/dist/esm/types/EventTypes";
import { toWindowLevel } from "@cornerstonejs/core/dist/esm/utilities/windowLevel";

export function crosshairRenderingListener() {
  const { viewerId } = viewerState;
  if (!viewerId) return;

  const { toolGroupId } = getIds();
  const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);
  if (!toolGroup) return;

  const tool = toolGroup.getToolInstance(CrosshairsTool.toolName);
  if (!tool?.editData?.annotation) return;
  const center = tool.editData.annotation.data.handles.toolCenter;
  const rotationPoint =
    tool.editData.annotation.data.handles.rotationPoints?.[0]?.[0];
  const relativeCoordinates = center.map(
    (c, i) => Math.round((rotationPoint?.[i] - c) * 1000) / 1000
  );
  const isNotTranslation =
    tool.editData.annotation.data.activeViewportIds?.length === 0;

  if (
    relativeCoordinates.filter((r) => Math.abs(r) > 1).length > 1 &&
    isNotTranslation
  ) {
    settingsState.showOrientationLabel = false;
  }
}

export function voiModifiedListener(event: CustomEvent) {
  const detail: VoiModifiedEventDetail = event.detail;
  const { windowWidth: width, windowCenter: center } = toWindowLevel(
    detail.range.lower,
    detail.range.upper
  );
  updateWindowLevel({ width, center });
}
