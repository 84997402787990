import { proxy } from "valtio";


interface NormativeGraphState {
  rid: string | null
}

const normativeGraphState = proxy<NormativeGraphState>({ 
  rid: 'RID6434'
})

export function displayMarkerNormativeGraph(rid: string){
  normativeGraphState.rid = rid
}

export default normativeGraphState