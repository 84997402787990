import { fhirClient } from "lib/client/fhir";
import { downloadCSV } from "react-admin";
import { BulkDeleteWithConfirmButton, BulkExportButton, type BulkActionProps } from "react-admin";

const BulkActionButtons = (props: BulkActionProps) => {
  const handleExportClick = () => exportSelectedObservartionCSV(props)
  return (
    <>
      <BulkDeleteWithConfirmButton {...props} mutationMode="optimistic" />
      {props.resource === "patients"
        ? <BulkExportButton {...props} onClick={handleExportClick} exporter={null} />
        // : <BulkExportButton {...props} />
        : null
      }
    </>
  )
};

export async function exportSelectedObservartionCSV(params: BulkActionProps) {
  const { selectedIds } = params;
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
  };

  const csv = await fhirClient.request({
    url: `Observation?_format=csv&subject=${selectedIds.join(",")}`,
    headers,
  })
  if (typeof csv === "string" && csv.length) {
    const filename = `patients_${selectedIds.join("-")}`
    downloadCSV(csv, filename)
  }
}


export default BulkActionButtons;
