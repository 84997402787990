import {
  Card,
} from '@mui/material';
import Box from '@mui/material/Box';
import { LoginForm } from '../components/LoginForm';
import { useTranslate } from 'ra-core';
import { useRedirect, useAuthState } from 'react-admin';

export const LoginPage = () => {
  const { authenticated } = useAuthState();
  const redirect = useRedirect();
  const translate = useTranslate();
  const url = window.location;
  if (authenticated && new URLSearchParams(url.search).get("token")) {
    redirect('/')
  }
  // TODO style inside LoginPage.css
  // TODO put a link to forget password page
  // TODO put left side bar, may be in a layout
  return <Box
    sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    }}
    >
    <Card sx={{ minWidth: 300, marginTop: '6em', paddingTop: '1em', textAlign: 'center' }}>
      {translate('ra.auth.welcome')}
      <LoginForm />
    </Card>
  </Box>
}