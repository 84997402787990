import { SelectInput, ReferenceInput, usePermissions,  required } from "react-admin";
import { InputGuesser } from "@api-platform/admin";
export const UserForm = () => {
  const { permissions } = usePermissions();
  return (
    <>
      <InputGuesser source="firstName" />
      <InputGuesser source="lastName" />

      {permissions.role.name === "SuperAdmin" && (
        <ReferenceInput source="organization.id" reference="organizations">
          <SelectInput
            optionText="name"
            source="organization"
            isRequired
            validate={required()}
          />
        </ReferenceInput>
      )}
    </>
  );
};
