import { NavLink } from "react-router-dom";
import { useTranslate } from "ra-core";
import useViewerParams from "shared/hooks/useViewerParams";
import { useSnapshot } from "valtio";
import viewerState from "stores/viewer";
import useMarkers from "shared/hooks/useMarkers";
import { markers } from "shared/utils/recursive";

export const MODES = {
  QUANTIFICATION: "quantification",
  NORMATIVEGRAPH: "normative-graph",
  LONGITUDINALGRAPH: "longitudinal-graph",
  RENDERING3D: "3d-rendering",
} as const;

const CORTICAL_LOBES_RID = "RID13498";
function Modes() {
  const t = useTranslate();
  const { getViewerPath, atViewerPage, study_id } = useViewerParams();
  const { populatedMarkers } = useMarkers(study_id);
  const { segmentsPerSeries } = useSnapshot(viewerState);

  const viewerPath = getViewerPath();
  if (!atViewerPage) {
    return null;
  }

  const isLongitudinal = segmentsPerSeries.some((seg) =>
    [
      "Decreased T2 hyperintensities",
      "Stable T2 hyperintensities",
      "Increased T2 hyperintensities",
    ].includes(seg.SegmentLabel)
  );
  const hasSomeNormative = populatedMarkers?.some((m) => m.percentileRange);
  const lobesMarkers = markers
    ?.find((m) => m.code === CORTICAL_LOBES_RID)
    ?.substructures?.map((rid) => populatedMarkers.find((m) => m.code === rid));
  const hasLobeNormativeData = lobesMarkers.every((m) => m?.percentileRange);

  return (
    <div>
      <NavLink
        to={`${viewerPath}/${MODES.QUANTIFICATION}`}
        data-testid={`viewer-menu-mode-button-${MODES.QUANTIFICATION}`}
        className="block text-center p-2 text-lg my-1 mx-3 bg-stone-700 rounded"
      >
        {t("modes.quantification")}
      </NavLink>
      {hasSomeNormative ? (
        <NavLink
          to={`${viewerPath}/${MODES.NORMATIVEGRAPH}`}
          data-testid={`viewer-menu-mode-button-${MODES.NORMATIVEGRAPH}`}
          className="block text-center p-2 text-lg my-1 mx-3 bg-stone-700 rounded"
        >
          {t("modes.normative_graph")}
        </NavLink>
      ) : null}
      {hasLobeNormativeData ? (
        <NavLink
          to={`${viewerPath}/${MODES.RENDERING3D}`}
          data-testid={`viewer-menu-mode-button-${MODES.RENDERING3D}`}
          className="block text-center p-2 text-lg my-1 mx-3 bg-stone-700 rounded"
        >
          {t("modes.3d_rendering")}
        </NavLink>
      ) : null}
      {isLongitudinal ? (
        <NavLink
          to={`${viewerPath}/${MODES.LONGITUDINALGRAPH}`}
          data-testid={`viewer-menu-mode-button-${MODES.LONGITUDINALGRAPH}`}
          className="block text-center p-2 text-lg my-1 mx-3 bg-stone-700 rounded"
        >
          {t("modes.longitudinal")}
        </NavLink>
      ) : null}
    </div>
  );
}

export default Modes;
