import { Enums, Types } from "@cornerstonejs/core";
import { ViewportType } from "@cornerstonejs/core/dist/esm/enums";
import viewerState from "stores/viewer";

declare type ViewportInputConf = {
  viewportId: string;
  type: ViewportType;
  defaultOptions: {
    orientation: Enums.OrientationAxis;
    background: Types.Point3;
  };
};

export default function getIds(id: string = "main") {
  const renderingEngineId = `RENDERING_ENGINE_${id}`;

  // Define a unique id for the volume
  const metadata = viewerState.instanceMetadata;
  const MRSeriesInstanceUID =
    metadata?.ReferencedSeriesSequence?.[0].SeriesInstanceUID ||
    metadata?.SeriesInstanceUID;
  const volumeName = `VOLUME_${id}_${MRSeriesInstanceUID}`; // Id of the volume less loader prefix
  const volumeLoaderScheme = `cornerstoneStreamingImageVolume`; // Loader id which defines which volume loader to use
  const volumeId = `${volumeLoaderScheme}:${volumeName}`; // VolumeId with loader id + volume id
  const segVolumeId = `${volumeLoaderScheme}:SEG_VOLUME_${id}_${metadata?.SeriesInstanceUID}`;
  const segmentationId = `MY_SEGMENTATION_${id}_${metadata?.SeriesInstanceUID}`;

  const toolGroupId = `MY_TOOLGROUP_${id}`;
  const voiSynchronizerId = `VOI_SYNCHRONIZER_${id}`;

  const viewportId1 = `AXIAL_${id}`;
  const viewportId2 = `SAGITTAL_${id}`;
  const viewportId3 = `CORONAL_${id}`;

  // Create the viewports
  const viewportInputConf: ViewportInputConf[] = [
    {
      viewportId: viewportId1,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.CORONAL,
        background: [0, 0, 0] as Types.Point3,
      },
    },
    {
      viewportId: viewportId2,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.SAGITTAL,
        background: [0, 0, 0] as Types.Point3,
      },
    },
    {
      viewportId: viewportId3,
      type: ViewportType.ORTHOGRAPHIC,
      defaultOptions: {
        orientation: Enums.OrientationAxis.AXIAL,
        background: [0, 0, 0] as Types.Point3,
      },
    },
  ];

  const viewportColors: Record<string, string> = {
    [viewportId1]: "rgb(200, 0, 0)",
    [viewportId2]: "rgb(200, 200, 0)",
    [viewportId3]: "rgb(0, 200, 0)",
  };

  const viewportReferenceLineControllable = [
    viewportId1,
    viewportId2,
    viewportId3,
  ];

  const viewportReferenceLineDraggableRotatable = [
    viewportId1,
    viewportId2,
    viewportId3,
  ];

  const viewportReferenceLineSlabThicknessControlsOn = [
    viewportId1,
    viewportId2,
    viewportId3,
  ];

  function getReferenceLineColor(viewportId: string) {
    return viewportColors[viewportId];
  }

  function getReferenceLineControllable(viewportId: string) {
    const index = viewportReferenceLineControllable.indexOf(viewportId);
    return index !== -1;
  }

  function getReferenceLineDraggableRotatable(viewportId: string) {
    const index = viewportReferenceLineDraggableRotatable.indexOf(viewportId);
    return index !== -1;
  }

  function getReferenceLineSlabThicknessControlsOn(viewportId: string) {
    const index =
      viewportReferenceLineSlabThicknessControlsOn.indexOf(viewportId);
    return index !== -1;
  }

  const blendModeOptions = {
    MIP: "Maximum Intensity Projection",
    MINIP: "Minimum Intensity Projection",
    AIP: "Average Intensity Projection",
  };

  return {
    renderingEngineId,
    volumeName,
    volumeLoaderScheme,
    volumeId,
    segVolumeId,
    segmentationId,
    toolGroupId,
    viewportId1,
    viewportId2,
    viewportId3,
    viewportInputConf,
    viewportColors,
    viewportReferenceLineControllable,
    viewportReferenceLineDraggableRotatable,
    viewportReferenceLineSlabThicknessControlsOn,
    getReferenceLineColor,
    getReferenceLineControllable,
    getReferenceLineDraggableRotatable,
    getReferenceLineSlabThicknessControlsOn,
    blendModeOptions,
    voiSynchronizerId,
  };
}
