import BadgeIcon from '@mui/icons-material/Badge';
import { AuthenticationMethodCreate } from "./components/AuthenticationMethodCreate";
import { AuthenticationMethodList } from "./components/AuthenticationMethodList";
import { AuthenticationMethodShow } from "./components/AuthenticationMethodShow";
import { AuthenticationMethodEdit } from "./components/AuthenticationMethodEdit";

const resource = {
    create: AuthenticationMethodCreate,
    show: AuthenticationMethodShow,
    list: AuthenticationMethodList,
    edit: AuthenticationMethodEdit,
    icon: BadgeIcon,
}

export default resource;