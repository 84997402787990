import { LinearProgress } from "@mui/material";
import { title } from "process";
import { ChipField } from "react-admin";
import { Grid } from "@mui/material";

function ProgressBar({ title, currentNumber, expectedNumber }) {
  const rate = (currentNumber / expectedNumber) * 100;
  if (rate > 100) {
    const errorMessage =
      "Error: current number is greater than expected number";
    return <ChipField title={errorMessage} />;
  }

  return (
    <Grid container>
      <Grid xs={6} sm={6} md={6} minHeight={100}>
        {title}
      </Grid>
      <Grid xs={6} sm={6} md={6} minHeight={100}>
        <LinearProgress
          color="primary"
          variant={rate != null ? "determinate" : "indeterminate"}
          style={{ height: 30, borderRadius: 4, marginTop: 6, width: "100%" }}
          value={rate}
        />
        <span>{`${currentNumber} / ${expectedNumber}`}</span>
      </Grid>
    </Grid>
  );
}

export default ProgressBar;
