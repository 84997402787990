// https://github.com/cornerstonejs/cornerstone3D-beta/blob/main/utils/demo/helpers/WADORSHeaderProvider.js

import dcmjs from "dcmjs";
import safeNaturalizeDataset from "../helpers/safeNaturalizeDataset";
const metadataHeadersPerImageId = {};
const INSTANCE = "instance";

const { DicomMessage } = dcmjs.data;

// @ts-ignore
function addInstance(imageId, dicomJSONDatasetOrP10ArrayBuffer) {
  let dicomJSONDataset;

  // If Arraybuffer, parse to DICOMJSON before naturalizing.
  if (dicomJSONDatasetOrP10ArrayBuffer instanceof ArrayBuffer) {
    const dicomData = DicomMessage.readFile(dicomJSONDatasetOrP10ArrayBuffer);
    dicomJSONDataset = dicomData.dict;
  } else {
    dicomJSONDataset = dicomJSONDatasetOrP10ArrayBuffer;
  }

  // Check if dataset is already naturalized.
  let naturalizedDataset;
  if (dicomJSONDataset["SeriesInstanceUID"] === undefined) {
    naturalizedDataset = safeNaturalizeDataset(dicomJSONDataset);
  } else {
    naturalizedDataset = dicomJSONDataset;
  }
  // @ts-ignore
  metadataHeadersPerImageId[imageId] = naturalizedDataset;
}

function get(query: string, imageId: string) {
  if (query === INSTANCE) {
    // @ts-ignore
    return metadataHeadersPerImageId[imageId];
  }
}

const provider = {
  addInstance,
  get,
};

export default provider;
