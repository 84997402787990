import Dexie, { Table } from 'dexie';
import { type DicomValidation } from './validation';

export class IndexedDB extends Dexie {
  dicoms!: Table<DicomValidation>;

  constructor() {
    super('qydb');
    this.version(3).stores({
      dicoms: '++, studyUID, seriesUID, referenceSeriesUID', // Primary key and indexed props
    });
  }
}

export const idb = new IndexedDB();