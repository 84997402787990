import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import FocusIcon from '@mui/icons-material/CenterFocusWeak';
import { useTranslate } from "ra-core";

import { segmentColorsToCSS } from "shared/utils/color";
import { Marker } from "./Marker";
import { useSnapshot } from "valtio";
import viewerState from "stores/viewer";
import getIds from "lib/cornerstone/helpers/getIds";
import { VolumeViewport, cache, getRenderingEngine } from "@cornerstonejs/core";
import { jumpToWorld } from "@cornerstonejs/tools/dist/esm/utilities/viewport";
import { Point3 } from "@cornerstonejs/core/dist/types/types";

interface EyeProps {
  marker: Marker
  displayMarker: ({
    segments,
    seriesUID,
  }: Pick<Marker, "seriesUID" | "segments">, onlySelect?: boolean) => void;
}

function Eye({ marker, displayMarker }: EyeProps) {
  const {segments, seriesUID} = marker
  const t = useTranslate()
  const {segmentsPerSeries, instanceMetadata} = useSnapshot(viewerState);
  if (!segments?.filter((s) => !!s).length)
    return <span className="mx-4"/>;

  const segmentsState = segmentsPerSeries
    .filter((seg) => segments.map((s) => s.SegmentNumber).includes(seg.SegmentNumber)
      && segments.map(s => s.seriesUID).includes(seg.seriesUID)
    );

  const isSelected = segmentsState.every(seg => seg?.selected);
  const disabled = segmentsPerSeries.every((s) => !s.representationUID);
  const otherModality = !segments.some(
    (s) => s.seriesUID === instanceMetadata?.SeriesInstanceUID
  );

  if (marker.coord) {
    const isLoading = !segmentsState.every(s => s.representationUID);
    return <IconButton
      size="small"
      disabled={disabled}
      title={t("patient.view.tooltip.marker.focus")}
      aria-label={t("patient.view.tooltip.marker.focus")}
      onClick={() => {
        if (otherModality){
          displayMarker({ segments, seriesUID }, true);
          setTimeout(() => moveCameraTo(marker.coord), 700);
        }
        else {
          moveCameraTo(marker.coord)
        }
      }}
    >
      <FocusIcon
        className={isLoading || !isSelected ? "opacity-50" : ""}
        style={otherModality || !isSelected ? null : segmentColorsToCSS(segments)}
      />
    </IconButton>
  }

  if (isSelected) {
    const isLoading = !segmentsState.every(s => s.representationUID);
    return (
      <IconButton
        size="small"
        title={t("patient.view.tooltip.marker.hide")}
        aria-label={t("patient.view.tooltip.marker.hide")}
        onClick={() => displayMarker({ segments, seriesUID })}
      >
        <VisibilityIcon
          className={isLoading ? "opacity-5" : ""}
          style={segmentColorsToCSS(segments)}
        />
      </IconButton>
    );
  }



  return (
    <IconButton
      disabled={disabled}
      size="small"
      title={t("patient.view.tooltip.marker.show")}
      aria-label={t("patient.view.tooltip.marker.show")}
      onClick={() => displayMarker({ segments, seriesUID })}
    >
      <VisibilityOffIcon
        className={
          otherModality
            ? "opacity-50"
            : disabled
            ? "animate-pulse-fast"
            : "" + disabled
        }
      />
    </IconButton>
  );
}


function moveCameraTo(position: Point3) {
  const { renderingEngineId, viewportId1, viewportId2, viewportId3, volumeId } = getIds()
  const renderingEngine = getRenderingEngine(renderingEngineId)
  const viewportIds = [viewportId1, viewportId2, viewportId3]
  const volume = cache.getVolume(volumeId);
  const [x,y,z] = position
  // Invert Z 
  const invertedPos = [x, y, volume.dimensions[2]-z] as Point3
  const worldPos = volume.imageData.indexToWorld(invertedPos)
  viewportIds.forEach(viewportId => {
    const viewport = renderingEngine.getViewport(viewportId);
    jumpToWorld(viewport as VolumeViewport, worldPos)
    viewport.render()
  })
}

export default Eye;
