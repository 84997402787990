import { OrganizationForm } from "./OrganizationForm";
import {
  DeleteWithConfirmButton,
  Edit,
  Toolbar,
  SaveButton,
  SimpleForm,
  useRecordContext,
  usePermissions
} from "react-admin";

const EditToolbar = () => {
  const record = useRecordContext();
  const { permissions } = usePermissions();


  return (
    <Toolbar>
      <SaveButton />
      {permissions.role.name === "SuperAdmin" && <DeleteWithConfirmButton
        confirmContent='ra.confirm.delete'
        translateOptions={{ name: record.name }}
      />}
    </Toolbar>
  );
};

export const OrganizationEdit = () => {
  return (
    <Edit redirect="list">
    <SimpleForm toolbar={<EditToolbar/>} mode="onBlur" reValidateMode="onBlur">
      <OrganizationForm />
    </SimpleForm>
  </Edit>
  )

};
