import { segmentation as cstSegmentation } from "@cornerstonejs/tools";

import { proxy, subscribe } from "valtio";

import dcmjs from "dcmjs";
import getIds from "lib/cornerstone/helpers/getIds";
import { generateColorLUT } from "lib/cornerstone/generateSegmentation";
import { ToolGroupSpecificRepresentation } from "@cornerstonejs/tools/dist/esm/types";
import { groupBy } from "shared/utils/recursive";
import { devtools } from 'valtio/utils'

export interface Segment extends dcmjs.data.SegMetadata {
  selected?: boolean;
  representationUID?: string;
  seriesUID?: string;
}

export interface ViewerState {
  viewerId: string | null;
  instanceMetadata: dcmjs.data.NaturalizedDataset | null;
  segmentsPerSeries: Segment[];
  editSegmentNumber?: number | null
}

const viewerState = proxy<ViewerState>({
  viewerId: null,
  instanceMetadata: null,
  segmentsPerSeries: [],
});

devtools(viewerState, { name: 'viewer state', enabled: true })

subscribe(viewerState, (ops) => {
  if (!viewerState.viewerId) {
    console.warn("Viewer not initialized");
    return;
  }

  // When series displayed changed
  const seriesChange = ops.find((op) => op[1].includes("instanceMetadata"));
  if (
    seriesChange &&
    seriesChange[3] &&
    !seriesChange[1].includes("SegmentSequence")
  ) {
    const previousSeries = seriesChange[3] as dcmjs.data.NaturalizedDataset;
    const currentSeries = seriesChange[2] as dcmjs.data.NaturalizedDataset;
    onSeriesUpdate(previousSeries, currentSeries);
  }

  // When selected segments changed
  const selectedChange = ops.find((op) => op[1].includes("segmentsPerSeries"));
  if (selectedChange) {
    updateSegmentationVisibility();
  }
});

function onSeriesUpdate(
  previousSeries: dcmjs.data.NaturalizedDataset,
  currentSeries: dcmjs.data.NaturalizedDataset
) {
  const { toolGroupId } = getIds(viewerState.viewerId);

  // Remove all previously loaded segmentationRepresentations from toolgroup
  const previousSegRepresentations = cstSegmentation.state
    .getSegmentationRepresentations(toolGroupId)
    ?.map(
      (rep: ToolGroupSpecificRepresentation) =>
        rep.segmentationRepresentationUID
    );
  if (previousSegRepresentations) {
    cstSegmentation.removeSegmentationsFromToolGroup(
      toolGroupId,
      previousSegRepresentations
    );
  }
  // Generate new Color
  if (currentSeries) {
    const currentSegments = viewerState.segmentsPerSeries.filter(
      (s) => s.seriesUID === currentSeries.SeriesInstanceUID
    );
    generateColorLUT(toolGroupId,[undefined, ...currentSegments], []);
  }

  // Unselect all previous series segments
  viewerState.segmentsPerSeries = viewerState.segmentsPerSeries.map((seg) => {
    if (seg.seriesUID !== previousSeries.SeriesInstanceUID) return seg;
    return { ...seg, selected: false };
  });
}

export function updateSegmentationVisibility() {
  const { toolGroupId } = getIds(viewerState.viewerId);

  const rSeg = groupBy(viewerState.segmentsPerSeries, s => s.representationUID)
  Object.entries(rSeg).forEach(([representationUID, segments]) => {
    if(!representationUID) return 
    // Make all selected segments visible
    const selectedSegmentNumbers = segments.filter(s => !!s.selected).map(s => Number(s.SegmentNumber));
    cstSegmentation.config.visibility.setSegmentsVisibility(toolGroupId, representationUID, selectedSegmentNumbers, true)
    // Make all unselected segments invisible
    const unSelectedSegmentNumbers = segments.filter(s => !s.selected).map(s => Number(s.SegmentNumber));
    cstSegmentation.config.visibility.setSegmentsVisibility(toolGroupId, representationUID, unSelectedSegmentNumbers, false)
  })
}

export function setEditSegment(segment: Segment){
  if(viewerState.editSegmentNumber === segment.SegmentNumber){
    viewerState.editSegmentNumber = null
  }else {
    viewerState.editSegmentNumber = segment.SegmentNumber
  }
}

export default viewerState;
