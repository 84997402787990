import { usePermissions } from "react-admin";
import { useEffect, useState } from "react";
import {
  useGetAnalysisSummary,
  useGetDetailedAnalysis,
} from "shared/hooks/useClinicalTrial";
import React from "react";
import BarChart from "shared/components/BarChart";
import {
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";

export const ClinicalTrialErrorAnalysis = ({ clinicalTrial }) => {
  const [totalAnalysis, setTotalAnalysis] = useState<string[]>([
    "{ value: 0 }",
  ]);
  const [totalSantiyCheckErrors, setTotalSanityCheckErrors] = useState<
    string[]
  >(["{ steps: [] }"]);

  const { data: totalNumberAnalysis } = useGetAnalysisSummary(
    "error",
    clinicalTrial.id,
    null,
    null,
    null,
    null
  );
  const { data: santiyCheckErrors } = useGetDetailedAnalysis(clinicalTrial.id);

  useEffect(() => {
    if (totalNumberAnalysis) {
      setTotalAnalysis(totalNumberAnalysis);
    }
    if (santiyCheckErrors) {
      setTotalSanityCheckErrors(santiyCheckErrors);
    }
  }, [totalNumberAnalysis, santiyCheckErrors]);
  const { permissions } = usePermissions();
  if (permissions.role.name !== "SuperAdmin") {
    return null;
  }

  const SantiyCheckErrorsTable = ({ analysis }) => {
    return (
      <TableBody>
        {analysis.map((analyse) =>
          analyse?.steps?.map((step, index) =>
            step.name === "dicom_conversion" && step.status === "error" ? (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>{analyse.patient.uid}</TableCell>
                  <TableCell>{analyse.name}</TableCell>
                  <TableCell>{step.errorMessage}</TableCell>
                </TableRow>
              </React.Fragment>
            ) : null
          )
        )}
      </TableBody>
    );
  };
  return (
    <Grid item={true} xs={12} sm={6} md={6}>
      <Grid container>
        <Grid
          item={true}
          xs={12}
          sm={12}
          md={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={100}
        >
          <h1> ANALYSIS SUMMARY</h1>​
        </Grid>
        <Grid item={true} xs={12} sm={12} md={12}>
          <h1>ERROR SUMMARY​</h1>
          <BarChart
            xAxis={"Error Name"}
            yAxis={"Error Frequency"}
            analysisSummary={totalAnalysis}
          />
        </Grid>

        <Grid item={true} xs={12} sm={12} md={12}>
          <h1>SANNITY CHECK ERRORS​</h1>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Patient id</TableCell>
                <TableCell>Analysis name</TableCell>
                <TableCell>Error</TableCell>
              </TableRow>
            </TableHead>
            <SantiyCheckErrorsTable analysis={totalSantiyCheckErrors} />
          </Table>
        </Grid>
      </Grid>
    </Grid>
  );
};
