import { useEffect, useState } from 'react';
import {
  useRecordContext,
  TextField,
  TextFieldProps,
  RaRecord
} from 'react-admin';
import { reformatIfFhirPatientTask } from './AnalysisStatusField';
import { secondsToHHMMss } from 'shared/utils/date';


function DurationField({ ...props }: TextFieldProps): JSX.Element {
  const recordContext = useRecordContext();
  const [remainingTime, setDuration] = useState(null);
  const record =  reformatIfFhirPatientTask(recordContext)
  
  useEffect(() => {
    let interval: NodeJS.Timer | undefined;
    if ((record.status === "running" || record.status === "in-progress")&& record.startedAt) {
      interval = setInterval(() => {
        setDuration(getDuration(record));
      }, 1000);
    }else {
      setDuration(secondsToHHMMss(record.duration))
      clearInterval(interval)
    }
    return () => clearInterval(interval);
  }, [record]);

  return <TextField {...props} source="formatted" record={{ ...record, formatted: remainingTime }} />
}

export default DurationField

function getDuration(record : RaRecord){
  if(record.startedAt){
    const startDate = new Date(record.startedAt + "Z")
    return secondsToHHMMss((new Date().getTime() - startDate.getTime())/1000 )
  }
  return undefined
} 
