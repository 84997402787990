import { useTranslate } from "ra-core";
import { FormDataConsumer, useRecordContext } from "react-admin";
import CredentialInput from "./CredentialInput";

export const CredentialForm = (props: any) => {
  const record = useRecordContext();
  const translate = useTranslate();
  const translationPrefix = "resources.authentication_methods.fields.credentials."
  return (
    <span>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          (formData.name === "azure" || formData.name === "google") && (
            <CredentialInput
              name="clientId"
              label={translate(translationPrefix + "clientId")}
              defaultValue={
                record !== undefined &&
                record.credentials !== undefined &&
                record.credentials.clientId !== undefined
                  ? record.credentials.clientId
                  : ""
              }
            />
          )
        }
      </FormDataConsumer>
      &nbsp;
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          (formData.name === "azure" || formData.name === "google") && (
            <CredentialInput
              name="clientSecret"
              label={translate(translationPrefix + "clientSecret")}
              defaultValue={
                record !== undefined &&
                record.credentials !== undefined &&
                record.credentials.clientSecret !== undefined
                  ? record.credentials.clientSecret
                  : ""
              }
            />
          )
        }
      </FormDataConsumer>
      &nbsp;
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.name === "azure" && (
            <CredentialInput
              name="tenantId"
              label={translate(translationPrefix + "tenantId")}
              defaultValue={
                record !== undefined &&
                record.credentials !== undefined &&
                record.credentials.tenantId !== undefined
                  ? record.credentials.tenantId
                  : ""
              }
            />
          )
        }
      </FormDataConsumer>
      &nbsp;
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.name === "ldap" && (
            <CredentialInput
              name="url"
              label={translate(translationPrefix + "url")}
              defaultValue={
                record !== undefined &&
                record.credentials !== undefined &&
                record.credentials.url !== undefined
                  ? record.credentials.url
                  : ""
              }
            />
          )
        }
      </FormDataConsumer>
      &nbsp;
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.name === "ldap" && (
            <CredentialInput
              name="bindUser"
              label={translate(translationPrefix + "bindUser")}
              defaultValue={
                record !== undefined &&
                record.credentials !== undefined &&
                record.credentials.bindUser !== undefined
                  ? record.credentials.bindUser
                  : ""
              }
            />
          )
        }
      </FormDataConsumer>
      &nbsp;
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.name === "ldap" && (
            <CredentialInput
              name="bindPassword"
              label={translate(translationPrefix + "bindPassword")}
              defaultValue={
                record !== undefined &&
                record.credentials !== undefined &&
                record.credentials.bindPassword !== undefined
                  ? record.credentials.bindPassword
                  : ""
              }
            />
          )
        }
      </FormDataConsumer>
      &nbsp;
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.name === "ldap" && (
            <CredentialInput
              name="baseDn"
              label={translate(translationPrefix + "baseDn")}
              defaultValue={
                record !== undefined &&
                record.credentials !== undefined &&
                record.credentials.baseDn !== undefined
                  ? record.credentials.baseDn
                  : ""
              }
            />
          )
        }
      </FormDataConsumer>
      &nbsp;
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.name === "ldap" && (
            <CredentialInput
              name="emailAttribute"
              label={translate(translationPrefix + "emailAttribute")}
              defaultValue={
                record !== undefined &&
                record.credentials !== undefined &&
                record.credentials.emailAttribute !== undefined
                  ? record.credentials.emailAttribute
                  : ""
              }
            />
          )
        }
      </FormDataConsumer>
    </span>
  );
};
