import ErrorIcon from '@mui/icons-material/Report';
import History from '@mui/icons-material/History';
import { Title, useTranslate } from 'react-admin';
import {
    Button,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { resetError } from 'stores/ui';

const ErrorDisplay = ({
    error,
    errorInfo,
    resetErrorBoundary,
    title,
    ...rest
}) => {
    const { pathname } = useLocation();
    const originalPathname = useRef(pathname);
    const isProd = process.env.NODE_ENV === 'production'

    // Effect that resets the error state whenever the location changes
    useEffect(() => {
        if (pathname !== originalPathname.current) {
            resetError()
            resetErrorBoundary?.();
        }
    }, [pathname, resetErrorBoundary]);

    const translate = useTranslate();
    return (
        <>
            {title && <Title title={title} />}
            <div className='mt-4 mx-auto max-w-2xl flex flex-col justify-center items-center'>
                <h1 className='flex items-center justify-center' role="alert">
                    <ErrorIcon sx={{ width: '2em', height: '2em', marginRight: '.5em' }} />
                    {translate('ra.page.error')}
                </h1>
                <div className='text-center'>{translate('ra.message.error')}</div>
                <Accordion className='mt-4'>
                    <AccordionSummary
                        expandIcon={isProd ? null : <ExpandMoreIcon />}
                    >
                        {translate(error.message, {
                            _: error.message,
                        })}
                    </AccordionSummary>
                    {isProd ? null :
                        <AccordionDetails>
                            {/*
                                error message is repeated here to allow users to copy it. AccordionSummary doesn't support text selection.
                            */}
                            <p>
                                {translate(error.message, {
                                    _: error.message,
                                })}
                            </p>
                            <p>{errorInfo?.componentStack}</p>
                        </AccordionDetails>
                    }
                </Accordion>
                <div className='mt-8'>
                    <Button
                        variant="contained"
                        startIcon={<History />}
                        onClick={() => window.history.go(-1)}
                    >
                        {translate('ra.action.back')}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ErrorDisplay;