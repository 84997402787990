import { useState } from "react"
import { useTranslate } from "ra-core";
import { useSnapshot } from "valtio"

import useMarkers from "shared/hooks/useMarkers"
import { markers, translateMarkerRID, uniqBy } from "shared/utils/recursive"
import { bgDepth, IMarkerRow, shadowDepth } from "features/patients/quantification/components/Marker"
import normativeGraphState, { displayMarkerNormativeGraph } from "stores/normativeGraph"

import { IconButton } from "@mui/material"
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShowChartIcon from '@mui/icons-material/Timeline';


interface NormativeGraphTableProps {
  studyId: string
}

function NormativeGraphTable({ studyId }: NormativeGraphTableProps) {
  const { populatedMarkers, displaySegment } = useMarkers(studyId)
  
  const seriesMarkers = uniqBy(populatedMarkers, m => m.code)
  const substructures = markers.flatMap(m => m.substructures).filter((m, index, arr) => arr.indexOf(m) === index && m);
  const roots = seriesMarkers.filter(m => !substructures?.includes(m.code))

  return (
    <div className="m-3">
      <table data-testid="normative-table" className='w-full rounded overflow-hidden text-right'>
        <tbody>
          {roots.map(m => <GraphMarkers key={m.code} depth={0} marker={m} populatedMarkers={populatedMarkers} displaySegment={displaySegment} />)}
        </tbody>
      </table>
    </div>
  )
}

function GraphMarkers({ marker, depth, displaySegment, populatedMarkers }: IMarkerRow) {
  const [open, setOpen] = useState(false);
  const snap = useSnapshot(normativeGraphState)
  const t = useTranslate()
  if (marker.hasNorm === false) return null

  const canOpen = populatedMarkers.filter(m => marker.substructures?.includes(m.code)).some(m => !!m.hasNorm)
    && !!marker.substructures?.length

  const title = open ? t("patient.view.tooltip.marker.collapse") : t("patient.view.tooltip.marker.expand");
  return <>
    <tr className={`shadow-inner ${shadowDepth[depth]} ${bgDepth[depth]}`}>
      <td className='py-2 text-center'>
        {marker.percentileRange
          ? <IconButton size='small' onClick={() => displayMarkerNormativeGraph(marker.code)} aria-label={t("patient.view.tooltip.marker.graph")} title={t("patient.view.tooltip.marker.graph")}>
            <ShowChartIcon color={snap.rid === marker.code ? "success" : undefined} />
          </IconButton>
          : <span className="mx-4"></span>}
      </td>
      <td className='py-2 text-left'>
        <abbr title={marker.code} className="border-b-transparent decoration-transparent capitalize">
        {translateMarkerRID(marker.code, t)}
        </abbr>
      </td>
      <td className='py-2'>
        {canOpen &&
          <IconButton size='small' onClick={() => setOpen(!open)} aria-label={title} title={title}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        }
      </td>
    </tr>
    {open && marker?.substructures
      .map(rid => populatedMarkers.find(marker => marker.code === rid))
      .map(sub => <GraphMarkers key={sub.code} depth={depth + 1} marker={sub} populatedMarkers={populatedMarkers} displaySegment={displaySegment} />)}
  </>
}


export default NormativeGraphTable