import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLocaleState } from "ra-core";

import useLongitudinal from "shared/hooks/useLongitudinal";
import useStudyMetadata from "shared/hooks/useStudyMetadata";
import { YYYYMMDDtoDate } from "shared/utils/date";

type Props = {
  study_id: string;
  getViewerPath: (newStudyId?: string) => string | null;
};

function AcquisitionSelection({ study_id, getViewerPath }: Props) {
  const { PatientID } = useStudyMetadata(study_id);
  const { patientStudies } = useLongitudinal(PatientID);
  const [currentLocal] = useLocaleState();
  const navigate = useNavigate();

  if (!patientStudies?.length) {
    return null;
  }

  function handleAcquisitionChange(e: SelectChangeEvent<string>) {
    const newStudyUID = e.target.value;
    const newPath = getViewerPath(newStudyUID);
    navigate(newPath);
  }

  return (
    <FormControl variant="filled">
      <InputLabel id="acquisition-selection-label"> Acquisitions </InputLabel>
      <Select
        className="min-w-[8rem]"
        value={study_id || ""}
        name="Acquisition"
        size="small"
        onChange={handleAcquisitionChange}
      >
        {patientStudies
          ?.sort((a, b) => a.StudyDate.localeCompare(b.StudyDate))
          ?.map((study) => {
            const studyDate = YYYYMMDDtoDate(study.StudyDate);
            return (
              <MenuItem
                key={study.StudyInstanceUID}
                value={study.StudyInstanceUID}
              >
                {new Intl.DateTimeFormat(currentLocal, {
                  dateStyle: "long",
                }).format(studyDate)}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}

export default AcquisitionSelection;
