import ProgressBar from "shared/components/ProgressBar";
import PieChart from "shared/components/PieChart";
import { Grid } from "@mui/material";
import Selection from "shared/components/SelectFilter";
import { useEffect, useState } from "react";
import {
  useGetModalitiesData,
  useGetVisitsData,
  useGetPatientData,
  useGetPatientStatusData,
} from "shared/hooks/useClinicalTrial";
import { ExportButton, downloadCSV } from 'react-admin'

interface StateType {
  label: string | null;
  id: string | null;
}

const initialState: StateType = {
  label: null,
  id: null,
};

export const exporter = (data, ...params) => {
  const resource = params.at(-1)
  const localDate = new Date()
    .toISOString()
    .replace(/[^0-9]/gi, "-")
    .slice(0, -2);
  downloadCSV(data[0].data, `${resource}_${localDate}`)
}

export const ClinicalTrailCROInfo = ({ clinicalTrial, dropDown }) => {
  const [selectedVisit, setSelectedVisit] = useState<StateType>(initialState);
  const [selectedScanner, setSelectedScanner] =
    useState<StateType>(initialState);
  const [selectedSite, setSelectedSite] = useState<StateType>(initialState);

  const [modalityList, setModalityList] = useState<string[]>([]);
  const [visitList, setVisitList] = useState<string[]>(["{ value: 0 }"]);
  const [patientList, setPatientList] = useState<string[]>(["{ value: 0 }"]);
  const [patientStatus, setPatientStatus] = useState<string[]>([
    "{ value: 0 }",
  ]);

  const handleStateChange = (stateSetter) => (referenceData) => {
    stateSetter(referenceData);
  };
  const { data: patientData } = useGetPatientData(
    clinicalTrial.id,
    selectedVisit.label,
    selectedSite.id
  );
  const { data: visitsData } = useGetVisitsData(
    clinicalTrial.id,
    selectedScanner.label,
    selectedSite.id
  );
  const { data: modalitiesData } = useGetModalitiesData(
    clinicalTrial.id,
    selectedVisit.label
  );
  const { data: patientStatusData } = useGetPatientStatusData(
    clinicalTrial.id,
    selectedSite.id,
    selectedVisit.label
  );

  useEffect(() => {
    if (patientData) {
      setPatientList(patientData);
    }
    if (visitsData) {
      setVisitList(visitsData);
    }
    if (modalitiesData) {
      setModalityList(modalitiesData);
    }
    if (patientStatusData) {
      setPatientStatus(patientStatusData);
    }
  }, [patientData, visitsData, modalitiesData, patientStatusData]);

  return (
    <Grid item={true} xs={12} sm={6} md={6}>
      <Grid container>
        <Grid
          item={true}
          xs={12}
          sm={12}
          md={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          minHeight={100}
        >
          <span className="w-12" />
          <h1> DATA FROM CRO </h1>
          <ExportButton
            resource="imagingstudies"
            filterValues={{ "_format": "csv", "group_ids": clinicalTrial.id }}
            exporter={exporter}
            label="Export"
          />
        </Grid>
        <Grid item={true} xs={12} sm={12} md={12}>
          <Selection
            data={dropDown.visits}
            name={"Visits"}
            onSelectChange={handleStateChange(setSelectedVisit)}
          />
          <Selection
            name={"Sites"}
            data={dropDown.sites}
            onSelectChange={handleStateChange(setSelectedSite)}
          />
          <Selection
            data={dropDown.scanners}
            name={"Scanners"}
            onSelectChange={handleStateChange(setSelectedScanner)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} minHeight={300}>
          <h1>UPLOAD STATUS </h1>
          <br />
          <ProgressBar
            title="Current number of visits received​"
            currentNumber={visitList.reduce((a, b) => a + b["value"], 0)}
            expectedNumber={clinicalTrial?.extension?.[0]?.value}
          />
          <ProgressBar
            title="Current number of patients received​"
            currentNumber={patientList[0]["value"]}
            expectedNumber={clinicalTrial?.recruitment?.target}
          />
          <ProgressBar
            title="Current number of modalities received​"
            currentNumber={modalityList.length}
            expectedNumber={modalityList.length}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} minHeight={300}>
          PATIENTS STATUS <PieChart patientsStatus={patientStatus} />
        </Grid>
      </Grid>
    </Grid>
  );
};
