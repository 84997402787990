import { AppBar, useAuthProvider } from "react-admin";
import type { AppBarProps } from "react-admin";
import { useTranslate } from "react-admin";
import { Box, useMediaQuery, Theme } from "@mui/material";
import { Link } from "react-router-dom";

import QyscoreLogo from "shared/images/QyscoreLogo";
import useViewerParams from "shared/hooks/useViewerParams";
import AcquisitionSelection from "features/patients/components/AcquisitionSelection";
import Report from "features/patients/report";
import useStudyMetadata from "shared/hooks/useStudyMetadata";

const CustomAppBar = ({ classes, userMenu, ...props }: AppBarProps) => {
  const authProvider = useAuthProvider();
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const { study_id, getViewerPath } = useViewerParams();

  return (
    <AppBar userMenu={userMenu ?? !!authProvider} {...props}>
      <Link to="/" className={`${isLarge ? "mr-[7rem]" : ""}`}>
        {isLarge && <QyscoreLogo className="h-7" />}
      </Link>
      <Box
        component="div"
        sx={{
          flex: 1,
          overflow: "hidden",
          display: "flex",
          alignItems: "end",
          gap: "1em",
        }}
      >
        {study_id ? (
          <>
            <AcquisitionSelection
              study_id={study_id}
              getViewerPath={getViewerPath}
            />
            <Report study_id={study_id} />
            <Beta study_id={study_id} isLarge={isLarge} />
          </>
        ) : null}
      </Box>
    </AppBar>
  );
};

const Beta = ({ study_id, isLarge }) => {
  const { beta } = useStudyMetadata(study_id);
  const t = useTranslate();
  if (!beta) return null;
  if (isLarge) {
    return (
      <div className="bg-red-700 mb-4 p-2 text-sm text-red-100 whitespace-nowrap">
        {t("beta.disclaimer")}
      </div>
    );
  }
  return (
    <abbr
      className="block bg-red-700 mb-3 p-2 text-sm text-red-100 whitespace-nowrap text-ellipsis overflow-hidden"
      title={t("beta.disclaimer")}
    >
      BETA
    </abbr>
  );
};

export default CustomAppBar;
