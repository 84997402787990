import React from "react";
import { FieldValues, SubmitHandler } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { useFormContext } from "react-hook-form";
import jwtDecode from "jwt-decode";
import { useLocation, Link } from "react-router-dom";
import { Button, CardContent, CircularProgress } from "@mui/material";
import {
  Form,
  required,
  email,
  useTranslate,
  useLogin,
  useNotify,
  useSafeSetState,
  Validator,
} from "ra-core";
import { LoginFormClasses, PasswordInput } from "react-admin";
import { TextInput } from "ra-ui-materialui";
import { ENTRYPOINT } from "../../../config/api";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const LoginForm = (props: LoginFormProps) => {
  const { className } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const [previousEmailValidated, setPreviousEmailValidated] =
    useSafeSetState(null);
  const [distantAuthentication, setDistantAuthentication] =
    useSafeSetState(true);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  let query = useQuery();
  React.useEffect(() => {
    if (query.has("token")) {
      const token = query.get("token");
      let jwt = {};
      // @ts-ignore
      if ((jwt = jwtDecode(token)) && jwt.exp && jwt.exp < Date.now()) {
        // @ts-ignore
        localStorage.setItem("token", token);
      }
    }
  }, [query]);

  const validateAccount = async (values: string) => {
    let errors = {};
    errors = required()(values, []);
    if (errors) {
      setDistantAuthentication(true);
      return errors;
    }
    errors = email(translate("ra.auth.invalid_email"))(values);
    if (errors) {
      setDistantAuthentication(true);
      return errors;
    }
    if (values === previousEmailValidated) {
      return;
    }
    const request = new Request(ENTRYPOINT + "/auth/backend", {
      method: "POST",
      body: JSON.stringify({ email: values }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          setDistantAuthentication(true);
        }
        return response.json();
      })
      .then((backends) => {
        setPreviousEmailValidated(values);
        if (Array.isArray(backends)) {
          if (backends.includes("local") || backends.includes("ldap")) {
            setDistantAuthentication(false);
          } else {
            setDistantAuthentication(true);
          }
        } else {
          setDistantAuthentication(true);
        }
      });
  };
  const validatePassword: Validator = (values: String) => {
    if (distantAuthentication) {
      return null;
    }
    return required()(values, []);
  };
  const submit: SubmitHandler<FieldValues> = (values: FieldValues) => {
    setLoading(true);
    if (distantAuthentication) {
      const request = new Request(
        ENTRYPOINT + "/oauth/authorize?email=" + values.username,
        {
          method: "GET",
          headers: new Headers({ "Content-Type": "application/json" }),
        }
      );
      return fetch(request)
        .then((response) => {
          setLoading(false);
          if (response.status >= 200 && response.status < 300) {
            return response.json();
          }
          notify("ra.auth.sign_in_error", {
            type: "warning",
          });
        })
        .then(({ authorization_url }) => {
          window.location.replace(authorization_url);
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      login(values)
        .then((res) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          notify(
            typeof error === "string"
              ? error
              : typeof error === "undefined" || !error.message
              ? "ra.auth.sign_in_error"
              : error.message,
            {
              type: "warning",
              messageArgs: {
                _:
                  typeof error === "string"
                    ? error
                    : error && error.message
                    ? error.message
                    : undefined,
              },
            }
          );
        });
    }
  };
  return (
    <StyledForm
      onSubmit={submit}
      mode="onBlur"
      noValidate
      className={className}
    >
      <CardContent className={LoginFormClasses.content}>
        <TextInput
          autoFocus
          data-testid="login-view-username-input"
          aria-label="username"
          source="username"
          label={translate("ra.auth.username")}
          isRequired
          /* @ts-ignore */
          validate={validateAccount}
          fullWidth
        />
        {distantAuthentication ? (
          ""
        ) : (
          <PasswordInput
            source="password"
            label={translate("ra.auth.password")}
            autoComplete="current-password"
            isRequired
            validate={validatePassword}
            fullWidth
          />
        )}
        <Link
          data-testid="login-view-forgot-password-link"
          className="fullWidth link"
          to="/forgot-password"
        >
          {translate("ra.auth.forgot_password")} ?
        </Link>
        <LoginButton loading={loading} />
      </CardContent>
    </StyledForm>
  );
};

const PREFIX = "RaLoginForm";

const StyledForm = styled(Form, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  [`& .${LoginFormClasses.content}`]: {
    width: 300,
  },
  [`& .${LoginFormClasses.button}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${LoginFormClasses.icon}`]: {
    margin: theme.spacing(0.3),
  },
}));

export interface LoginFormProps {
  className?: string;
}

function LoginButton({ loading }) {
  const translate = useTranslate();
  const { formState } = useFormContext();

  return (
    <Button
      variant="contained"
      type="submit"
      color="primary"
      disabled={loading || !formState.isValid}
      fullWidth
      className={LoginFormClasses.button}
    >
      {loading ? (
        <CircularProgress
          className={LoginFormClasses.icon}
          size={19}
          thickness={3}
        />
      ) : (
        translate("ra.auth.sign_in")
      )}
    </Button>
  );
}
