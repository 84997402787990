import Version from "features/patients/components/Version";
import LongitudinalTable from "features/patients/longitudinal-graph/components/LongitudinalTable";
import Modes from "features/patients/modes";
import { MODES } from "features/patients/modes/components/Modes";
import { NormativeGraphTable } from "features/patients/normative-graph";
import PatientInfo from "features/patients/patient-info";
import ViewerSettings from "features/patients/viewer/components/ViewerSettings";
import useViewerParams from "shared/hooks/useViewerParams";
import uiState from "stores/ui";
import { useSnapshot } from "valtio";

type Props = { open: boolean };

function ViewerMenu({ open }: Props) {
  const { study_id, mode } = useViewerParams();
  const ui = useSnapshot(uiState);
  if (!open || !study_id || ui.error) {
    return null;
  }
  return (
    <div className="max-w-[275px]">
      <PatientInfo studyId={study_id} />
      <Version studyId={study_id} />
      <Modes />
      {mode === MODES.NORMATIVEGRAPH ? (
        <NormativeGraphTable studyId={study_id} />
      ) : null}
      {mode === MODES.LONGITUDINALGRAPH ? (
        <LongitudinalTable studyId={study_id} />
      ) : null}
      <ViewerSettings />
    </div>
  );
}

export default ViewerMenu;
