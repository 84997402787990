import { useGetOne, useRedirect, usePermissions } from "react-admin";
import { Box, Grid } from "@mui/material";
import { useParams } from "react-router";
import { useCTBasicInfo } from "shared/hooks/useClinicalTrial";
import { ClinicalTrailCROInfo } from "../utils/ClinicalTrailCROInfo";
import { ClinicalTrialAnalysisData } from "../utils/ClinicalTrialAnalysisData";
export const ClinicalTrailGeneralOverview = () => {
  const { id } = useParams();
  const redirect = useRedirect();
  const { sites, visits, modalities, scanners } = useCTBasicInfo(id);
  const { data: record, isLoading } = useGetOne(
    "clinicaltrials",
    { id },
    { onError: () => redirect("/clinicaltrials") }
  );

  if (isLoading) return null;
  return (
    <div>
      <Box>
        <Grid container spacing={4}>
          <ClinicalTrailCROInfo
            data-testid="clinicalTrialCROInfo"
            clinicalTrial={record}
            dropDown={{
              sites: sites,
              visits: visits,
              scanners: scanners,
            }}
          />
          <ClinicalTrialAnalysisData
            data-testid="clinicalTrialAnalysisData"
            clinicalTrial={record}
            dropDown={{
              sites: sites,
              modalities: modalities,
              scanners: scanners,
            }}
          />
        </Grid>
      </Box>
    </div>
  );
};
