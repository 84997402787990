import { ChartOptions } from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useParams } from "react-router";
import { useSnapshot } from "valtio";
import { useTranslate, useLocaleState } from "ra-core";

import useStudyMetadata from "shared/hooks/useStudyMetadata";
import useLongitudinal from "shared/hooks/useLongitudinal";
import { YYYYMMDDtoDate } from "shared/utils/date";
import { parseQuantitativeDataFromSR } from "shared/hooks/useMarkers";
import viewerState, { Segment } from "stores/viewer";
import { getSegmentRID } from "shared/utils/recursive";
import { lab2rgba } from "shared/utils/color";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function LongitudinalGraph() {
  const { study_id } = useParams();
  const { PatientID } = useStudyMetadata(study_id);
  const { studiesInstanceMetadata, isDone } = useLongitudinal(PatientID);
  const snap = useSnapshot(viewerState);
  const [currentLocal] = useLocaleState();
  const t = useTranslate();
  const dateFormatter = new Intl.DateTimeFormat(currentLocal, {
    dateStyle: "long",
  });

  if (!studiesInstanceMetadata?.length || !isDone) {
    return null;
  }

  function computeLongitudinalData() {
    const quantitativeData = studiesInstanceMetadata
      ?.filter(Boolean)
      .map((instances) => ({
        studyDate: dateFormatter.format(
          YYYYMMDDtoDate(instances?.[0].StudyDate)
        ),
        quantification: parseQuantitativeDataFromSR(instances),
      }));

    const datasets = WMH_CODES.map((rid) => {
      const [region] = rid.split(":");
      const segment = (snap.segmentsPerSeries as Segment[]).find(
        (seg) => getSegmentRID(seg) === rid
      );
      const rgb = lab2rgba(segment?.RecommendedDisplayCIELabValue);
      return {
        label: t(region),
        data: quantitativeData.map(
          (q) =>
            q.quantification.find(
              (quant) => quant[0] === rid && quant[2] === "%"
            )?.[1]
        ),
        backgroundColor: `rgb(${rgb.join(",")})`,
        barThickness: 75,
      };
    });

    return {
      labels: quantitativeData.map((q) => q.studyDate),
      datasets,
    };
  }

  const data = computeLongitudinalData();

  const options: ChartOptions<"bar"> = {
    responsive: true,
    elements: {
      bar: {},
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: t("modes.longitudinal.date"),
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        title: {
          display: true,
          text: t("modes.longitudinal.wmh"),
        },
      },
    },
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: t("modes.longitudinal.title"),
      },
    },
  };

  return (
    <div data-testid="viewer-mode-longitudinal-graph">
      <Bar options={options} data={data} />
    </div>
  );
}

export default LongitudinalGraph;

export const WMH_CODES = [
  "RID39467:RID6384",
  "RID39467:RID6387",
  "RID39467:RID45765",
  "RID39467:RID6381",
];
