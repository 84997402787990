import ArticleIcon from "@mui/icons-material/Article";
import { AnalysisGroupCreate } from "./components/AnalysisGroupCreate";
import { AnalysisGroupList } from "./components/AnalysisGroupList";
import { AnalysisGroupEdit } from "./components/AnalysisGroupEdit";

const resource = {
  create: AnalysisGroupCreate,
  list: AnalysisGroupList,
  edit: AnalysisGroupEdit,
  icon: ArticleIcon,
};

export default resource;
