import MedicationIcon from '@mui/icons-material/Medication';
import { OrganizationCreate } from "./components/OrganizationCreate";
import { OrganizationList } from "./components/OrganizationList";
import { OrganizationEdit } from "./components/OrganizationEdit";

const resource = {
  create: OrganizationCreate,
  list: OrganizationList,
  edit: OrganizationEdit,
  icon: MedicationIcon,
};

export default resource;
