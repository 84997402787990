import { defaultTheme } from 'react-admin';
import { RaThemeOptions } from 'ra-ui-materialui';

const theme: RaThemeOptions = {
    ...defaultTheme,
    sidebar: {width: 275},
    palette: {
        primary: {
            main: '#105953',
        },
        secondary: {
            main: '#21b2a6',
        },
        mode: 'dark',
    },
};

export default theme;
