import { useEffect } from "react";
import { eqSet, getSegmentRID } from "shared/utils/recursive";
import viewerState from "stores/viewer";
import { data } from "dcmjs";
import { resetValidation } from "stores/validation";

// TODO maybe use hierarchy.
const GREY_MATTER_RID = "RID6903";

function useViewerSetup(study_id: string, SEGIsDone: boolean, naturalizedDatasets: data.NaturalizedDataset[]) {
  // When changing study, delete current instanceMetadata
  useEffect(
    () => resetState()
    , [study_id]
  );

  // Initialize viewer state segments
  useEffect(
    () => loadInstance(SEGIsDone, naturalizedDatasets)
    , [study_id, SEGIsDone, naturalizedDatasets]
  );
}

function resetState() {
  if (viewerState.instanceMetadata !== null) {
    viewerState.instanceMetadata = null;
    viewerState.segmentsPerSeries = [];
    resetValidation()
  }
}

function loadInstance(SEGIsDone: boolean, naturalizedDatasets: data.NaturalizedDataset[]) {
    if (!SEGIsDone) return;

    const loadedSEGSeriesUIDs = new Set(
      viewerState.segmentsPerSeries?.map((seg) => seg.seriesUID)
    );
    const SEGSeriesUIDs = new Set(
      naturalizedDatasets
        ?.filter((d) => d.Modality === "SEG")
        .map((d) => d.SeriesInstanceUID)
    );

    if (SEGSeriesUIDs?.size && !eqSet(loadedSEGSeriesUIDs, SEGSeriesUIDs)) {
      // Update viewer state with the collected segments from SEG metadata
      const segments = naturalizedDatasets
        .filter((dataset) => dataset.Modality === "SEG")
        .sort((a, b) => a.SeriesDate === b.SeriesDate
          ? 0
          : a.SeriesDate < b.SeriesDate
            ? 1
            : -1
        )
        .filter(
          (s, i, arr) => arr.findIndex(
            (item) => item.SeriesDescription === s.SeriesDescription
          ) === i
        )
        .map((serie) => serie.SegmentSequence.map((seg) => ({
          ...seg,
          seriesUID: serie.SeriesInstanceUID,
        }))
        )
        .flat();
      viewerState.segmentsPerSeries = segments;
      // Find the T1 SEG series by searching the dicom SEG with the GREY matter
      let seriesToLoad = segments.find((seg) => getSegmentRID(seg) === GREY_MATTER_RID)?.seriesUID
       || segments?.[0]?.seriesUID;
      const instanceMetadataToLoad = naturalizedDatasets.find(
        (instance) => instance.SeriesInstanceUID === seriesToLoad
      );
      viewerState.instanceMetadata = instanceMetadataToLoad;
    }


    // If no dicom SEG, ony show the first MR we find
    else if (naturalizedDatasets?.length &&
      naturalizedDatasets.every((dataset) => dataset.Modality !== "SEG") &&
      viewerState.instanceMetadata === null) {
      viewerState.instanceMetadata = naturalizedDatasets.find(
        (d) => d.Modality === "MR" || d.Modality === "PT"
      );
    }
}

export default useViewerSetup;