import { Create, SimpleForm } from "react-admin";
import { AuthenticationMethodForm } from "./AuthenticationMethodForm";

const transformCredentials = (data: any)=> {
    const name = data.name;
    const organization = data.organization;
    delete data.name;
    delete data.organization;
    const credentials = data;
    return {
        name: name,
        organization: organization,
        credentials: credentials
    }
}


export const AuthenticationMethodCreate = () => (
    <Create transform={transformCredentials} redirect="show">
        <SimpleForm mode="onBlur" reValidateMode="onBlur">
            <AuthenticationMethodForm />
        </SimpleForm>
    </Create>
)
