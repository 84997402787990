import { useRedirect, useGetOne, usePermissions } from "react-admin";
import { useParams } from "react-router";
import { useCTBasicInfo } from "shared/hooks/useClinicalTrial";
import Selection from "shared/components/SelectFilter";
import { ClinicalTrialErrorAnalysis } from "../utils/ClinicalTrialErrorData";
import { useEffect, useState } from "react";
import {
  useGetAnalysisSummary,
  useGetDetailedAnalysis,
} from "shared/hooks/useClinicalTrial";
import React from "react";
import BarChart from "shared/components/BarChart";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";

export const ClinicalTrialInternal = () => {
  const { id } = useParams();
  const redirect = useRedirect();
  const { sites, visits } = useCTBasicInfo(id);
  const { data: record, isLoading } = useGetOne(
    "clinicaltrials",
    { id },
    { onError: () => redirect("/clinicaltrials") }
  );
  const { permissions } = usePermissions();
  if (permissions.role.name !== "SuperAdmin") {
    return null;
  }

  if (isLoading) return null;

  return (
    <div>
      <Box>
        <Grid container data-testid="internal" spacing={4}>
          <ClinicalTrialErrorAnalysis clinicalTrial={record} />
        </Grid>
      </Box>
    </div>
  );
};
