import useStudyMetadata from "shared/hooks/useStudyMetadata";
import QyscoreLogo from "shared/images/QyscoreLogo";

type Props = { studyId: string };

function Version({ studyId }: Props) {
  const { version } = useStudyMetadata(studyId);
  return (
    <button
      className="inline-block mx-3 my-2 p-2 border rounded-md  shadow shadow-slate-800 border-slate-800"
      data-testid="viewer-menu-version"
      disabled
    >
      <QyscoreLogo className="h-6 align-baseline" />
      <abbr
        title={version}
        style={{ textDecoration: "None" }}
        className="align-bottom overflow-ellipsis overflow-hidden whitespace-nowrap text-start w-32"
      >
        {version}
      </abbr>
    </button>
  );
}

export default Version;
