import {
    ShowGuesser,
    FieldGuesser
  } from "@api-platform/admin";

import {
    ReferenceField,
    TextField,
} from 'react-admin';

// Analysis Group Show Page

export const AuthenticationMethodShow = () => (
    <ShowGuesser >
      <FieldGuesser source="name" />
      <ReferenceField source="organization.id" reference="organizations" link="show">
        <TextField source='name'/>
      </ReferenceField>
      <FieldGuesser source="credentials" />
    </ShowGuesser>
);
