import { PatientList } from "./components/PatientList";
import { PatientEdit } from "./components/PatientEdit";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

const resource = {
  list: PatientList,
  edit: PatientEdit,
  icon: LocalHospitalIcon,
};

export default resource;
