import { Button, useNotify, useTranslate } from "react-admin";
import { useFormContext } from "react-hook-form";
import { useState } from "react";
import { ENTRYPOINT } from "config/api";
import { useNavigate } from "react-router";

const ResetPasswordButton = (props: any) => {
  const { formState, watch } = useFormContext();
  const t = useTranslate()
  const navigate = useNavigate()
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");
  const notify = useNotify();
  const password = watch("password");

  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    try {
      const response = await fetch(ENTRYPOINT + "/auth/reset-password", {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          accept: "application/json",
        }),
        body: JSON.stringify({
          token: token,
          password: password,
        }),
      })
      const body =  await response.json()
      if(!response.ok){
        throw new Error(body?.detail?.reason)
      }
      notify(t("ra.auth.reset_password.success"));
      navigate('/')
    } catch (error) {
      let errorMessage = t("ra.auth.reset_password.failure");
      if (error.message) errorMessage +=  " : " + error.message
      notify(errorMessage, { type: "error" })
    } finally{
      setLoading(false);
    }
  };
  return <Button size="large" label={t('ra.action.confirm')} onClick={handleClick} disabled={loading || !formState.isValid} />;
};

export default ResetPasswordButton;
