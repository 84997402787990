import { getRenderingEngine } from "@cornerstonejs/core";
import { VolumeActor } from "@cornerstonejs/core/dist/esm/types";
import { toLowHighRange } from "@cornerstonejs/core/dist/esm/utilities/windowLevel";
import { CrosshairsTool, ToolGroupManager, segmentation as cstSegmentation } from "@cornerstonejs/tools";
import { MouseBindings } from "@cornerstonejs/tools/dist/esm/enums";
import getIds from "lib/cornerstone/helpers/getIds";
import { proxy, subscribe } from "valtio";

interface WindowLevel {
  width: number
  center: number
}


interface SettingsState {
  windowLevel: WindowLevel
  defaultWindowLevel: WindowLevel
  roiOpacity: number
  showOrientationLabel: boolean
}

const settingsState = proxy<SettingsState>({
  windowLevel: { width: 20, center: 20 },
  defaultWindowLevel: {width: 500, center: 500},
  roiOpacity: 100,
  showOrientationLabel: true
})

export function updateRoiOpacity(value: number) {
  settingsState.roiOpacity = value
}

export function updateWindowLevel(newWindowLevel: Partial<WindowLevel>, setDefault: boolean = false) {
  settingsState.windowLevel = { ...settingsState.windowLevel, ...newWindowLevel }
  if( setDefault){
    settingsState.defaultWindowLevel = { ...settingsState.defaultWindowLevel, ...newWindowLevel }
  }
}

export function toogleOrientationLabel() {
  settingsState.showOrientationLabel = !settingsState.showOrientationLabel
}


subscribe(settingsState, (ops) => {
  const roiOpacityChange = ops.find(op => op[1].includes("roiOpacity"))
  const windowLevelChange = ops.find(op => op[1].includes("windowLevel"))
  if (roiOpacityChange) {
    const config = cstSegmentation.config.getGlobalConfig();
    config.representations.LABELMAP.fillAlpha =  Number(roiOpacityChange[2])/100
    config.representations.LABELMAP.fillAlphaInactive = Number(roiOpacityChange[2])/100
    cstSegmentation.config.setGlobalConfig(config);
  }
  if (windowLevelChange) {
    const { width, center } = windowLevelChange[2] as WindowLevel
    updateRendererWindowLevel(width, center);
  }
})

function updateRendererWindowLevel(width: number, center: number) {
  const { renderingEngineId, volumeId } = getIds();
  const renderingEngine = getRenderingEngine(renderingEngineId);

  const actors = renderingEngine?.getViewports()
    .map(viewport => viewport.getActor(volumeId)?.actor as VolumeActor| undefined)

  actors.forEach(actor => {
    if(!actor) return
    const { lower, upper } = toLowHighRange(width, center);
    // Set the mapping range of the actor to a range to highlight bones
    actor
    .getProperty()
    .getRGBTransferFunction(0)
    .setMappingRange(lower, upper);
  });
  renderingEngine?.render();
}

export function disableCrossHair() {
  const {toolGroupId} =  getIds()
  const toolGroup = ToolGroupManager.getToolGroup(toolGroupId)
  if(!toolGroup){
    console.warn("No Toolgroup")
    return 
  }
  const {mode} = toolGroup.getToolOptions(CrosshairsTool.toolName)
  if(mode === "Active") {
    toolGroup.setToolDisabled(CrosshairsTool.toolName)
  }else{
    toolGroup.setToolActive(CrosshairsTool.toolName, {
      bindings: [{ mouseButton: MouseBindings.Primary }],
    });
  }
}

export default settingsState