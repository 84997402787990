// https://github.com/cornerstonejs/cornerstone3D-beta/blob/main/utils/demo/helpers/setCtTransferFunctionForVolumeActor.js

import { utilities } from "@cornerstonejs/core";
import { IImage, IVolumeInput } from "@cornerstonejs/core/dist/types/types";
import { updateWindowLevel } from "stores/settings";

// Set the best window level for the min and max pixel value of a slice image
export function setCtTransferFunctionFromImage(
  sliceImage: IImage
): IVolumeInput["callback"] {
  return ({ volumeActor }) => {
    const { minPixelValue, maxPixelValue } = sliceImage
    if (minPixelValue === maxPixelValue) {
      const [lower, upper] = volumeActor.getProperty().getRGBTransferFunction(0).getMappingRange();
      const { windowCenter, windowWidth } = utilities.windowLevel.toWindowLevel(lower, upper);
      updateWindowLevel({ center: windowCenter, width: windowWidth }, true);
    } else {
      const { windowCenter, windowWidth } = utilities.windowLevel.toWindowLevel(
        sliceImage.minPixelValue,
        sliceImage.maxPixelValue
      );
      updateWindowLevel({ center: windowCenter, width: windowWidth });
    }
  };
}

