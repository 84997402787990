import { useFormContext } from "react-hook-form";
import { pwdValidation } from "shared/utils/inputValidation";
import { useTranslate } from "react-admin";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DangerousIcon from "@mui/icons-material/Dangerous";

function PasswordValidation() {
  const { watch } = useFormContext();
  const t = useTranslate();
  const password = watch("password");
  // if(!password) return null

  return (
    <ul>
      {Object.entries(pwdValidation).map(([i18n_key, validate_fn]) => {
        return (
          <li
            key={i18n_key}
            data-testid={`validation-rule-${i18n_key}`}
            className="pt-1"
          >
            {validate_fn(password) ? (
              <CheckCircleOutlineIcon className="text-green-400 mr-2" />
            ) : (
              <DangerousIcon className="text-red-400 mr-2" />
            )}
            {t(i18n_key)}
          </li>
        );
      })}
    </ul>
  );
}

export default PasswordValidation;
