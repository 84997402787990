const passwordGenerator = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-:_=\\|/?^&!.@$£#*()%~<>{}[];";
  const forbiddenWords = [
    "qynapse",
    "qyscore",
    "qypredict",
    "qyviewer",
    "orchestrator",
    "viewer",
    "api",
    "boucicaut",
    "montreal",
    "lyon",
    "paris",
    "boston",
  ];
  const forbiddenSequences = [
    "01234567890",
    "09876543210",
    "zyxwvutsrqponmlkjihgfedcda",
    "abcdefghijklmnopqrstuvwxyz",
    "azertyuiopqsdfghjklmwxcvbn",
    "qwertyuiopasdfghjklzxcvbnm",
  ];

  let generatedPassword = "";
  while (true) {
    generatedPassword = "";
    for (let i = 0; i < 12; i++) {
      generatedPassword += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    if (
      generatedPassword.length >= 12 &&
      generatedPassword.length <= 64 &&
      /[a-z]/.test(generatedPassword) &&
      /[A-Z]/.test(generatedPassword) &&
      /\d/.test(generatedPassword) &&
      /[-:_=\\|/?^&!.@$£#*()%~<>{}[\];]/.test(generatedPassword) &&
      //eslint-disable-next-line
      !forbiddenWords.some((word) => generatedPassword.includes(word)) &&
      //eslint-disable-next-line
      !forbiddenSequences.some((sequence) =>
        generatedPassword.includes(sequence)
      ) &&
      !/(.)\1\1/.test(generatedPassword)
    ) {
      break;
    }
  }

  return generatedPassword;
};

export default passwordGenerator;
