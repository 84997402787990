import dcmjs from "dcmjs";
import * as cornerstone from "@cornerstonejs/core";
import { segmentation } from "@cornerstonejs/tools";
import { Color } from "@cornerstonejs/tools/dist/esm/types";
import { lab2rgba } from "shared/utils/color";

export interface SegInfo {
  labelmapBufferArray: ArrayBuffer[];
  segMetadata: {
    seriesInstanceUid: string;
    data: dcmjs.data.SegMetadata[];
  };
  segmentsOnFrame: number[][];
  segmentsOnFrameArray: number[][][];
}

const instanceModuleNames = [
  "multiframeModule",
  "generalSeriesModule",
  "patientStudyModule",
  "imagePlaneModule",
  "nmMultiframeGeometryModule",
  "imagePixelModule",
  "modalityLutModule",
  "voiLutModule",
  "sopCommonModule",
  "petIsotopeModule",
  "overlayPlaneModule",
  "transferSyntax",
  "petSeriesModule",
  "petImageModule",
  "instance",
];

export async function getSegInfo(
  imageIds: string[],
  arrayBuffer: ArrayBuffer,
) {
  const promise = new Promise((resolve, reject) => {
    // @ts-ignore
    const SEGParsingWorker = new Worker(new URL("./parseSeg.worker.js", import.meta.url));
    // Format data from the cornerstone metadata cache to send it to worker thread
    const instances = JSON.stringify(
      imageIds.map((imageId) => [
        imageId,
        Object.fromEntries(
          instanceModuleNames.map((moduleName) => [
            moduleName,
            cornerstone.metaData.get(moduleName, imageId),
          ])
        ),
      ])
    );
    SEGParsingWorker.onmessage = (e) => {
      resolve(e.data);
      SEGParsingWorker.terminate();
    };
    SEGParsingWorker.onerror = (e) => {
      console.error(e)
      reject(e);
      SEGParsingWorker.terminate();
    };
    SEGParsingWorker.postMessage({ arrayBuffer, instances });
  });

  return (await promise) as SegInfo;
}

export function injectBufferIntoSegVolume(
  segmentationVolume: cornerstone.ImageVolume,
  labelMap3DBuffer: ArrayBuffer
) {
  const { dimensions } = segmentationVolume;
  //@ts-ignore
  const scalarData = segmentationVolume.scalarData;

  let voxelIndex = 0;
  const sliceLength = dimensions[0] * dimensions[1];
  for (let z = 0; z < dimensions[2]; z++) {
    const byteOffset = sliceLength * 2 * z; // 2 bytes/pixel
    const frameData = new Uint16Array(
      labelMap3DBuffer!,
      byteOffset,
      sliceLength
    );
    for (let index = 0; index < frameData.length; index++) {
      const segmentLabel = frameData[index];
      scalarData[voxelIndex] = segmentLabel;
      voxelIndex++;
    }
  }
}

export function generateColorLUT(
  toolGroupId: string,
  segments: dcmjs.data.NaturalizedDataset["SegmentSequence"],
  segmentationRepresentationUIDs: string[]
) {
  const colorLUT: Color[] = segments.map((segment) =>
    segment ? lab2rgba(segment.RecommendedDisplayCIELabValue) : [0, 0, 0, 0]
  );
  segmentation.config.color.addColorLUT(colorLUT, 0);
  segmentationRepresentationUIDs
    .forEach(segRepUID =>segmentation.config.color.setColorLUT(toolGroupId, segRepUID, 0))
}
