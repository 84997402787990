import {
  TextField,
  SelectInput,
  TopToolbar,
  FilterButton,
  FunctionField,
  WrapperField,
  useRefresh,
  useTranslate,
  useLocaleState,
  RaRecord,
} from "react-admin";

import { ListGuesser, FieldGuesser } from "@api-platform/admin";
import { useMediaQuery, Theme, IconButton } from "@mui/material";

import PauseCircleIcon from "@mui/icons-material/PauseCircleFilled";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import DateFieldYYYYMMDD from "shared/components/DateFieldYYYYMMDD";
import AnalysisStatusField from "shared/components/AnalysisStatusField";
import CustomActionButton from "shared/components/CustomActionButton";
import CustomActionLink from "shared/components/CustomActionLink";
import { runAnalysis, stopAnalysis } from "lib/client/api";
import ETAField from "shared/components/ETAField";
import DurationField from "shared/components/DurationField";
import DateFieldUTC from "shared/components/DateFieldUTC";

const ListActions = () => (
  <TopToolbar>
    <FilterButton />
  </TopToolbar>
);

const REFERTCH_INTERVAL = 15000;
function AnalysisList() {
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const t = useTranslate();
  const [locale] = useLocaleState();
  const refresh = useRefresh();

  const analysesListFilters = [
    <SelectInput
      source="status"
      label={t("resources.analyzes.filter.status")}
      choices={[
        { id: "done", name: t("resources.status.done") },
        { id: "error", name: t("resources.status.error") },
        { id: "cached", name: t("resources.status.cached") },
        { id: "scheduled", name: t("resources.status.scheduled") },
        { id: "pending", name: t("resources.status.pending") },
        { id: "running", name: t("resources.status.running") },
        { id: "aborted", name: t("resources.status.aborted") },
        { id: "notrun", name: t("resources.status.notrun") },
      ]}
      emptyText={t("resources.analyzes.filter.status.empty")}
      emptyValue={0}
      alwaysOn
    />,
  ];

  function getAnalysisURL(record: RaRecord) {
    const patientId = record.patient?.id;
    const studyId = record.studyUid;
    if (!["done", "cached"].includes(record.status)) return "";
    return `/patients/${patientId}/studies/${studyId}`;
  }

  return (
    <ListGuesser
      actions={<ListActions />}
      bulkActionButtons={false}
      hasShow={false}
      hasEdit={false}
      filters={analysesListFilters}
      rowClick={(id, resource, record: RaRecord) => getAnalysisURL(record)}
      rowStyle={(record) => !["done", "cached"].includes(record.status) ? { "cursor": "default" } : null}
      sort={{ order: "DESC", field: "id" }}
      queryOptions={{refetchInterval: REFERTCH_INTERVAL}}
    >
      <FieldGuesser source="id" />
      {isLarge ? (
        <TextField source="patient.group.name" sortBy="patient__group__name" />
      ) : null}
      {isLarge ? <TextField source="patient.uid" sortable={false} /> : null}
      <DateFieldYYYYMMDD
        source="acquisition"
        sortable={false}
        locales={locale}
        options={{ year: "numeric", month: "2-digit", day: "2-digit" }}
      />
      {isLarge ? (
        <DateFieldUTC
          source="startedAt"
          locales={locale}
          options={{
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          }}
        />
      ) : null}
      {isLarge ? <DurationField source="duration" sortable={false} /> : null}
      <ETAField source="eta" sortable={false} />
      {isLarge ? (
        <FunctionField
          source="current_step"
          sortable={false}
          render={getCurrentorErrorStep}
        />
      ) : null}
      <AnalysisStatusField source="status" textAlign="center" />
      <WrapperField cellClassName="!text-right" headerClassName="!text-right" label="Actions">
        <CustomActionButton
          title={t("resources.analyzes.tooltip.rerun")}
          aria-label={t("resources.analyzes.tooltip.rerun")}
          callback={(record) => {
            runAnalysis(record.id as number);
            refresh();
          }}
          isHidden={(record) =>
            !["error", "done", "cached", "aborted"].includes(record.status)
          }
          icon={<RestartAltIcon />}
        />
        <CustomActionButton
          title={t("resources.analyzes.tooltip.stop")}
          aria-label={t("resources.analyzes.tooltip.stop")}
          callback={(record) => {
            stopAnalysis(record.id as number);
            refresh();
          }}
          isHidden={(record) =>
            ["done", "cached", "error", "aborted"].includes(record.status)
          }
          icon={<PauseCircleIcon />}
        />
        <CustomActionLink
          title={t("resources.analyzes.tooltip.view")}
          aria-label={t("resources.analyzes.tooltip.view")}
          getPath={record => getAnalysisURL(record)}
          isHidden={(record) => !["done", "cached"].includes(record.status)}
        >
          <IconButton size='small'>
            <VisibilityIcon fontSize="small" />
          </IconButton>
        </CustomActionLink>
      </WrapperField>
    </ListGuesser>
  );

  function getCurrentorErrorStep({ steps }) {
    return (
      steps
        ?.find((step) => step.status === "running")
        ?.name?.replaceAll("_", " ") ||
      steps?.find((step) => step.status === "error")?.name?.replaceAll("_", " ")
    );
  }
}

export default AnalysisList;
