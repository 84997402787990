
import { useRef, useEffect } from 'react';

import { useParams } from 'react-router';
import useMarkers from 'shared/hooks/useMarkers';
import { useTranslate } from 'ra-core'
import { AddObjsFromStudy, initVTKRenderer, resetScene, VTKContext } from '../vtkHelpers';

function Rendering3D() {
  const { study_id } = useParams()
  const t = useTranslate()
  const { naturalizedDatasets, populatedMarkers, regressionModels } = useMarkers(study_id)
  const vtkContainerRef = useRef<HTMLDivElement>(null);
  const context = useRef<VTKContext>(null);

  const OBJDatasets = naturalizedDatasets?.filter(instanceMetadata => instanceMetadata.Modality === 'M3D');

  useEffect(() => {
    if (!context.current) {
      initVTKRenderer(vtkContainerRef, context)
    }
  }, [vtkContainerRef]);

  useEffect(() => {
    if (context.current && !context.current?.actors?.length && populatedMarkers?.length && OBJDatasets && regressionModels) {
      resetScene(context.current)
      AddObjsFromStudy(OBJDatasets, context.current, populatedMarkers)
    }
  }, [context, OBJDatasets, populatedMarkers, regressionModels])

  return (
    <div data-testid="viewer-mode-3d-rendering" className='relative'>
      <h3 className='absolute inline-block w-full top-4 z-10 text-center text-xl'> {t('modes.3d_rendering')} </h3>
      {OBJDatasets?.length === 0 ?
        <h3 className='absolute top-4 right-0 z-10 mr-2 border rounded p-2 text-sm bg-orange-600 opacity-50'> ⚠️ {t('modes.3d_rendering.model_mesh')} </h3>
        : <h3 className='absolute top-4 right-0 z-10 mr-2 border rounded p-2 text-sm  opacity-50'> {t('modes.3d_rendering.patient_mesh')} </h3>}
      <div style={{ height: "45vh", borderRadius: ".5em", overflow: 'hidden' }} ref={vtkContainerRef} />
      <div className='absolute bottom-4 w-full flex justify-around'>
        <div>
          <span className='inline-block h-4 w-4 rounded-3xl bg-p01' />
          <span className='px-2'>P01</span>
        </div>
        <div>
          <span className='inline-block h-4 w-4 rounded-3xl bg-p05' />
          <span className='px-2'>P05</span>
        </div>
        <div>
          <span className='inline-block h-4 w-4 rounded-3xl bg-p25' />
          <span className='px-2'>P25</span>
        </div>
      </div>
    </div>
  )
}

export default Rendering3D