import {
  DeleteWithConfirmButton,
  Edit,
  SaveButton,
  SimpleForm,
  Toolbar,
  useRecordContext,
} from "react-admin";
import { PatientForm } from "./PatientForm";

const EditToolbar = () => {
  const record = useRecordContext();

  return (
    <Toolbar>
      <SaveButton />
      <DeleteWithConfirmButton
        confirmContent='ra.confirm.delete'
        translateOptions={{ id: record.identifier?.[0]?.value }}
      />
    </Toolbar>
  );
};

export const PatientEdit = () => (
  <Edit redirect="list">
    <SimpleForm toolbar={<EditToolbar />} mode="onBlur" reValidateMode="onBlur">
      <PatientForm />
    </SimpleForm>
  </Edit>
);
