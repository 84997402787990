import '@kitware/vtk.js/Rendering/Profiles/Geometry';

import vtkFullScreenRenderWindow from '@kitware/vtk.js/Rendering/Misc/FullScreenRenderWindow';

import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkRenderWindow from '@kitware/vtk.js/Rendering/Core/RenderWindow';
import vtkRenderer from '@kitware/vtk.js/Rendering/Core/Renderer';
import vtkOBJReader from '@kitware/vtk.js/IO/Misc/OBJReader';
import { percentileColors } from 'features/patients/normative-graph/components/NormativeGraph';
import { data } from 'dcmjs';
import { Marker } from 'features/patients/quantification/components/Marker';
import queryClient from 'lib/client/main';
import { dicomOptions, retrieveInstance } from 'lib/client/dicomweb';
import React from 'react';


export interface VTKContext {
  fullScreenRenderer?: vtkFullScreenRenderWindow,
  renderWindow?: vtkRenderWindow,
  renderer?: vtkRenderer,
  sources?: any[],
  actors?: vtkActor[],
  mappers?: vtkMapper[],
}

export function resetScene(context: VTKContext) {
  context.actors.forEach(actor => actor.delete())
  context.actors = []
  context.mappers.forEach(mapper => mapper.delete())
  context.mappers = []
  context.sources.forEach(source => source.delete())
  context.sources = []
}

const RangeColor = {
  "< 1": percentileColors[0].map(i => i / 255),
  "1 - 5": percentileColors[1].map(i => i / 255),
  "5 - 1": percentileColors[2].map(i => i / 255),
  "1 - 25": percentileColors[2].map(i => i / 255),
}

const FALLBACK_MESH_RIDS = [
  'RID13511', 'RID13512',
  'RID13523', 'RID13524',
  'RID13526', 'RID13527',
  'RID13520', 'RID13521',
  'RID13517', 'RID13518',
  'RID13514', 'RID13515',
]

export async function retrieveAllModalityInstancesFromStudy(instances: data.NaturalizedDataset[]) {
  const enc = new TextDecoder("utf-8");

  if (!instances?.length) {
    const fallbackPromises = FALLBACK_MESH_RIDS.map(async (rid) => {
      const promise = await fetch(`/meshes/${rid}.obj`).then(res => res.text());
      return [promise, rid]
    })
    return Promise.all(fallbackPromises)
  }

  const promises = instances?.map(async instance => {
    const promise = await queryClient.fetchQuery(
      ['instance', instance.StudyInstanceUID, instance.SeriesInstanceUID, instance.SOPInstanceUID],
      async () => await retrieveInstance(instance.StudyInstanceUID, instance.SeriesInstanceUID, instance.SOPInstanceUID),
      dicomOptions
    ).then(buffer => enc.decode(buffer))
    const rid = instance.SeriesDescriptionCodeSequence?.[0]?.CodeValue;
    return [promise, rid] as const
  }
  )

  return await Promise.all(promises)
}

export async function AddObjsFromStudy(naturilizedDataset: data.NaturalizedDataset[], context: VTKContext, populatedMarkers: Marker[]) {

  const objBuffers = await retrieveAllModalityInstancesFromStudy(naturilizedDataset);
  objBuffers.forEach(([obj, rid]) => {
    const percentileRange = populatedMarkers?.find(marker => marker.code === rid)?.percentileRange
    const color = RangeColor[percentileRange]
    const objReader = vtkOBJReader.newInstance()
    objReader.parseAsText(obj)
    const nbOutputs = objReader.getNumberOfOutputPorts()
    for (let idx = 0; idx < nbOutputs; idx++) {
      const source = objReader.getOutputData(idx)
      const mapper = vtkMapper.newInstance()
      const actor = vtkActor.newInstance()
      if (color) actor.getProperty().set({ color })
      actor.setMapper(mapper)
      mapper.setInputData(source)
      context.renderer.addActor(actor)

      context.sources.push(source)
      context.mappers.push(mapper)
      context.actors.push(actor)
    }
  })
  context.renderer.resetCamera();
  context.renderWindow.render();
}

export function initVTKRenderer(vtkContainerRef: React.MutableRefObject<HTMLDivElement>, context: React.MutableRefObject<VTKContext>){
  const fullScreenRenderer = vtkFullScreenRenderWindow.newInstance({
    background: [0, 0, 0],
    container: vtkContainerRef.current,
  });
  const renderer = fullScreenRenderer.getRenderer();
  const renderWindow = fullScreenRenderer.getRenderWindow();

  // FIXME try to respect the radiologique convention
  renderer.resetCamera();
  renderer.getActiveCamera().azimuth(-90)
  renderer.getActiveCamera().roll(90)
  renderWindow.render();

  context.current = {
    fullScreenRenderer,
    renderWindow,
    renderer,
    sources: [],
    mappers: [],
    actors: [],
  };
}