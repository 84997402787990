import { useCallback } from "react";
import { useLocation, useParams } from "react-router";
import { MODES } from "../../features/patients/modes/components/Modes";

function useViewerParams() {
  const { study_id, patient_id } = useParams();
  const { pathname } = useLocation();
  const pathElements = pathname.split("/");
  const patientId = patient_id || pathElements?.[2];
  const studyId = study_id ?? pathElements?.[4];
  const mode = pathElements?.[5];

  const atViewerPage = pathname.includes("studies");
  const getViewerPath = useCallback(
    (newStudyId?: string) => {
      const path = [...pathElements];
      if (newStudyId) path[4] = newStudyId;
      // @ts-ignore
      if (Object.values(MODES).includes(path[5])) path.length = 5;
      return path.join("/");
    },
    [pathElements]
  );

  return {
    study_id: studyId,
    patient_id: patientId,
    mode,
    pathname,
    getViewerPath,
    atViewerPage,
  };
}

export default useViewerParams;
