import { SelectInput, ReferenceInput } from "react-admin";

import { InputGuesser } from "@api-platform/admin";

const reportChoices = [
  {
    id: "PDF",
    name: "PDF",
    value: "PDF",
  },
  {
    id: "IMAGE",
    name: "Multiple frames image",
    value: "IMAGE",
  },
  {
    id: "SINGLE_IMAGE",
    name: "Single image",
    value: "SINGLE_IMAGE",
  },
  {
    id: "MULTIPLE_IMAGE_FILES",
    name: "Multiple images",
    value: "MULTIPLE_IMAGE_FILES",
  },
];

const structuredDataChoices = [
  {
    id: "SR",
    name: "Structured reports",
    value: "SR",
  },
];
const maskChoices = [
  {
    id: "SEGMENTATION",
    name: "Segmentation",
    value: "SEGMENTATION",
  },
  {
    id: "FUSED_IMAGE",
    name: "Fused images",
    value: "FUSED_IMAGE",
  },
];
const meshChoices = [
  {
    id: "OBJ",
    name: "3d OBJ format",
    value: "OBJ",
  },
]
const registeredVolumeChoices = [
  {
    id: "MULTIPLE_IMAGE_FILES",
    name: "Multiple images",
    value: "MULTIPLE_IMAGE_FILES",
  },
  {
    id: "ENHANCED",
    name: "Single file with all slices",
    value: "ENHANCED",
  },
]
const compressionChoices = [
  {
    id: "JPEGBaseline8Bit",
    name: "JPEG Lossy",
    value: "JPEGBaseline8Bit",
  },
  {
    id: "JPEGLosslessSV1",
    name: "JPEG Lossless",
    value: "JPEGLosslessSV1",
  },
  {
    id: "JPEGLSLossless",
    name: "JPEG LS Lossless",
    value: "JPEGLSLossless",
  },
  {
    id: "JPEG2000",
    name: "JPEG2000 Lossy",
    value: "JPEG2000",
  },
  {
    id: "JPEG2000Lossless",
    name: "JPEG2000 Lossless",
    value: "JPEG2000Lossless",
  },
  {
    id: "RLELossless",
    name: "RLE Lossless",
    value: "RLELossless",
  },
]


export const DicomNodeForm = () => {
  return (
    <>
      <InputGuesser source="url" />
      <InputGuesser source="aeTitle" isRequired />

      <SelectInput
        source="report"
        choices={reportChoices}
        optionText="name"
        optionValue="value"
        emptyText="-- None --"
      />
      <SelectInput
        source="reportCompression"
        choices={compressionChoices}
        optionText="name"
        optionValue="value"
        emptyText="-- None --"
      />
      <SelectInput
        source="structuredData"
        choices={structuredDataChoices}
        optionText="name"
        optionValue="value"
        emptyText="-- None --"
      />
      <SelectInput
        source="mask"
        choices={maskChoices}
        optionText="name"
        optionValue="value"
        emptyText="-- None --"
      />
      <SelectInput
        source="maskCompression"
        choices={compressionChoices}
        optionText="name"
        optionValue="value"
        emptyText="-- None --"
      />
      <SelectInput
        source="mesh"
        choices={meshChoices}
        optionText="name"
        optionValue="value"
        emptyText="-- None --"
      />
      <SelectInput
        source="registeredVolume"
        choices={registeredVolumeChoices}
        optionText="name"
        optionValue="value"
        emptyText="-- None --"
      />
      <SelectInput
        source="registeredVolumeCompression"
        choices={compressionChoices}
        optionText="name"
        optionValue="value"
        emptyText="-- None --"
      />
      <ReferenceInput source="group.id" reference="analyzis_groups">
        <SelectInput optionText="name" source="group" isRequired />
      </ReferenceInput>
    </>
  );
};
