import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Grid } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

function PieChart({ patientsStatus }) {
  const labels = [
    "COMPLETED",
    "RECEIVED",
    "EXPECT_SERIE",
    "MISSING_SERIE",
    "PLAN_EXAM",
  ];
  const data = {
    labels: labels,
    datasets: [
      {
        data: labels.map((label) => {
          const matchingItem = patientsStatus.find(
            (item) => item.label === label
          );
          return matchingItem ? matchingItem.value : 0;
        }),
        backgroundColor: [
          "rgba(75, 192, 192)",
          "rgba(54, 162, 235)",
          "rgba(255, 206, 86)",
          "rgba(255, 99, 132)",
          "rgba(153, 102, 255)",
        ],
        borderColor: [
          "rgba(75, 192, 192)",
          "rgba(54, 162, 235)",
          "rgba(255, 206, 86)",
          "rgba(255, 99, 132)",
          "rgba(153, 102, 255)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Grid
      sx={{
        p: 2,
        margin: "auto",
        maxWidth: 500,
        flexGrow: 1,
      }}
    >
      <Pie data={data} />
    </Grid>
  );
}

export default PieChart;
