import { useQuery, useQueries } from "react-query";
import { getAgeFromMetadata } from "lib/client/normative";
import {
  fetchSeries,
  fetchSeriesMetadata,
  dicomOptions,
} from "lib/client/dicomweb";
import safeNaturalizeDataset, { denaturalizeDataset } from "lib/cornerstone/helpers/safeNaturalizeDataset";
import useViewerParams from "./useViewerParams";
import { useLiveQuery } from "dexie-react-hooks";
import { idb } from "stores/idb";
import queryClient from "lib/client/main";

function useStudyMetadata(study_id: string) {
  const { patient_id } = useViewerParams();
  const {
    data: seriesData,
    isLoading,
    ...studyQuery
  } = useQuery(
    ["studies", study_id, "series", patient_id],
    ({ queryKey }) => fetchSeries(queryKey[1]!, queryKey[3]!),
    {
      ...dicomOptions,
    }
  );

  const localSeriesDatasets = useLiveQuery(
    () => {
      if(!study_id) return []
      return idb.dicoms.where({ studyUID: study_id }).toArray().then(validations => {
        validations.filter(v => v.seriesUID).forEach((validation) => {
          queryClient.setQueryData(["studies", study_id, "series", validation.dataset.SeriesInstanceUID, "metadata"], [denaturalizeDataset(validation.dataset)] )
          queryClient.setQueryData(["instance", study_id, validation.dataset.SeriesInstanceUID, validation.dataset.SOPInstanceUID], validation.dicom)
        })
        const datasets = validations?.map(d => d.dataset).filter(Boolean);
        return datasets
      })
    }
    , [study_id])

  const naturalizedSeriesData = (seriesData || []).map(safeNaturalizeDataset);
  const SEGSeries = naturalizedSeriesData.some((s) => s.Modality === "SEG")
    ? naturalizedSeriesData?.filter((serie) => ["SEG"].includes(serie.Modality))
    : naturalizedSeriesData;
  const SEGSeriesMetadataQueries = useQueries(
    SEGSeries?.map((serie) => ({
      queryKey: [
        "studies",
        serie.StudyInstanceUID,
        "series",
        serie.SeriesInstanceUID,
        "metadata",
      ],
      queryFn: () =>
        fetchSeriesMetadata(serie.StudyInstanceUID, serie.SeriesInstanceUID),
      enabled: !!seriesData?.length,
      ...dicomOptions,
    }))
  );

  const seriesToFetch = naturalizedSeriesData.some((s) => s.Modality === "SEG")
    ? naturalizedSeriesData?.filter((serie) =>
        ["SR", "DOC"].includes(serie.Modality)
      )
    : naturalizedSeriesData;

  const seriesMetadataQueries = useQueries(
    seriesToFetch?.map((serie) => ({
      queryKey: [
        "studies",
        serie.StudyInstanceUID,
        "series",
        serie.SeriesInstanceUID,
        "metadata",
      ],
      queryFn: () =>
        fetchSeriesMetadata(serie.StudyInstanceUID, serie.SeriesInstanceUID),
      enabled: !!seriesData?.length,
      ...dicomOptions,
    }))
  );

  const SEGIsDone = Boolean(SEGSeriesMetadataQueries?.length) && SEGSeriesMetadataQueries?.every(d => d.isFetched);

  const isDone = seriesMetadataQueries?.every((d) => !d.isLoading && !d.error) && SEGIsDone;

  const naturalizedDatasets = [
    ...SEGSeriesMetadataQueries,
    ...seriesMetadataQueries,
  ]
    ?.flatMap((d) => d.data)
    ?.filter(Boolean)
    ?.map(safeNaturalizeDataset);
  if(localSeriesDatasets?.length) {
    naturalizedDatasets.push(...localSeriesDatasets)
  }

  const firstDataset = naturalizedDatasets?.[0];
  const age = getAgeFromMetadata(firstDataset);
  const sex = firstDataset?.PatientSex;
  const PatientID = firstDataset?.PatientID;
  const PatientName = firstDataset?.PatientName?.[0]?.Alphabetic;

  const qynapseMetadata = naturalizedDatasets?.find(
    (dataset) => dataset?.Manufacturer === "Qynapse"
  );
  const version = qynapseMetadata?.SoftwareVersions;
  const uniqueDeviceIdentifier =
    qynapseMetadata?.UDISequence?.[0]?.UniqueDeviceIdentifier;
  const beta = "PRELIMINARY" === qynapseMetadata?.PreliminaryFlag;
  const certifications = qynapseMetadata?.ApprovalSequence?.map(
    (tag) => tag.CodeValue
  );

  return {
    ...studyQuery,
    isDone,
    SEGIsDone,
    age,
    sex,
    certifications,
    PatientID,
    PatientName,
    version,
    naturalizedDatasets,
    uniqueDeviceIdentifier,
    beta,
    isLoading,
  };
}

export default useStudyMetadata;
