import { useController } from "react-hook-form";
import TextField from "@mui/material/TextField";

const CredentialInput = (props: any) => {
  const {
    field,
    fieldState: { isTouched },
    formState: { isSubmitted },
  } = useController(props);
  return (
    <TextField
      variant="filled"
      {...field}
      label={props.label}
      type="string"
      helperText={isTouched || isSubmitted}
    />
  );
};

export default CredentialInput;
