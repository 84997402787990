import { ENTRYPOINT } from 'config/api';
import { fetchUtils } from 'react-admin';

export const fetchJson = (url: URL, options: fetchUtils.Options = {}) => {
    if (localStorage.getItem('token')) {
        if (!options.user) {
            options.user = {};
        }
        options.user.authenticated = true
        options.user.token = 'Bearer '+  localStorage.getItem('token')
    }
    return fetchUtils.fetchJson(url, options);
};


export function runAnalysis(analysisId: number){
    const url = new URL(`${ENTRYPOINT}/analyzes/${String(analysisId)}/run`) 
    return fetchJson(url, {method: 'POST'})
}

export function stopAnalysis(analysisId: number){
    const url = new URL(`${ENTRYPOINT}/analyzes/${String(analysisId)}/stop`) 
    return fetchJson(url, {method: 'POST'})
}

export function triggerWebhook(nodeId: number){
    const url = new URL(`${ENTRYPOINT}/nodes/${String(nodeId)}/hook`)
    return fetchJson(url, {method: 'POST'})
}