
import { proxy, subscribe } from "valtio"
import type { data } from "dcmjs"
import { devtools } from 'valtio/utils'
import type { Marker } from "features/patients/quantification/components/Marker"
import { CrosshairsTool, ToolGroupManager } from "@cornerstonejs/tools"
import getIds from "lib/cornerstone/helpers/getIds"
import { MouseBindings } from "@cornerstonejs/tools/dist/esm/enums"
import { brushInstanceNames } from "lib/cornerstone/setupCornerstoneViewer"
import viewerState from "./viewer"

export const VALIDATION_STATUS = {
  UNSET: "unset",
  VALIDATED: "validated",
  UNVALIDATED: "unvalidated",
  EDITED: "edited",
  ERASED: "erased"
} as const

export type ValidationStatus = (typeof VALIDATION_STATUS)[keyof typeof VALIDATION_STATUS]

export type MarkerValidationInfo = {
  status: ValidationStatus
  screenshots?: readonly string[]
}

// This type structure global js state and also the indexdb data 
export type DicomValidation = {
  studyUID?: string
  seriesUID?: string;
  referenceSeriesUID?: string;
  markerValidations?: Record<string, MarkerValidationInfo>;
  dataset?: data.NaturalizedDataset;
  dicom?: ArrayBuffer;
  isValtioStore?: boolean
}

const validationState = proxy<DicomValidation>({
  markerValidations: null,
  seriesUID: null,
  isValtioStore: true
})

devtools(validationState, { name: 'Validation store', enabled: true })

subscribe(validationState, (ops) => {
  const seriesChange = ops.find((op) => op[1].includes("instanceMetadata"));
  if (seriesChange?.[2] === null) {
    resetValidation()
  }
})

export function initValidation(markers: Marker[]) {
  validationState.markerValidations = Object.fromEntries(markers
    ?.filter(m => m.segments?.length === 1 && !m.hasCoords)
    ?.map(m => ([m.code, { status: "unset" }]))
  )
  validationState.seriesUID = markers[0].seriesUID
}

export function resetValidation() {
  validationState.markerValidations = null
  validationState.seriesUID = null
  viewerState.editSegmentNumber = null
  const { toolGroupId } = getIds()
  const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);
  for (const key in brushInstanceNames) {
    toolGroup.setToolDisabled(brushInstanceNames[key]);
  }
  setActiveEditTool(CrosshairsTool.toolName)
}

export function toggleSegmentValidation(code: string) {
  const segmentValidation = validationState?.markerValidations?.[code]?.status
  const vArr = Object.values(VALIDATION_STATUS).slice(0, 3)
  if (vArr.includes(segmentValidation)) {
    if (segmentValidation === VALIDATION_STATUS.UNVALIDATED) {
      viewerState.editSegmentNumber = null
      setActiveEditTool(CrosshairsTool.toolName)
    }
    const index = vArr.findIndex(v => v === segmentValidation)
    const newIndex = (index + 1) % 3
    const newStatus = vArr[newIndex]
    validationState.markerValidations[code].status = newStatus
  }
}

export function setMarkerValidation(code: string, validationStatus: ValidationStatus) {
  validationState.markerValidations[code].status = validationStatus
}

export function setMarkerScreenshots(code: string, screenshots: string[]){
  validationState.markerValidations[code].screenshots = screenshots
}


export function setActiveEditTool(nameAsStringOrNumber: string) {
  const name = String(nameAsStringOrNumber);
  const { toolGroupId } = getIds()
  const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);

  // Set the currently active tool disabled
  const toolName = toolGroup.getActivePrimaryMouseButtonTool();
  if (toolName) {
    toolGroup.setToolDisabled(toolName);
  }

  const toolToActivate = toolName === name ? CrosshairsTool.toolName : name
  toolGroup.setToolActive(toolToActivate, {
    bindings: [{ mouseButton: MouseBindings.Primary }],
  });
}


export default validationState