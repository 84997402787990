import { useState } from "react";
import { useTranslate } from "ra-core";
import { Badge, IconButton } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Eye from "./Eye";
import ValidateSegmentIcon from "features/patients/validation/ValidateSegmentIcon";
import EditSegmentIcon from "features/patients/validation/EditSegmentIcon";
import ScreenshotIcon from "features/patients/validation/ScreenshotIcon";

import type { Segment } from "stores/viewer";
import type { DicomValidation } from "stores/validation";
import { translateMarkerRID } from "shared/utils/recursive";

export interface Marker {
  // From tree.json
  code: string;
  substructures?: string[];
  percentiles?: number[];
  // Added after populate
  seriesUID?: string;
  segments?: Segment[];
  icv?: number;
  mL?: number;
  zscore?: number;
  percentileRange?: string;
  // Coordinates
  hasCoords?: boolean;
  coord?: [number, number, number];
  // Flags for displaying in hierarchy tables
  hasSegments?: boolean;
  hasQuantif?: boolean;
  hasNorm?: boolean;
  isUnknown?: boolean;
}

export interface IMarkerRow {
  marker: Marker;
  depth: number;
  populatedMarkers: Marker[];
  displaySegment: ({
    segments,
    seriesUID,
  }: Pick<Marker, "seriesUID" | "segments">, onlySelect?: boolean) => void;
  validationStore?: DicomValidation;
}

export const bgDepth = ["bg-stone-700", "bg-stone-800", "bg-stone-900"];
export const shadowDepth = [
  "shadow-stone-750",
  "shadow-stone-850",
  "shadow-stone-950",
];

export function Markers({
  marker,
  depth,
  displaySegment: displayMarker,
  populatedMarkers,
  validationStore,
}: IMarkerRow) {
  const [open, setOpen] = useState<boolean>(false);
  const t = useTranslate();
  let crossed =
    validationStore?.markerValidations?.[marker.code]?.status === "erased"
      ? "line-through"
      : undefined;

  if (marker.hasQuantif === false && marker.hasSegments === false) return null;

  const canOpen = !!marker.substructures?.length;
  return (
    <>
      <tr className={`shadow-inner ${shadowDepth[depth]} ${bgDepth[depth]}`}>
        <td className="py-2 text-center">
          <Eye marker={marker} displayMarker={displayMarker} />
        </td>
        <td className="py-2 text-left">
          <abbr
            title={marker.code}
            className="border-b-transparent decoration-transparent inline-block first-letter:uppercase">
            <span className={crossed}>
              {translateMarkerRID(marker.code, t)}
            </span>
          </abbr>
        </td>
        <td className="py-2"> {marker?.mL?.toFixed(2)}</td>
        <td className="py-2"> {marker?.icv?.toFixed(2)} </td>
        <td className="py-2"> {marker?.zscore?.toFixed(2)} </td>
        <td className="py-2"> {marker?.percentileRange}</td>
        <td className="py-2 px-3">
          <ScreenshotIcon marker={marker} validationStore={validationStore} />
          <EditSegmentIcon marker={marker} validationStore={validationStore} />
          <ValidateSegmentIcon
            marker={marker}
            validationStore={validationStore}
          />
          <CollapseIcon
            badge={marker?.substructures?.length}
            hidden={!canOpen}
            setOpen={setOpen}
            open={open}
          />
        </td>
      </tr>
      {open &&
        marker?.substructures
          ?.map((rid) => populatedMarkers.find((marker) => marker.code === rid))
          .filter((sub) => !!sub)
          ?.map((sub) => (
            <Markers
              key={sub?.code}
              depth={depth + 1}
              marker={sub}
              populatedMarkers={populatedMarkers}
              displaySegment={displayMarker}
              validationStore={validationStore}
            />
          ))}
    </>
  );
}

function CollapseIcon({
  badge,
  hidden,
  setOpen,
  open,
}: {
  badge: number;
  hidden: boolean;
  setOpen: Function;
  open: boolean;
}) {
  const t = useTranslate();
  if (hidden) {
    return null;
  }
  const title = open
    ? t("patient.view.tooltip.marker.collapse")
    : t("patient.view.tooltip.marker.expand");
  return (
    <IconButton
      size="small"
      onClick={() => setOpen(!open)}
      aria-label={title}
      title={title}
    >
      <Badge
        badgeContent={badge}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        color="default"
      >
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Badge>
    </IconButton>
  );
}

export default Markers;
