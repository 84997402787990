import BarChart from "shared/components/BarChart";
import { Grid, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { ExportButton } from 'react-admin'
import Selection from "shared/components/SelectFilter";
import { useEffect, useState } from "react";
import { useGetAnalysisSummary } from "shared/hooks/useClinicalTrial";
import { exporter } from "./ClinicalTrailCROInfo";

interface StateType {
  label: string | null;
  id: string | null;
}

const initialState: StateType = {
  label: null,
  id: null,
};

export const ClinicalTrialAnalysisData = ({ clinicalTrial, dropDown }) => {
  const [selectedModality, setSelectedModality] =
    useState<StateType>(initialState);
  const [selectedScanner, setSelectedScanner] =
    useState<StateType>(initialState);
  const [selectedSite, setSelectedSite] = useState<StateType>(initialState);
  const [analysisSummaryData, setAnalysisSummaryData] = useState<string[]>([
    "{ value: 0 }",
  ]);
  const [totalAnalysis, setTotalAnalysis] = useState<string[]>([
    "{ value: 0 }",
  ]);
  const [totalAnalysisExist, setTotalAnalysisExist] = useState<string[]>([
    "{ value: 0 }",
  ]);

  const { data: analysisSummary } = useGetAnalysisSummary(
    "status",
    clinicalTrial.id,
    selectedScanner.label,
    selectedSite.id,
    selectedModality.label,
    null
  );
  const { data: totalNumberAnalysis } = useGetAnalysisSummary(
    "status",
    clinicalTrial.id,
    null,
    null,
    null,
    null
  );

  const { data: totalNumberAnalysisExist } = useGetAnalysisSummary(
    "status",
    clinicalTrial.id,
    null,
    null,
    null,
    '{"deleted_at__isnull": false}'
  );

  const handleStateChange = (stateSetter) => (referenceData) => {
    stateSetter(referenceData);
  };

  useEffect(() => {
    if (analysisSummary) {
      setAnalysisSummaryData(analysisSummary);
    }
    if (totalNumberAnalysis) {
      setTotalAnalysis(totalNumberAnalysis);
    }
    if (totalNumberAnalysisExist) {
      setTotalAnalysisExist(totalNumberAnalysisExist);
    }
  }, [analysisSummary, totalNumberAnalysis, totalNumberAnalysisExist]);

  return (
    <Grid item xs={12} sm={6} md={6}>
      <Grid container>
        <Grid
          item={true}
          xs={12}
          sm={12}
          md={12}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          minHeight={100}
        >
          <span className="w-12"/>
          <h1> ANALYZED DATA</h1>
          <ExportButton
          resource="tasks"
          filterValues={{"_format": "csv", "group_ids": clinicalTrial.id}}
          exporter={exporter}
          label="Export"
          />
        </Grid>
        <Grid item={true} xs={12} sm={12} md={12} minHeight={300}>
          <h1>COST SUMMARY​</h1>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Total number of analyzes run​</TableCell>
                <TableCell>
                  {totalAnalysis.reduce(
                    (sum, value) => sum + value["value"],
                    0
                  )}
                </TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>
                  Total number of extra modalities sent and processed​​
                </TableCell>
                <TableCell>10​</TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>
                  Total number of patient retracted but processed​​
                </TableCell>
                <TableCell>
                  {totalAnalysisExist.reduce(
                    (sum, value) => sum + value["value"],
                    0
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>

        <Grid item={true} xs={12} sm={12} md={12} minHeight={300}>
          <h1>ANALYZES SUMMARY​</h1>
          <Selection
            data={dropDown.sites}
            name={"Sites"}
            onSelectChange={handleStateChange(setSelectedSite)}
          />
          <Selection
            name={"Modalities"}
            data={dropDown.modalities}
            onSelectChange={handleStateChange(setSelectedModality)}
          />
          <Selection
            data={dropDown.scanners}
            name={"Scanners"}
            onSelectChange={handleStateChange(setSelectedScanner)}
          />

          <BarChart
            xAxis={"analysis status"}
            yAxis={"number of analysis"}
            analysisSummary={analysisSummaryData}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
